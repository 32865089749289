import React, {useState, useEffect} from "react";
import "./addEducationalCourse.css"
import {cheq, db, database} from "../../firebaseConfig";
import firebase from "firebase";

import { useAuthState } from 'react-firebase-hooks/auth';
import Todo from "../../components/base/Todo";

import Next from "../../components/showBranchImages";

import SingleEdCourse from "./singleEdCourse";

export default function ViewEdCourses({}){

  const [user] = useAuthState(cheq);
    const [todoList, setTodoList] = useState();
    const [nextList, setNextList] = useState();


    var phone = "hello";
    if (user != null) {
        phone = cheq.currentUser.phoneNumber;
    }


    useEffect(() => {
        const todoRef = firebase
          .database()
          .ref(`educator/${phone}/educationalcourses`);
        todoRef.on("value", (snapshot) => {
          const todos = snapshot.val();
          const todoList = [];
          for (let id in todos) {
            todoList.push({ id, ...todos[id] });
          }
          setTodoList(todoList);
        });
    
      }, []);

    return(

        <div>
            
            <center>
        <h5><b>Hi, View your listed Educational Courses here</b></h5>
        </center>
         <div>
      {todoList
        ? todoList.map((todo, index) => <SingleEdCourse todo={todo} key={index} />)
        : <div><center><p>No Educational Courses Listed by You</p></center></div>}
      </div>

      
        </div> 
    );
}