import React from 'react';
import firebase from "firebase";
import { cheq, storage, db } from '../firebaseConfig';
import "../index.css";
import { Link } from 'react-router-dom';

export default function ShowPlanImages({ next }) {

  return (
    <div>
      {/*<p>{next.onClickfunction}</p>*/}
      <Link to={next.onClickfunction} target="_blank">
      <img className=" inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 shadow-2xl"
        src={next.slideshow_image}></img>
        </Link>
    </div>
  );
}