import React from "react";

export const infostatescities = {
    countries: [
        {
            name: "Andaman and Nicobar Islands",
            states: [
                {
                    name: ["Nicobar"],
                }, {
                    name: ["North and Middle Andaman"],
                }, {
                    name: ["South Andaman"],
                }
            ]
        },
        {
            name: "Andhra Pradesh",

            states: [
                {
                    name: ["Anantapur"],
                }, {
                    name: ["Chittoor"],
                }, {
                    name: ["East Godavari"],
                }, {
                    name: ["Guntur"],
                }, {
                    name: ["Krishna"],
                }, {
                    name: ["Kurnool"],
                }, {
                    name: ["Prakasam"],
                }, {
                    name: ["Srikakulam"],
                }, {
                    name: ["Sri Potti Sriramulu Nellore"],
                }, {
                    name: ["Visakhapatnam"],
                }, {
                    name: ["Vizianagaram"],
                }, {
                    name: ["West Godavari"],
                }, {
                    name: ["YSR District"],
                }, {
                    name: ["Kadapa (Cuddapah)"],
                }
            ]
        },
        {

            name: "Arunachal Pradesh",

            states: [
                {
                    name: ["Anjaw"],
                }, {
                    name: ["Changlang"],
                }, {
                    name: ["Dibang Valley"],
                }, {
                    name: ["East Kameng"],
                }, {
                    name: ["East Siang"],
                }, {
                    name: ["Kamle"],
                }, {
                    name: ["Kra Daadi"],
                }, {
                    name: ["Kurung Kumey"],
                }, {
                    name: ["Lepa Rada"],
                }, {
                    name: ["Lohit"],
                }, {
                    name: ["Longding"],
                }, {
                    name: ["Lower Dibang Valley"],
                }, {
                    name: ["Lower Siang"],
                }, {
                    name: ["Lower Subansiri"],
                }, {
                    name: ["Namsai"],
                }, {
                    name: ["Pakke Kessang"],
                }, {
                    name: ["Papum Pare"],
                }, {
                    name: ["Shi Yomi"],
                }, {
                    name: ["Siang"],
                }, {
                    name: ["Tawang"],
                }, {
                    name: ["Tirap"],
                }, {
                    name: ["Upper Siang"],
                }, {
                    name: ["Upper Subansiri"],
                }, {
                    name: ["West Kameng"],
                }, {
                    name: ["West Siang"],
                }
            ]
        }, {
            name: "Assam",

            states: [
                {
                    name: ["Baksa"],
                }, {
                    name: ["Barpeta"],
                }, {
                    name: ["Biswanath"],
                }, {
                    name: ["Bongaigaon"],
                }, {
                    name: ["Cachar"],
                }, {
                    name: ["Charaideo"],
                }, {
                    name: ["Chirang"],
                }, {
                    name: ["Darrang"],
                }, {
                    name: ["Dhemaji"],
                }, {
                    name: ["Dhubri"],
                }, {
                    name: ["Dibrugarh"],
                }, {
                    name: ["Dima Hasao (North Cachar Hills)"],
                }, {
                    name: ["Goalpara"],
                }, {
                    name: ["Golaghat"],
                }, {
                    name: ["Hailakandi"],
                }, {
                    name: ["Hojai"],
                }, {
                    name: ["Jorhat"],
                }, {
                    name: ["Kamrup"],
                }, {
                    name: ["Kamrup Metropolitan"],
                }, {
                    name: ["Karbi Anglong"],
                }, {
                    name: ["Karimganj"],
                }, {
                    name: ["Kokrajhar"],
                }, {
                    name: ["Lakhimpur"],
                }, {
                    name: ["Majuli"],
                }, {
                    name: ["Morigaon"],
                }, {
                    name: ["Nagaon"],
                }, {
                    name: ["Nalbari"],
                }, {
                    name: ["Sivasagar"],
                }, {
                    name: ["Sonitpur"],
                }, {
                    name: ["South Salamara-Mankachar"],
                }, {
                    name: ["Tinsukia"],
                }, {
                    name: ["Udalguri"],
                }, {
                    name: ["West Karbi Anglong"],
                }
            ]

        }, {
            name: "Bihar",

            states: [
                {
                    name: ["Araria"],
                }, {
                    name: ["Arwal"],
                }, {
                    name: ["Aurangabad"],
                }, {
                    name: ["Banka"],
                }, {
                    name: ["Begusarai"],
                }, {
                    name: ["Bhagalpur"],
                }, {
                    name: ["Bhojpur"],
                }, {
                    name: ["Buxar"],
                }, {
                    name: ["Darbhanga"],
                }, {
                    name: ["East Champaran (Motihari)"],
                }, {
                    name: ["West Champaran"],
                }, {
                    name: ["Gaya"],
                }, {
                    name: ["Gopalganj"],
                }, {
                    name: ["Jamui"],
                }, {
                    name: ["Jehanabad"],
                }, {
                    name: ["Kaimur (Bhabua)"],
                }, {
                    name: ["Katihar"],
                }, {
                    name: ["Khagaria"],
                }, {
                    name: ["Kishanganj"],
                }, {
                    name: ["Lakhisarai"],
                }, {
                    name: ["Madhepura"],
                }, {
                    name: ["Madhubani"],
                }, {
                    name: ["Munger (Monghyr)"],
                }, {
                    name: ["Nalanda"],
                }, {
                    name: ["Nawada"],
                }, {
                    name: ["Patna"],
                }, {
                    name: ["Purnia (Purnea)"],
                }, {
                    name: ["Rohtas"],
                }, {
                    name: ["Saharsa"],
                }, {
                    name: ["Samastipur"],
                }, {
                    name: ["Saran"],
                }, {
                    name: ["Sheikhpura"],
                }, {
                    name: ["Sheohar"],
                }, {
                    name: ["Sitamarhi"],
                }, {
                    name: ["Siwan"],
                }, {
                    name: ["Supaul"],
                }, {
                    name: ["Vaishali"],
                }
            ]

        }, {
            name: "Chandigarh",
            states: [
                { name: ["Chandigarh"] },
            ]
        }, {
            name: "Chhattisgarh",

            states: [
                {
                    name: ["Balod"],
                }, {
                    name: ["Baloda Bazar"],
                }, {
                    name: ["Balrampur"],
                }, {
                    name: ["Bastar"],
                }, {
                    name: ["Bemetara"],
                }, {
                    name: ["Bijapur"],
                }, {
                    name: ["Bilaspur"],
                }, {
                    name: ["Dantewada (South Bastar)"],
                }, {
                    name: ["Dhamtari"],
                }, {
                    name: ["Durg"],
                }, {
                    name: ["Gariyaband"],
                }, {
                    name: ["Janjgir-Champa"],
                }, {
                    name: ["Jashpur"],
                }, {
                    name: ["Kabirdham (Kawardha)"],
                }, {
                    name: ["Kanker (North Bastar)"],
                }, {
                    name: ["Kondagaon"],
                }, {
                    name: ["Korba"],
                }, {
                    name: ["Korea (Koriya)"],
                }, {
                    name: ["Mahasamund"],
                }, {
                    name: ["Mungeli"],
                }, {
                    name: ["Narayanpur"],
                }, {
                    name: ["Raigarh"],
                }, {
                    name: ["Raipur"],
                }, {
                    name: ["Rajnandgaon"],
                }, {
                    name: ["Sukma"],
                }, {
                    name: ["Surajpur"],
                }, {
                    name: ["Surguja"],
                }
            ]

        }, {
            name: "Dadra and Nagar Haveli",
            states: [
                {
                    name: ["Dadra & Nagar Haveli"],
                }
            ]

        }, {
            name: "Daman and Diu",

            states: [
                {
                    name: ["Daman"],
                }, {
                    name: ["Diu"],
                }
            ]

        }, {
            name: "Delhi",

            states: [
                {
                    name: ["Central Delhi"],
                }, {
                    name: ["East Delhi"],
                }, {
                    name: ["New Delhi"],
                }, {
                    name: ["North Delhi"],
                }, {
                    name: ["North East Delhi"],
                }, {
                    name: ["North West Delhi"],
                }, {
                    name: ["Shahdara"],
                }, {
                    name: ["South Delhi"],
                }, {
                    name: ["South East Delhi"],
                }, {
                    name: ["South West Delhi"],
                }, {
                    name: ["West Delhi"],
                }
            ]

        }, {
            name: "Goa",

            states: [
                {
                    name: ["North Goa"],
                }, {
                    name: ["South Goa"],
                }
            ]

        }, {
            name: "Gujarat",

            states: [
                {
                    name: ["Ahmedabad"],
                }, {
                    name: ["Amreli"],
                }, {
                    name: ["Anand"],
                }, {
                    name: ["Aravalli"],
                }, {
                    name: ["Banaskantha (Palanpur)"],
                }, {
                    name: ["Bharuch"],
                }, {
                    name: ["Bhavnagar"],
                }, {
                    name: ["Botad"],
                }, {
                    name: ["Chhota Udepur"],
                }, {
                    name: ["Dahod"],
                }, {
                    name: ["Dangs (Ahwa)"],
                }, {
                    name: ["Devbhoomi Dwarka"],
                }, {
                    name: ["Gandhinagar"],
                }, {
                    name: ["Gir Somnath"],
                }, {
                    name: ["Jamnagar"],
                }, {
                    name: ["Junagadh"],
                }, {
                    name: ["Kachchh"],
                }, {
                    name: ["Kheda (Nadiad)"],
                }, {
                    name: ["Mahisagar"],
                }, {
                    name: ["Mehsana"],
                }, {
                    name: ["Morbi"],
                }, {
                    name: ["Narmada (Rajpipla)"],
                }, {
                    name: ["Navsari"],
                }, {
                    name: ["Panchmahal (Godhra)"],
                }, {
                    name: ["Patan"],
                }, {
                    name: ["Porbandar"],
                }, {
                    name: ["Rajkot"],
                }, {
                    name: ["Sabarkantha (Himmatnagar)"],
                }, {
                    name: ["Surat"],
                }, {
                    name: ["Surendranagar"],
                }, {
                    name: ["Tapi (Vyara)"],
                }, {
                    name: ["Vadodara"],
                }, {
                    name: ["Valsad"],
                }
            ]

        }, {
            name: "Haryana",

            states: [
                {
                    name: ["Ambala"],
                }, {
                    name: ["Bhiwani"],
                }, {
                    name: ["Charkhi Dadri"],
                }, {
                    name: ["Faridabad"],
                }, {
                    name: ["Fatehabad"],
                }, {
                    name: ["Gurugram (Gurgaon)"],
                }, {
                    name: ["Hisar"],
                }, {
                    name: ["Jhajjar"],
                }, {
                    name: ["Jind"],
                }, {
                    name: ["Kaithal"],
                }, {
                    name: ["Karnal"],
                }, {
                    name: ["Kurukshetra"],
                }, {
                    name: ["Mahendragarh"],
                }, {
                    name: ["Nuh"],
                }, {
                    name: ["Palwal"],
                }, {
                    name: ["Panchkula"],
                }, {
                    name: ["Panipat"],
                }, {
                    name: ["Rewari"],
                }, {
                    name: ["Rohtak"],
                }, {
                    name: ["Sirsa"],
                }, {
                    name: ["Sonipat"],
                }, {
                    name: ["Yamunanagar"],
                }
            ]

        }, {
            name: "Himachal Pradesh",

            states: [
                {
                    name: ["Bilaspur"],
                }, {
                    name: ["Chamba"],
                }, {
                    name: ["Hamirpur"],
                }, {
                    name: ["Kangra"],
                }, {
                    name: ["Kinnaur"],
                }, {
                    name: ["Kullu"],
                }, {
                    name: ["Lahaul & Spiti"],
                }, {
                    name: ["Mandi"],
                }, {
                    name: ["Shimla"],
                }, {
                    name: ["Sirmaur (Sirmour)"],
                }, {
                    name: ["Solan"],
                }, {
                    name: ["Una"],
                }
            ]

        }, {
            name: "Jammu and Kashmir",

            states: [
                {
                    name: ["Anantnag"],
                }, {
                    name: ["Bandipore"],
                }, {
                    name: ["Baramulla"],
                }, {
                    name: ["Budgam"],
                }, {
                    name: ["Doda"],
                }, {
                    name: ["Ganderbal"],
                }, {
                    name: ["Jammu"],
                }, {
                    name: ["Kathua"],
                }, {
                    name: ["Kishtwar"],
                }, {
                    name: ["Kulgam"],
                }, {
                    name: ["Kupwara"],
                }, {
                    name: ["Poonch"],
                }, {
                    name: ["Pulwama"],
                }, {
                    name: ["Rajouri"],
                }, {
                    name: ["Ramban"],
                }, {
                    name: ["Reasi"],
                }, {
                    name: ["Samba"],
                }, {
                    name: ["Shopian"],
                }, {
                    name: ["Srinagar"],
                }, {
                    name: ["Udhampur"],
                }
            ]

        }, {
            name: "Jharkhand",

            states: [
                {
                    name: ["Bokaro"],
                }, {
                    name: ["Chatra"],
                }, {
                    name: ["Deoghar"],
                }, {
                    name: ["Dhanbad"],
                }, {
                    name: ["Dumka"],
                }, {
                    name: ["East Singhbhum"],
                }, {
                    name: ["Garhwa"],
                }, {
                    name: ["Giridih"],
                }, {
                    name: ["Godda"],
                }, {
                    name: ["Gumla"],
                }, {
                    name: ["Hazaribag"],
                }, {
                    name: ["Jamtara"],
                }, {
                    name: ["Khunti"],
                }, {
                    name: ["Koderma"],
                }, {
                    name: ["Latehar"],
                }, {
                    name: ["Lohardaga"],
                }, {
                    name: ["Pakur"],
                }, {
                    name: ["Palamu"],
                }, {
                    name: ["Ramgarh"],
                }, {
                    name: ["Ranchi"],
                }, {
                    name: ["Sahibganj"],
                }, {
                    name: ["Seraikela-Kharsawan"],
                }, {
                    name: ["Simdega"],
                }, {
                    name: ["West Singhbhum"],
                }
            ]

        }, {
            name: "Karnataka",

            states: [
                {
                    name: ["Bagalkot"],
                }, {
                    name: ["Ballari (Bellary)"],
                }, {
                    name: ["Belagavi (Belgaum)"],
                }, {
                    name: ["Bengaluru (Bangalore) Rural"],
                }, {
                    name: ["Bengaluru (Bangalore) Urban"],
                }, {
                    name: ["Bidar"],
                }, {
                    name: ["Chamarajanagar"],
                }, {
                    name: ["Chikballapur"],
                }, {
                    name: ["Chikkamagaluru (Chikmagalur)"],
                }, {
                    name: ["Chitradurga"],
                }, {
                    name: ["Dakshina Kannada"],
                }, {
                    name: ["Davangere"],
                }, {
                    name: ["Dharwad"],
                }, {
                    name: ["Gadag"],
                }, {
                    name: ["Hassan"],
                }, {
                    name: ["Haveri"],
                }, {
                    name: ["Kalaburagi (Gulbarga)"],
                }, {
                    name: ["Kodagu"],
                }, {
                    name: ["Kolar"],
                }, {
                    name: ["Koppal"],
                }, {
                    name: ["Mandya"],
                }, {
                    name: ["Mysuru (Mysore)"],
                }, {
                    name: ["Raichur"],
                }, {
                    name: ["Ramanagara"],
                }, {
                    name: ["Shivamogga (Shimoga)"],
                }, {
                    name: ["Tumakuru (Tumkur)"],
                }, {
                    name: ["Udupi"],
                }, {
                    name: ["Uttara Kannada (Karwar)"],
                }, {
                    name: ["Vijayapura (Bijapur)"],
                }, {
                    name: ["Yadgir"],
                }
            ]

        }, {
            name: "Kerala",

            states: [
                {
                    name: ["Alappuzha"],
                }, {
                    name: ["Ernakulam"],
                }, {
                    name: ["Idukki"],
                }, {
                    name: ["Kannur"],
                }, {
                    name: ["Kasaragod"],
                }, {
                    name: ["Kollam"],
                }, {
                    name: ["Kottayam"],
                }, {
                    name: ["Kozhikode"],
                }, {
                    name: ["Malappuram"],
                }, {
                    name: ["Palakkad"],
                }, {
                    name: ["Pathanamthitta"],
                }, {
                    name: ["Thiruvananthapuram"],
                }, {
                    name: ["Thrissur"],
                }, {
                    name: ["Wayanad"],
                }
            ]

        }, {
            name: "Ladakh",

            states: [
                {
                    name: ["Kargil"],
                }, {
                    name: ["Leh"],
                }
            ]

        }, {
            name: "Madhya Pradesh",

            states: [
                {
                    name: ["Agar Malwa"],
                }, {
                    name: ["Alirajpur"],
                }, {
                    name: ["Anuppur"],
                }, {
                    name: ["Ashoknagar"],
                }, {
                    name: ["Balaghat"],
                }, {
                    name: ["Barwani"],
                }, {
                    name: ["Betul"],
                }, {
                    name: ["Bhind"],
                }, {
                    name: ["Bhopal"],
                }, {
                    name: ["Burhanpur"],
                }, {
                    name: ["Chhatarpur"],
                }, {
                    name: ["Chhindwara"],
                }, {
                    name: ["Damoh"],
                }, {
                    name: ["Datia"],
                }, {
                    name: ["Dewas"],
                }, {
                    name: ["Dhar"],
                }, {
                    name: ["Dindori"],
                }, {
                    name: ["Guna"],
                }, {
                    name: ["Gwalior"],
                }, {
                    name: ["Harda"],
                }, {
                    name: ["Hoshangabad"],
                }, {
                    name: ["Indore"],
                }, {
                    name: ["Jabalpur"],
                }, {
                    name: ["Jhabua"],
                }, {
                    name: ["Katni"],
                }, {
                    name: ["Khandwa"],
                }, {
                    name: ["Khargone"],
                }, {
                    name: ["Mandla"],
                }, {
                    name: ["Mandsaur"],
                }, {
                    name: ["Morena"],
                }, {
                    name: ["Narsinghpur"],
                }, {
                    name: ["Neemuch"],
                }, {
                    name: ["Panna"],
                }, {
                    name: ["Raisen"],
                }, {
                    name: ["Rajgarh"],
                }, {
                    name: ["Ratlam"],
                }, {
                    name: ["Rewa"],
                }, {
                    name: ["Sagar"],
                }, {
                    name: ["Satna"],
                }, {
                    name: ["Sehore"],
                }, {
                    name: ["Seoni"],
                }, {
                    name: ["Shahdol"],
                }, {
                    name: ["Shajapur"],
                }, {
                    name: ["Sheopur"],
                }, {
                    name: ["Shivpuri"],
                }, {
                    name: ["Sidhi"],
                }, {
                    name: ["Singrauli"],
                }, {
                    name: ["Tikamgarh"],
                }, {
                    name: ["Ujjain"],
                }, {
                    name: ["Umaria"],
                }, {
                    name: ["Vidisha"],
                }
            ]

        }, {
            name: "Maharashtra",

            states: [
                {
                    name: ["Ahmednagar"],
                }, {
                    name: ["Akola"],
                }, {
                    name: ["Amravati"],
                }, {
                    name: ["Aurangabad"],
                }, {
                    name: ["Beed"],
                }, {
                    name: ["Bhandara"],
                }, {
                    name: ["Buldhana"],
                }, {
                    name: ["Chandrapur"],
                }, {
                    name: ["Dhule"],
                }, {
                    name: ["Gadchiroli"],
                }, {
                    name: ["Gondia"],
                }, {
                    name: ["Hingoli"],
                }, {
                    name: ["Jalgaon"],
                }, {
                    name: ["Jalna"],
                }, {
                    name: ["Kolhapur"],
                }, {
                    name: ["Latur"],
                }, {
                    name: ["Mumbai City"],
                }, {
                    name: ["Mumbai Suburban"],
                }, {
                    name: ["Nagpur"],
                }, {
                    name: ["Nanded"],
                }, {
                    name: ["Nandurbar"],
                }, {
                    name: ["Nashik"],
                }, {
                    name: ["Osmanabad"],
                }, {
                    name: ["Palghar"],
                }, {
                    name: ["Parbhani"],
                }, {
                    name: ["Pune"],
                }, {
                    name: ["Raigad"],
                }, {
                    name: ["Ratnagiri"],
                }, {
                    name: ["Sangli"],
                }, {
                    name: ["Satara"],
                }, {
                    name: ["Sindhudurg"],
                }, {
                    name: ["Solapur"],
                }, {
                    name: ["Thane"],
                }, {
                    name: ["Wardha"],
                }, {
                    name: ["Washim"],
                }, {
                    name: ["Yavatmal"],
                }
            ]

        }, {
            name: "Manipur",

            states: [
                {
                    name: ["Bishnupur"],
                }, {
                    name: ["Chandel"],
                }, {
                    name: ["Churachandpur"],
                }, {
                    name: ["Imphal East"],
                }, {
                    name: ["Imphal West"],
                }, {
                    name: ["Jiribam"],
                }, {
                    name: ["Kakching"],
                }, {
                    name: ["Kamjong"],
                }, {
                    name: ["Kangpokpi"],
                }, {
                    name: ["Noney"],
                }, {
                    name: ["Pherzawl"],
                }, {
                    name: ["Senapati"],
                }, {
                    name: ["Tamenglong"],
                }, {
                    name: ["Tengnoupal"],
                }, {
                    name: ["Thoubal"],
                }, {
                    name: ["Ukhrul"],
                }
            ]

        }, {
            name: "Meghalaya",

            states: [
                {
                    name: ["East Garo Hills"],
                }, {
                    name: ["East Jaintia Hills"],
                }, {
                    name: ["East Khasi Hills"],
                }, {
                    name: ["North Garo Hills"],
                }, {
                    name: ["Ri Bhoi"],
                }, {
                    name: ["South Garo Hills"],
                }, {
                    name: ["South West Garo Hills"],
                }, {
                    name: ["South West Khasi Hills"],
                }, {
                    name: ["West Garo Hills"],
                }, {
                    name: ["West Jaintia Hills"],
                }, {
                    name: ["West Khasi Hills"],
                }
            ]

        }, {
            name: "Mizoram",

            states: [
                {
                    name: ["Aizawl"],
                }, {
                    name: ["Champhai"],
                }, {
                    name: ["Kolasib"],
                }, {
                    name: ["Lawngtlai"],
                }, {
                    name: ["Lunglei"],
                }, {
                    name: ["Mamit"],
                }, {
                    name: ["Saiha"],
                }, {
                    name: ["Serchhip"],
                }
            ]

        }, {
            name: "Nagaland",

            states: [
                {
                    name: ["Dimapur"],
                }, {
                    name: ["Kiphire"],
                }, {
                    name: ["Kohima"],
                }, {
                    name: ["Longleng"],
                }, {
                    name: ["Mokokchung"],
                }, {
                    name: ["Mon"],
                }, {
                    name: ["Peren"],
                }, {
                    name: ["Phek"],
                }, {
                    name: ["Tuensang"],
                }, {
                    name: ["Wokha"],
                }, {
                    name: ["Zunheboto"],
                }
            ]

        }, {
            name: "Odisha",

            states: [
                {
                    name: ["Angul"],
                }, {
                    name: ["Balangir"],
                }, {
                    name: ["Balasore"],
                }, {
                    name: ["Bargarh"],
                }, {
                    name: ["Bhadrak"],
                }, {
                    name: ["Boudh"],
                }, {
                    name: ["Cuttack"],
                }, {
                    name: ["Deogarh"],
                }, {
                    name: ["Dhenkanal"],
                }, {
                    name: ["Gajapati"],
                }, {
                    name: ["Ganjam"],
                }, {
                    name: ["Jagatsinghapur"],
                }, {
                    name: ["Jajpur"],
                }, {
                    name: ["Jharsuguda"],
                }, {
                    name: ["Kalahandi"],
                }, {
                    name: ["Kandhamal"],
                }, {
                    name: ["Kendrapara"],
                }, {
                    name: ["Kendujhar (Keonjhar)"],
                }, {
                    name: ["Khordha"],
                }, {
                    name: ["Koraput"],
                }, {
                    name: ["Malkangiri"],
                }, {
                    name: ["Mayurbhanj"],
                }, {
                    name: ["Nabarangpur"],
                }, {
                    name: ["Nayagarh"],
                }, {
                    name: ["Nuapada"],
                }, {
                    name: ["Puri"],
                }, {
                    name: ["Rayagada"],
                }, {
                    name: ["Sambalpur"],
                }, {
                    name: ["Sonepur"],
                }, {
                    name: ["Sundargarh"],
                }
            ]

        }, {
            name: "Puducherry",

            states: [
                {
                    name: ["Karaikal"],
                }, {
                    name: ["Mahe"],
                }, {
                    name: ["Puducherry"],
                }, {
                    name: ["Yanam"],
                }
            ]

        }, {
            name: "Punjab",

            states: [
                {
                    name: ["Amritsar"],
                }, {
                    name: ["Barnala"],
                }, {
                    name: ["Bathinda"],
                }, {
                    name: ["Faridkot"],
                }, {
                    name: ["Fatehgarh Sahib"],
                }, {
                    name: ["Fazilka"],
                }, {
                    name: ["Ferozepur"],
                }, {
                    name: ["Gurdaspur"],
                }, {
                    name: ["Hoshiarpur"],
                }, {
                    name: ["Jalandhar"],
                }, {
                    name: ["Kapurthala"],
                }, {
                    name: ["Ludhiana"],
                }, {
                    name: ["Mansa"],
                }, {
                    name: ["Moga"],
                }, {
                    name: ["Muktsar"],
                }, {
                    name: ["Nawanshahr (Shahid Bhagat Singh Nagar)"],
                }, {
                    name: ["Pathankot"],
                }, {
                    name: ["Patiala"],
                }, {
                    name: ["Rupnagar"],
                }, {
                    name: ["Sahibzada Ajit Singh Nagar (Mohali)"],
                }, {
                    name: ["Sangrur"],
                }, {
                    name: ["Tarn Taran"],
                }
            ]

        }, {
            name: "Rajasthan",

            states: [
                {
                    name: ["Ajmer"],
                }, {
                    name: ["Alwar"],
                }, {
                    name: ["Banswara"],
                }, {
                    name: ["Baran"],
                }, {
                    name: ["Baran"],
                }, {
                    name: ["Barmer"],
                }, {
                    name: ["Bharatpur"],
                }, {
                    name: ["Bhilwara"],
                }, {
                    name: ["Bikaner"],
                }, {
                    name: ["Bundi"],
                }, {
                    name: ["Chittorgarh"],
                }, {
                    name: ["Churu"],
                }, {
                    name: ["Dausa"],
                }, {
                    name: ["Dholpur"],
                }, {
                    name: ["Dungarpur"],
                }, {
                    name: ["Hanumangarh"],
                }, {
                    name: ["Jaipur"],
                }, {
                    name: ["Jaisalmer"],
                }, {
                    name: ["Jalore"],
                }, {
                    name: ["Jhalawar"],
                }, {
                    name: ["Jhunjhunu"],
                }, {
                    name: ["Jodhpur"],
                }, {
                    name: ["Karauli"],
                }, {
                    name: ["Kota"],
                }, {
                    name: ["Nagaur"],
                }, {
                    name: ["Pali"],
                }, {
                    name: ["Pratapgarh"],
                }, {
                    name: ["Rajasamand"],
                }, {
                    name: ["Sawai Madhopur"],
                }, {
                    name: ["Sikar"],
                }, {
                    name: ["Sirohi"],
                }, {
                    name: ["Sri Ganganagar"],
                }, {
                    name: ["Tonk"],
                }, {
                    name: ["Udaipur"],
                }
            ]

        }, {
            name: "Sikkim",

            states: [
                {
                    name: ["East Sikkim"],
                }, {
                    name: ["North Sikkim"],
                }, {
                    name: ["South Sikkim"],
                }, {
                    name: ["West Sikkim"],
                }
            ]

        }, {
            name: "Tamil Nadu",

            states: [
                {
                    name: ["Ariyalur"],
                }, {
                    name: ["Chengalpattu"],
                }, {
                    name: ["Chennai"],
                }, {
                    name: ["Coimbatore"],
                }, {
                    name: ["Cuddalore"],
                }, {
                    name: ["Dharmapuri"],
                }, {
                    name: ["Dindigul"],
                }, {
                    name: ["Erode"],
                }, {
                    name: ["Kallakurichi"],
                }, {
                    name: ["Kanchipuram"],
                }, {
                    name: ["Kanyakumari"],
                }, {
                    name: ["Karur"],
                }, {
                    name: ["Krishnagiri"],
                }, {
                    name: ["Madurai"],
                }, {
                    name: ["Nagapattinam"],
                }, {
                    name: ["Namakkal"],
                }, {
                    name: ["Nilgiris"],
                }, {
                    name: ["Perambalur"],
                }, {
                    name: ["Pudukkottai"],
                }, {
                    name: ["Ramanathapuram"],
                }, {
                    name: ["Ranipet"],
                }, {
                    name: ["Salem"],
                }, {
                    name: ["Sivaganga"],
                }, {
                    name: ["Tenkasi"],
                }, {
                    name: ["Thanjavur"],
                }, {
                    name: ["Theni"],
                }, {
                    name: ["Thoothukudi (Tuticorin)"],
                }, {
                    name: ["Tiruchirappalli"],
                }, {
                    name: ["Tirunelveli"],
                }, {
                    name: ["Tirupathur"],
                }, {
                    name: ["Tiruppur"],
                }, {
                    name: ["Tiruvallur"],
                }, {
                    name: ["Tiruvannamalai"],
                }, {
                    name: ["Tiruvarur"],
                }, {
                    name: ["Vellore"],
                }, {
                    name: ["Viluppuram"],
                }, {
                    name: ["Virudhunagar"],
                }
            ]

        }, {
            name: "Telangana",

            states: [
                {
                    name: ["Adilabad"],
                }, {
                    name: ["Bhadradri Kothagudem"],
                }, {
                    name: ["Adilabad"],
                }, {
                    name: ["Bhadradri Kothagudem"],
                }, {
                    name: ["Hyderabad"],
                }, {
                    name: ["Jagtial"],
                }, {
                    name: ["Jangaon"],
                }, {
                    name: ["Jayashankar Bhoopalpally"],
                }, {
                    name: ["Jogulamba Gadwal"],
                }, {
                    name: ["Kamareddy"],
                }, {
                    name: ["Karimnagar"],
                }, {
                    name: ["Khammam"],
                }, {
                    name: ["Komaram Bheem Asifabad"],
                }, {
                    name: ["Mahabubabad"],
                }, {
                    name: ["Mahabubnagar"],
                }, {
                    name: ["Mancherial"],
                }, {
                    name: ["Medak"],
                }, {
                    name: ["Medchal"],
                }, {
                    name: ["Nagarkurnool"],
                }, {
                    name: ["Nalgonda"],
                }, {
                    name: ["Nirmal"],
                }, {
                    name: ["Nizamabad"],
                }, {
                    name: ["Peddapalli"],
                }, {
                    name: ["Rajanna Sircilla"],
                }, {
                    name: ["Rangareddy"],
                }, {
                    name: ["Sangareddy"],
                }, {
                    name: ["Siddipet"],
                }, {
                    name: ["Suryapet"],
                }, {
                    name: ["Vikarabad"],
                }, {
                    name: ["Wanaparthy"],
                }, {
                    name: ["Warangal (Rural)"],
                }, {
                    name: ["Warangal (Urban)"],
                }, {
                    name: ["Yadadri Bhuvanagiri"],
                }
            ]
        }, {
            name: "Tripura",

            states: [
                {
                    name: ["Dhalai"],
                }, {
                    name: ["Gomati"],
                }, {
                    name: ["Khowai"],
                }, {
                    name: ["North Tripura"],
                }, {
                    name: ["Sepahijala"],
                }, {
                    name: ["South Tripura"],
                }, {
                    name: ["Unakoti"],
                }, {
                    name: ["West Tripura"],
                }
            ]

        }, {
            name: "Uttarakhand",

            states: [
                {
                    name: ["Almora"],
                }, {
                    name: ["Bageshwar"],
                }, {
                    name: ["Chamoli"],
                }, {
                    name: ["Champawat"],
                }, {
                    name: ["Dehradun"],
                }, {
                    name: ["Haridwar"],
                }, {
                    name: ["Nainital"],
                }, {
                    name: ["Pauri Garhwal"],
                }, {
                    name: ["Pithoragarh"],
                }, {
                    name: ["Rudraprayag"],
                }, {
                    name: ["Tehri Garhwal"],
                }, {
                    name: ["Udham Singh Nagar"],
                }, {
                    name: ["Uttarkashi"],
                }
            ]

        }, {
            name: "Uttar Pradesh",

            states: [
                {
                    name: ["Agra"],
                }, {
                    name: ["Aligarh"],
                }, {
                    name: ["Ambedkar Nagar"],
                }, {
                    name: ["Amethi (Chatrapati Sahuji Mahraj Nagar)"],
                }, {
                    name: ["Amroha (J.P. Nagar)"],
                }, {
                    name: ["Auraiya"],
                }, {
                    name: ["Azamgarh"],
                }, {
                    name: ["Baghpat"],
                }, {
                    name: ["Bahraich"],
                }, {
                    name: ["Ballia"],
                }, {
                    name: ["Balrampur"],
                }, {
                    name: ["Banda"],
                }, {
                    name: ["Barabanki"],
                }, {
                    name: ["Bareilly"],
                }, {
                    name: ["Basti"],
                }, {
                    name: ["Bhadohi"],
                }, {
                    name: ["Bijnor"],
                }, {
                    name: ["Budaun"],
                }, {
                    name: ["Bulandshahr"],
                }, {
                    name: ["Chandauli"],
                }, {
                    name: ["Chitrakoot"],
                }, {
                    name: ["Deoria"],
                }, {
                    name: ["Etah"],
                }, {
                    name: ["Etawah"],
                }, {
                    name: ["Faizabad"],
                }, {
                    name: ["Farrukhabad"],
                }, {
                    name: ["Fatehpur"],
                }, {
                    name: ["Firozabad"],
                }, {
                    name: ["Gautam Buddha Nagar"],
                }, {
                    name: ["Ghaziabad"],
                }, {
                    name: ["Ghazipur"],
                }, {
                    name: ["Gonda"],
                }, {
                    name: ["Gorakhpur"],
                }, {
                    name: ["Hamirpur"],
                }, {
                    name: ["Hapur (Panchsheel Nagar)"],
                }, {
                    name: ["Hardoi"],
                }, {
                    name: ["Hathras"],
                }, {
                    name: ["Jalaun"],
                }, {
                    name: ["Jaunpur"],
                }, {
                    name: ["Jhansi"],
                }, {
                    name: ["Kannauj"],
                }, {
                    name: ["Kanpur Dehat"],
                }, {
                    name: ["Kanpur Nagar"],
                }, {
                    name: ["Kanshiram Nagar (Kasganj)"],
                }, {
                    name: ["Kaushambi"],
                }, {
                    name: ["Kushinagar (Padrauna)"],
                }, {
                    name: ["Lakhimpur - Kheri"],
                }, {
                    name: ["Lalitpur"],
                }, {
                    name: ["Lucknow"],
                }, {
                    name: ["Maharajganj"],
                }, {
                    name: ["Mahoba"],
                }, {
                    name: ["Mainpuri"],
                }, {
                    name: ["Mathura"],
                }, {
                    name: ["Mau"],
                }, {
                    name: ["Meerut"],
                }, {
                    name: ["Mirzapur"],
                }, {
                    name: ["Moradabad"],
                }, {
                    name: ["Muzaffarnagar"],
                }, {
                    name: ["Pilibhit"],
                }, {
                    name: ["Pratapgarh"],
                }, {
                    name: ["Prayagraj"],
                }, {
                    name: ["RaeBareli"],
                }, {
                    name: ["Rampur"],
                }, {
                    name: ["Saharanpur"],
                }, {
                    name: ["Sambhal (Bhim Nagar)"],
                }, {
                    name: ["Sant Kabir Nagar"],
                }, {
                    name: ["Shahjahanpur"],
                }, {
                    name: ["Shamali (Prabuddh Nagar)"],
                }, {
                    name: ["Shravasti"],
                }, {
                    name: ["Siddharth Nagar"],
                }, {
                    name: ["Sitapur"],
                }, {
                    name: ["Sonbhadra"],
                }, {
                    name: ["Sultanpur"],
                }, {
                    name: ["Unnao"],
                }, {
                    name: ["Varanasi"],
                }
            ]

        }, {
            name: "West Bengal",

            states: [
                {
                    name: ["Alipurduar"],
                }, {
                    name: ["Bankura"],
                }, {
                    name: ["Birbhum"],
                }, {
                    name: ["Cooch Behar"],
                }, {
                    name: ["Dakshin Dinajpur (South Dinajpur)"],
                }, {
                    name: ["Darjeeling"],
                }, {
                    name: ["Hooghly"],
                }, {
                    name: ["Howrah"],
                }, {
                    name: ["Jalpaiguri"],
                }, {
                    name: ["Jhargram"],
                }, {
                    name: ["Kalimpong"],
                }, {
                    name: ["Kolkata"],
                }, {
                    name: ["Malda"],
                }, {
                    name: ["Murshidabad"],
                }, {
                    name: ["Nadia"],
                }, {
                    name: ["North 24 Parganas"],
                }, {
                    name: ["Paschim Medinipur (West Medinipur)"],
                }, {
                    name: ["Paschim (West) Burdwan (Bardhaman)"],
                }, {
                    name: ["Purba Burdwan (Bardhaman)"],
                }, {
                    name: ["Purba Medinipur (East Medinipur)"],
                }, {
                    name: ["Purulia"],
                }, {
                    name: ["South 24 Parganas"],
                }, {
                    name: ["Uttar Dinajpur (North Dinajpur)"],
                }
            ]
        },
    ]
};