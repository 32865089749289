import React from "react";
import "./Team.css";
import Footer from "../Footers/Footer.js";

function Team() {
  return (
    <div className="show_school">
      <div className="show_canva">
  <iframe loading="lazy" className="show-c-frame"
    src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAE2julR134&#x2F;view?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
  </iframe>
</div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Team;