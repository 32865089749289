import React, { useState } from "react";
import "./Login.css";
import { firebase, cheq, auth, googleProvider, appleProvider } from "./firebaseConfig";
import { BrowserRouter as Link } from "react-router-dom";

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Header from "./HeaderGen";
import FooterGen from "./FooterGen";
import FooterDH from "./FooterDH";
import ClientDetails from "./school_corporate/components/clientDetails";
import FooterNext from "./Footers/FooterNext";

import { Button } from "react-bootstrap";
import Background from "./background";
import ShowBranches from "./components/showBranches";
import ShowExperts from "./components/showExperts";
import ShowMemes from "./components/showMemes";
import { FaGoogle, FaApple } from 'react-icons/fa';
import { Container, Row, Col, Card } from 'react-bootstrap';

import img1 from '/Users/divyashree/Downloads/dr planete web/folder/dr_planete/src/club/images/i4.png';
import newlogo from '/Users/divyashree/Downloads/dr planete web/folder/dr_planete/src/club/images/logonew.png';

const Login = () => {
  // Inputs
  
  /*
  const [mynumber, setnumber] = useState("");
  const [otp, setotp] = useState("");
  const [show, setshow] = useState(false);
  const [final, setfinal] = useState("");

  // Sent OTP

  const signin = () => {
    if (mynumber === "" || mynumber.length < 10) return;

    let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
    cheq
      .signInWithPhoneNumber("+91" + mynumber, verify)
      .then((result) => {
        setfinal(result);
        <div class="alert alert-success" role="alert">
          A simple success alert—check it out!
        </div>;
        //alert("code sent");
        setshow(true);
      })
      .catch((err) => {
        alert(err);
        window.location.reload();
      });
  };

  // Validate OTP
  const ValidateOtp = () => {
    if (otp === null || final === null) return;
    final
      .confirm(otp)
      .then((result) => {
        // success
      })
      .catch((err) => {
        alert("Wrong code");
      });
  };


  */

  const signInWithGoogle = async () => {
    try {
      await auth.signInWithPopup(googleProvider);
    } catch (error) {
      console.error("Error signing in with Google", error);
    }
  };

  const signInWithApple = async () => {
    try {
      await auth.signInWithPopup(appleProvider);
    } catch (error) {
      console.error("Error signing in with Apple", error);
    }
  };

  return (

    <div>
      
      <Container className="my-5">
        {/* Image Sections with Paragraphs */}

        <Row className="mb-4">

          
          <Col md={6}>
            <img
              className="d-block w-100 mb-3"
              src={img1}
              alt="First section"
              style={{ width: '80%', height: 'auto' }} // Adjust the size if needed
            />
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <img
              className="d-block w-100 mb-3"
              src={newlogo}
              alt="First section"
              style={{ width: '40%', border: '2px solid #fff', height: 'auto' }}
            />

            <div>

            <div className="d-flex flex-column align-items-center">

            <Button
        variant="dark"
        className="d-flex align-items-center justify-content-center w-100 my-2"
        onClick={signInWithApple}
      ><FaApple className="me-2" />
      Sign in with Apple
    </Button>
      <Button
        variant="danger"
        className="d-flex align-items-center justify-content-center w-100 my-2"
        onClick={signInWithGoogle}
      >
        <FaGoogle className="me-2" />
        Sign in with Google
      </Button>
      
        
    </div>
            
              
            </div>

            <div>
            </div>
          </Col>
        </Row>

      </Container>
      

    </div>
    
  );
}
export default Login;



{/*
    <div className="login-container">

      <center>

        <div class="container" >

          <div className="get-things" style={{opacity:"0"}}>
            <h5><b>Login to get Started!!</b></h5>
            <h5><b>Login to get Started!!</b></h5>
            <h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5><h5><b>Login to get Started!!</b></h5>
          </div>

          <div className="show-stuff" style={{opacity:"0"}}>
            <h5><b>Login to get Started!!</b></h5>
            <h5><b>Login to get Started!!</b></h5>
            <h5><b>Login to get Started!!</b></h5>
          </div>

          <div>
            <div style={{ display: !show ? "block" : "none" }}>

              <TextField id="outlined-basic" fullWidth label="Phone Number" value={mynumber} onChange={(e) => {
                setnumber(e.target.value);
              }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                }} variant="outlined" />

              <br />
              <br />
              <div id="recaptcha-container"></div>
              <button
                class="btn btn-success"
                style={{ marginTop: "5px" }}
                onClick={signin}
              >
                Send OTP
              </button>
            </div>
            <div
              class="form-group form-control-lg col-lg-3"
              style={{ display: show ? "block" : "none" }}
            >

              <TextField id="outlined-basic" fullWidth label="Enter OTP"
                onChange={(e) => {
                  setotp(e.target.value);
                }}
                variant="outlined" />
              <br />
              <br />
              <Button variant="primary" onClick={ValidateOtp}>
                Verify
              </Button>
             
            </div>
          </div>

         
        </div>
        
        <div class="show-stuff" style={{marginTop:"250px"}}>

          <div>
            <ShowExperts/>
          </div>

          <div>
            <ShowMemes/>
          </div>

          
        </div>

      </center>
      <div className="show-footer">
      <FooterNext />
      </div>
      
    </div > */}