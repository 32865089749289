import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  CompanyHighlight
} from "./FooterStyles";

export default function CompanyName() {
  return (
    <div class="bg-black" style={{ height: "auto", marginLeft: "600px"}}>
      <div>
        <div class="text-stone-50 font-bold font-mono">
          <CompanyHighlight>
            <i className="far fa-copyright">{"  "}</i>
             2022 Sungowin Technologies Pvt. Ltd.
          </CompanyHighlight>
        </div>
        <CompanyHighlight class="text-center" style={{marginLeft:"85px"}}>All Rights Reserved.</CompanyHighlight>
      </div>
    </div>
  );
}
