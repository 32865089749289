import React, { useState, useContext } from "react";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    Link,
} from "react-router-dom";
import ShowColumn from "../components/showColumn";
import { Button } from "react-bootstrap";
import FooterNew from "../Footers/Footer";
export default function ExamAlerts({

}) {

    return (
        <div>
            <h1 class="center">Exam Alerts</h1>
        </div>
    );
}
