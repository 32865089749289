import React, { useState, useContext } from "react";
import { db } from "../../firebaseConfig";
import Carousel from "react-bootstrap/Carousel";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";



export default function Rightside({ slideshow_image, onClickfunction }) {
  const [user, setUser] = useState("");
  const deletePost = () => {};

  const [posts, setPosts] = useState([]);
  db.collection("slideshow/medicalstudent/get").onSnapshot((snapshot) => {
    setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
  });

  
  return (
    <div>
      <Carousel stopOnHover={false}  autoPlay={true}
              infiniteLoop={true} style={{width:"380px", height:"680px"}}>
        {posts.map(({ id, post }) => {
          return (
            <Carousel.Item interval={100}>
              <Link to={post.onClickfunction} target="_blank">
              <img
                className="d-block w-100"
                src={post.slideshow_image}
                alt="Image One"
                style={{height: "650px", width: "100%"}}
              />
              </Link>
              <Carousel.Caption></Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel>
      {/*<span style={{ fontWeight: "500", marginRight: "4px" }}>
            {slideshow_image}
  </span>*/}
    </div>
  );
}
