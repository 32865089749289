import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterNext from '../../../../Footers/FooterNext';
import { Typography} from '@mui/material';


function RajasthanMedicalCouncilRegistration() {
  return (
    <div>
    <Container className="my-5">
      <Helmet>
        <title>How to Apply for Medical Council Registration in Rajasthan</title>
        <meta name="description" content="Step-by-step guide to applying for medical council registration in Rajasthan. Check eligibility, documents required, and renewal process." />
        <meta name="keywords" content="medical council registration, Rajasthan, RMC, doctor registration, eligibility, documents, renewal" />
      </Helmet>

      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title as="h1" className="text-center mb-4">How to Apply for Medical Council Registration in Rajasthan</Card.Title>
              <Card.Text >Here's a step-by-step guide to applying for medical council registration in Rajasthan:</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">1. Eligibility Check:</Card.Subtitle>
              <Card.Text>Ensure you meet the eligibility criteria set by the Rajasthan Medical Council (RMC) for registration.</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">2. Document Preparation:</Card.Subtitle>
              <Card.Text>Gather necessary documents including educational certificates, internship completion certificate, proof of date of birth, identity proof, address proof, and passport-sized photographs.</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">3. Application Form:</Card.Subtitle>
              <Card.Text>Obtain the application form from the RMC office or website and fill it carefully.</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">4. Submission of Application:</Card.Subtitle>
              <Card.Text>Submit the completed form along with required documents to the RMC office, including application fees.</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">5. Verification and Processing:</Card.Subtitle>
              <Card.Text>RMC will verify your documents and process the application.</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">6. Registration Certificate:</Card.Subtitle>
              <Card.Text>Once approved, you'll receive a registration certificate from RMC.</Card.Text>

              <Card.Subtitle as="h4" className="mt-4">7. Renewal:</Card.Subtitle>
              <Card.Text>Keep track of expiry date and renew registration on time.</Card.Text>

              <Card.Text>
                For more information, visit the <Card.Link href="https://www.rmcjaipur.org/">Rajasthan Medical Council website</Card.Link>.
              </Card.Text>

              <Card.Subtitle as="h4" className="mt-4">Important Notes:</Card.Subtitle>
              <ListGroup variant="flush" className="mb-4">
                <ListGroup.Item>
                  <strong>Online Registration:</strong> RMC is transitioning to a completely online system for doctor registration. Keep checking the RMC website for updates.
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Previous Application Process:</strong> If online system isn't available yet, refer to the RMC website for the previous application process.
                </ListGroup.Item>
              </ListGroup>

              <Card.Text>Stay updated by checking the RMC website regularly and contacting them directly for further assistance.</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Typography style= {{marginTop: "35px", marginBottom: "35px"}} variant="body2" color="textSecondary" className="disclaimer">
        Disclaimer: The information provided in this blog serves as a general guide and may vary based on specific regulations and requirements set forth by individual State Medical Councils in India. It is advisable to consult the respective State Medical Council or seek professional advice for personalized guidance regarding the application process for additional qualification registration. Dr Planete is not responsible for any inaccuracies, omissions, or any adverse consequences arising from the use of this information. Users are encouraged to verify the information independently and exercise due diligence before proceeding with any applications or decisions.
        </Typography>
    </Container>

    
    <FooterNext/>
    </div>
  );
}

export default RajasthanMedicalCouncilRegistration;
