import React from 'react';
import './Product.css';
import { useStateValue } from "./StateProvider";


function Product({image}){

    const [{basket}, dispatch] = useStateValue();

    const addToBasket = () => {

    };
    
    return(
        <div className='product'>
            <div className="product__info">
                <p></p>
                <p className="product__price">
                </p>
                <div className="product__rating">
                    <p>🌟</p>
                </div>

                <img src={image} alt=""/>
            </div>

          <button onClick={addToBasket} >Add to basket</button>

        </div>
    );
}




export default Product;