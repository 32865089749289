import React , {useState, useEffect} from 'react';
import firebase from "firebase";
import { cheq , storage, db} from '../../firebaseConfig';
import "../../index.css";

import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';


export default function SingleObservership({ todo }) {
    
  const deleteTodo = () => {
    const todoRef = firebase.database().ref(`Hospital/trainingcentre/${cheq.currentUser.phoneNumber}/observershipcourses`).child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('Todo').child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };
  return (
    <div class="container">
      <Card style={{marginTop: "35px", marginLeft:"35px", marginRight:"35px", marginBottom:"15px"}}>
      
      <Card.Title style={{marginTop: "15px", marginLeft:"15px", marginBottom:"4px"}}>Course Title: {todo.courseTitle}</Card.Title>
      <Card.Subtitle style={{marginTop: "1px", marginLeft:"15px", marginBottom:"1px"}}>Course Duration: {todo.courseDuration}</Card.Subtitle>
      <Card.Body>

      <p className={todo.complete ? 'complete' : ''}></p>
      <p className={todo.complete ? 'complete' : ''}></p>
      <p className={todo.complete ? 'complete' : ''}>Observership Starting Date - {todo.courseStartingDate}</p>
      <p className={todo.complete ? 'complete' : ''}>Observership Fees - {todo.courseFees}</p>
      <p className={todo.complete ? 'complete' : ''}>Observership Certificate - {todo.courseCertificate}</p>
      <p className={todo.complete ? 'complete' : ''}>Observership Terms & Conditions - {todo.courseTnC}</p>
      <p className={todo.complete ? 'complete' : ''}>Observership Refund Policy - {todo.courseRefundPolicy}</p>

      <p className={todo.complete ? 'complete' : ''}>Department - {todo.department}</p>
      <p className={todo.complete ? 'complete' : ''}>Eligible - {todo.eligible}</p>
      

      </Card.Body>
      
      
    </Card>
      
      
    </div>
  );
}