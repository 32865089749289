import React, {Component} from "react";
import "./Header.css";
import { Navbar, NavDropdown, Form, FormControl, Button, Nav } from 'react-bootstrap'
import {BrowserRouter as Router, Route, Switch, Redirect, Link} from 'react-router-dom';
import Home from "./Home";
import Login from "./Login"; 
import Hospital from "./hospital/Hospital";
import DoctorRegistration from "./doctor/doctorreg";
import Doctor from "./doctor/Doctor.js"

class HeaderDH extends Component {
 
  state = {
    clicked:false
  }
  handleClick = () => {
    this.setState({clicked: !this.state.clicked})
  }

  render(){
  return (
    <Router>
    <div>

        <Navbar bg="light" variant={"light"} expand="lg">
        <img className="show__logo" src="/logo.png" />
            <Navbar.Brand href="#">Dr Planete</Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="mr-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    {/*<Nav.Link as={Link} to="/home">Home</Nav.Link>
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
  <Nav.Link as={Link} to="/contact">Contact</Nav.Link>*/}
                    <Nav.Link as={Link} to="/school">School/Corporate</Nav.Link>
                    <Nav.Link as={Link} to="/corporate">Medical Student</Nav.Link>
                    <Nav.Link as={Link} to="/doctor">Doctor</Nav.Link>
                    <Nav.Link as={Link} to="/hospital">Hospital</Nav.Link>
                    <Nav.Link as={Link} to="/login">Login</Nav.Link>

                </Nav>

            </Navbar.Collapse>
        </Navbar>
    </div>
    <div>
        <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/doctor">
                <Doctor />
            </Route>
            <Route path="/hospital">
                <Hospital />
            </Route>
        </Switch>
    </div>
</Router>
    /*<nav class="navbar navbar-expand-lg navbar-light bg-light">
      <img className="show__logo" src="/logo.png" />
      <p class="text-center">Dr Planete</p>
      
      <center>
      <div class="nav justify-content">
        <ul class="nav justify-content">
        
        <form class="form-inline my-2 my-lg-0">
      <li><input class="form-control"  type="search" placeholder="Search" aria-label="Search"/></li>
     <li><button class="btn btn-outline-success" type="submit">Search</button></li> 
    </form>

        <li class="nav-item nav justify-content-end">
            <a class="nav-link active" href="/safeplan">
              SafePlan
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" href="/doctor">
              Doctors
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/hospital">
              Hospitals
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">
              About us
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="login">
              Login
            </a>
          </li>
        </ul>
      </div>
      </center>
  </nav>*/
  );
  }
}

export default HeaderDH;
