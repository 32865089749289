import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function AboutMRCS({ }) {

    return (
        <div>
            <Helmet>
                <title>All about MRCS | Dr Planete </title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>What is MRCS?</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>The Smart Way</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", height: "700px", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div>
                            <p>MRCS stands for "Membership of the Royal Colleges of Surgeons". It is a postgraduate medical examination for surgical trainees in the United Kingdom and Ireland. The examination is divided into two parts, the MRCS(UK) Part A and the MRCS(UK) Part B.</p>
                        </div>

                        <div><p>MRCS(UK) Part A is a computer-based examination that tests the candidate's knowledge of the basic sciences related to surgery and the ability to apply this knowledge to the practice of surgery. It is typically taken after the completion of the foundation years of training and before the start of specialty training.</p>
                        </div>

                        <div><p>MRCS(UK) Part B is a clinical examination that tests the candidate's ability to apply their knowledge and skills to the practice of surgery. It is typically taken during specialty training in surgery.
                        </p></div>

                        <div><p>Passing the MRCS examination is a requirement for becoming a Member of the Royal Colleges of Surgeons in the UK and Ireland and is also recognized internationally.
                            The MRCS(UK) examination is organized by the Royal College of Surgeons of England, the Royal College of Surgeons of Edinburgh and the Royal College of Surgeons in Ireland.</p></div>
                        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>


                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

            <div><FooterNext /></div>
        </div>
    )
}