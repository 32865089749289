import React from 'react';
import firebase from "firebase";
import { cheq, storage, db } from '../../firebaseConfig';
import "../../index.css";
import { Alert } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';

export default function Memeboard({ todo }) {

    return (
        <div class="inline">
                <img className="w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 shadow-2xl"
                     src={todo.slideshow_image}
                style={{ height: "400px", width: "400px" }}
                />
        </div>
    );
}