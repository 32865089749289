// MainContent.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'bootstrap';
// Import your images here
import img1 from './images/i4.png';
import FooterNext from '../Footers/FooterNext';
import newlogo from './images/logonew.png';
import appStoreLogo from './images/appstore.svg';
//import img3 from './images/img3.jpg';
//import img4 from './images/img4.jpg';
//import img5 from './images/img5.jpg';

const ClubContent = () => {
  return (

    <div>
      <Container className="my-5">
        {/* Image Sections with Paragraphs */}

        <Row className="mb-4">

          
          <Col md={6}>
            <img
              className="d-block w-100 mb-3"
              src={img1}
              alt="First section"
              style={{ width: '80%', height: 'auto' }} // Adjust the size if needed
            />
          </Col>
          <Col md={6} className="d-flex flex-column justify-content-center">
            <img
              className="d-block w-100 mb-3"
              src={newlogo}
              alt="First section"
              style={{ width: '40%', border: '2px solid #fff', height: 'auto' }}
            />

            <div>

            <Container>
            
      <Row className="justify-content-center">
        <Col xs="auto">
        {/*style="display: inline-block; overflow: hidden; border-radius: 13px; width: 250px; height: 83px;" */}
        <a href="https://apps.apple.com/in/app/dr-planete/id6473446013">
        <img 
        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83" 
        alt="Download on the App Store" 
        style={{ width: "200px", border: '2px solid #ffffff',height: "80px"}}
        />
        </a>
        </Col>
        <Col xs="auto">
          <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
            style={{width: "220px", border: '2px solid #ffffff',height: "97px", marginTop:"-9px"}}
              className="google_play"
              alt="Get it on Google Play"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
        </Col>
      </Row>
    </Container>
            
              
            </div>

            <div>
            </div>
          </Col>
        </Row>


        {/*<Row className="mb-4">
        <Col>
          <h2 className="text-center mb-4">Why Join Dr Planete?</h2>
          <Row>
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Career Opportunities</Card.Title>
                  <Card.Text>
                    Access exclusive career opportunities and job listings
                    tailored for doctors and medical professionals.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Training Courses</Card.Title>
                  <Card.Text>
                    Enhance your skills with specialized training courses and
                    certifications offered by industry experts.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Medico-Legal Advisory</Card.Title>
                  <Card.Text>
                    Get advice on medico-legal practices, NABH compliance, and
                    manage high-risk cases effectively.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h2 className="text-center mb-4">Upcoming Events & Conferences</h2>
          <Row>
            <Col md={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Annual Medical Conference 2024</Card.Title>
                  <Card.Text>
                    Join us for the Annual Medical Conference to explore the
                    latest advancements in medical science.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Title>Workshop on Surgical Techniques</Card.Title>
                  <Card.Text>
                    Attend our workshop to learn about the latest surgical
                    techniques and best practices.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <h2 className="text-center mb-4">What Our Members Say</h2>
          <Row>
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Text>
                    "Dr Planete has transformed my career by providing access to
                    valuable resources and opportunities."
                  </Card.Text>
                  <footer className="blockquote-footer">Dr. A. Kumar</footer>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Text>
                    "The networking opportunities and training courses are
                    unparalleled. Highly recommend joining!"
                  </Card.Text>
                  <footer className="blockquote-footer">Dr. S. Mehta</footer>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4}>
              <Card className="mb-4">
                <Card.Body>
                  <Card.Text>
                    "As a medical student, the resources available on Dr Planete
                    have been invaluable for my education."
                  </Card.Text>
                  <footer className="blockquote-footer">R. Singh</footer>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>*/}

      </Container>
      <FooterNext />
    </div>
  );
};

export default ClubContent;
