import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
background: #15171c;
height: 1px;
display: flex;
justify-content: flex-start;
align-items: center;
`;

const NavIcon = styled(Link)`
margin-left: 2rem;
font-size: 2rem;
height: 80px;
display: flex;
justify-content: flex-start;
align-items: center;
`;

const SidebarNav = styled.nav`
background: #f4f2f2;
width: 300px;
height: 100%;
display: flex;
justify-content: center;
top: 0;
left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
transition: 350ms;
z-index: 1;
`;

const SidebarWrap = styled.div`
width: 150%;
`;

const SidebarThis = () => {
const [sidebar, setSidebar] = useState(true);

const showSidebar = () => setSidebar(!sidebar);

return (
	<>
	<IconContext.Provider value={{ color: "#fff" }}>
		<Nav>
		<NavIcon to="#">
			{/*<FaIcons.FaBars onClick={showSidebar} />*/}
		</NavIcon>
		
		</Nav>
		<SidebarNav sidebar={sidebar}>
		<SidebarWrap>
			{/*<NavIcon to="#">
			<AiIcons.AiOutlineClose onClick={showSidebar} />
</NavIcon>*/}
			{SidebarData.map((item, index) => {
			return <SubMenu item={item} key={index} />;
			})}
		</SidebarWrap>
		</SidebarNav>
	</IconContext.Provider>
	</>
);
};

export default SidebarThis;

