import React, { useState, useContext } from "react";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import { Button } from "react-bootstrap";
export default function ShowColumn({
  slideshow_image,
  onClickfunction,
}) {
  const [user, setUser] = useState("");
  const [functionName, setFunctionName] = useState("");
 
  return (
    <div>
      <Link to={onClickfunction}>
        <img
          style={{ height: "400px", width: "600px" }}
          class="w-full aspect-square"
          src={slideshow_image}
        />
      </Link>
    </div>
  );
}
