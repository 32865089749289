import React , {useState, useEffect} from 'react';
import firebase from "firebase";
import { cheq , storage, db} from '../../firebaseConfig';
import "../../index.css";

import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';


export default function SingleCourse({ todo }) {
    
  const deleteTodo = () => {
    const todoRef = firebase.database().ref(`Hospital/trainingcentre/${cheq.currentUser.phoneNumber}/trainingcourses`).child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('Todo').child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };
  return (
    <div class="container">
      <Card style={{marginTop: "5px", marginLeft:"35px", marginRight:"35px", marginBottom:"5px"}}>
      
      <Card.Title style={{marginTop: "15px", marginLeft:"15px", marginBottom:"4px"}}>Course Title: {todo.courseTitle}</Card.Title>
      <Card.Subtitle style={{marginTop: "1px", marginLeft:"15px", marginBottom:"1px"}}>Course Duration: {todo.courseDuration}</Card.Subtitle>
      <Card.Body>

        <div>
        <p className={todo.complete ? 'complete' : ''}>Starting Date - {todo.courseStartingDate}</p>
      <p className={todo.complete ? 'complete' : ''}>Course Fee - {todo.courseFees}</p>
      <p className={todo.complete ? 'complete' : ''}>Course Certificate - {todo.courseCertificate}</p>
      <p className={todo.complete ? 'complete' : ''}>Course Terms & Conditions - {todo.courseTnC}</p>
      <p className={todo.complete ? 'complete' : ''}>Course Refund Policy - {todo.courseRefundPolicy}</p>

        </div>
     
      <p className={todo.complete ? 'complete' : ''}>Eligible - {todo.eligible}</p>
      <p className={todo.complete ? 'complete' : ''}>Mode of Teaching - {todo.mode}</p>
      

      </Card.Body>
      
      {/*<div class="columns-2" style={{marginLeft:"90%", marginBottom: "10px"}}>
        <div><Button variant="outline-primary"onClick={deleteTodo}>Delete</Button></div>
        {/*<div><Button variant="success" onClick={completeTodo}>Complete</Button></div>*/}
        
        
    </Card>
      
      
    </div>
  );
}