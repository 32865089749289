import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function AboutUSMLE({ }) {

    return (
        <div>
            <Helmet>
                <title>All about USMLE | Dr Planete</title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>All you need to know about USMLE</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>Go for it</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", height: "700px", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div>
                            <p>The United States Medical Licensing Examination (USMLE) is a multi-part professional examination for physicians in the United States. It is sponsored by the Federation of State Medical Boards (FSMB) and the National Board of Medical Examiners (NBME). The examination is designed to assess a physician's ability to apply medical knowledge, concepts, and principles, and to demonstrate fundamental patient- centered skills, that are important in health and disease and that constitute the basis of safe and effective patient care.
                            </p>
                        </div>

                        <div>

                            <div><p>
                                The USMLE is divided into three steps</p></div>

                            <div><p> <b>Step 1:</b> This exam is a computer-based test that assesses the examinee's understanding and application of the basic sciences to the practice of medicine. This step is typically taken after the completion of the second year of medical school.
                            </p></div>

                            <div><p><b>Step 2 - Clinical Knowledge (CK):</b> This exam is a computer-based test that assesses the examinee's knowledge and understanding of the clinical sciences, and the ability to apply this knowledge to the practice of medicine. This step is typically taken during the fourth year of medical school.
                            </p></div>

                            <div><p> <b>Step 3:</b> This exam is a computer-based test that assesses the examinee's understanding and application of the basic sciences to the practice of medicine. This step is typically taken after the completion of the first year of residency.
                                Passing the USMLE is a requirement for obtaining a medical license in the United States and is also a requirement for many residency programs.
                            </p></div>
                        </div>



                        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>


                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

            <div><FooterNext /></div>
        </div>
    )
}