// Import the functions you need from the SDKs you need
import firebase from 'firebase';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyB1n98ckCKn-ReFNpyP1by9emioHz_ggLI",
  authDomain: "dr-planete-web.firebaseapp.com",
  databaseURL: "https://dr-planete-web-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "dr-planete-web",
  storageBucket: "dr-planete-web.appspot.com",
  messagingSenderId: "210722892763",
  appId: "1:210722892763:web:d0077504d050d633a7d5fe",
  measurementId: "G-CYWJDQXMR8"
};

const app = initializeApp(firebaseConfig);
var cheq = firebase.auth();
var storage = firebase.storage();
var db = firebase.firestore();
var database = firebase.database();

export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');

export {cheq , firebase, storage, db, database};
