import React, { useState, useEffect } from "react";
import "./viewCourse.css";
import { cheq, db, database } from "../firebaseConfig";
import firebase from "firebase";

import { useAuthState } from 'react-firebase-hooks/auth';
import Todo from "../components/base/Todo";
import Next from "../components/showBranchImages";
import SingleCourse from "./components/singleCourse";
import Login from "../Login";
import SingleObservership from "./components/singleObservership";
import FooterNext from "../Footers/FooterNext";

export default function ViewObservershipPlans({ }) {

  const [user] = useAuthState(cheq);
  const [todoList, setTodoList] = useState();
  const [nextList, setNextList] = useState();


  var phone = "hello";
  if (user != null) {
    phone = cheq.currentUser.phoneNumber;
  }


  useEffect(() => {
    const todoRef = firebase
      .database()
      .ref(`Hospital/trainingcentre/${phone}/observershipcourses`);
    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setTodoList(todoList);
    });

  }, []);

  return (

    
      <div>
        
        <center>
        <h5><b>Hi, View your listed Observership Courses here</b></h5>
        </center>

        <div>
          {todoList
            ? todoList.map((todo, index) => <SingleObservership todo={todo} key={index} />)
            : <div><center><p>No Observership Plans Listed by You</p></center></div>}
        </div>

        
      </div>
  );
}