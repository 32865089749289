import React from "react";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  Office,
} from "./FooterStyles";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import Font, { Text } from "react-font";
import CompanyName from "./companyName";
import SocialHandles from "./socialHandles";

export default function FooterNew() {
  return (
    <Box style={{ position: "relative", height: "auto", width:"auto"}}>
      <Container style={{ marginTop: "75vh" , width:"auto"}}>
        <Row style={{ marginBottom: "75vh" , width:"auto"}}>
          <Column style={{width:"auto"}}>
            <Heading>About us</Heading>
            <FooterLink > Dr Planete is a startup by Bits Pilani and MGM Medical College-Indore Alumni, providing a platform connecting doctors to organizations. Clinics/Hospitals can post request for on-call specialist doctor on Dr Planete, while educational & corporate institutions can post request for visiting doctor & health checkup plan on Dr Planete platform.</FooterLink>
            
            <Office style={{marginTop: "35px", width:"auto"}}>Registered Office Address</Office>
            <Office style={{width:"auto"}}>Sungowin Technologies Pvt. Ltd.</Office>
            <Office style={{marginTop:"-5px", width:"auto"}}>54-55 Arun Vihar Jaipur PIN: 302012</Office>
            <Office style={{width:"auto"}}>CIN: U72900RJ2022PTC079589</Office>
            <Office style={{marginTop: "-5px", width:"auto"}}>Telephone: +918619460759</Office>
          </Column>
          <Column style={{ marginLeft: "45%", width:"auto" }}>
            <Heading>Company</Heading>
            <FooterLink target="_blank" style={{width:"auto"}} href="team">Team</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="careers">Careers</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="contact">Contact</FooterLink>
          </Column>
          <Column style={{ marginLeft: "45%", width:"auto"}}>
            <Heading>Schools/Corporate</Heading>
            <FooterLink target="_blank" style={{width:"auto"}} href="safeplan">Request Visiting Doctor</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="emphealth-checkup-plans">Employee Health Checkup Plans</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="schealth-checkup-plans">Student Health Checkup Plans</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="vaccination-plans">Vaccination Plans</FooterLink>
          </Column>
          <Column style={{ marginLeft: "45%", width:"auto" }}>
            <Heading style={{width:"auto"}}>Medical Students</Heading>
            <FooterLink target="_blank" style={{width:"auto"}} href="#">Observership Programs</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="campus-ambassador-program">Campus Ambassador</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="affiliate-connect">Affiliate Connect</FooterLink>
          </Column>
          <Column style={{ marginLeft: "45%" , width:"auto"}}>
            <Heading style={{width:"auto"}}>Doctors</Heading>
            <FooterLink target="_blank" style={{width:"auto"}} href="doctorreg">Doctor Registration</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="#">Doctors on Board</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="#">Training Courses</FooterLink>
          </Column>
          <Column style={{ marginLeft: "25%" , width:"auto"}}>
            <Heading style={{width:"205px"}}>Hospitals</Heading>
            <FooterLink target="_blank" style={{width:"auto"}} href="#">Hospital Registration</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="training-centre-enrollment">Training Centre Registration</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="training-centre-enrollment">Observership Centre Registration</FooterLink>
         
          </Column>
          <Column style={{ marginLeft: "25%", backgound:"black" , width:"auto" }}>
            <Heading>Help</Heading>
            <FooterLink target="_blank" style={{width:"auto"}} href="#">Frequently Asked Questions</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="termsandconditions">Terms & Conditions</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="gro-plus-termsandconditions">Terms & Conditions: Gro+</FooterLink>
            <FooterLink target="_blank" style={{width:"auto"}} href="privacypolicy">Privacy Policy</FooterLink>
             </Column>
        </Row>
        <div style={{ marginLeft: "345px" , width:"auto"}}>
          <Row style={{ height: "40px" }}>
            <FooterLink target="_blank" href="https://www.facebook.com/DrPlanete">
              <i className="fab fa-facebook-f"></i>
            </FooterLink>
            <FooterLink target="_blank" href="https://www.instagram.com/drplanete/">
              <i className="fab fa-instagram"></i>
            </FooterLink>
            <FooterLink target="_blank" href="https://twitter.com/planete_dr">
              <i className="fab fa-twitter"></i>
            </FooterLink>
            <FooterLink target="_blank" href="https://www.youtube.com/@drplanete4495">
              <i className="fab fa-youtube"></i>
            </FooterLink>
            <FooterLink target="_blank" href="https://www.linkedin.com/company/dr-planete/">
              <i className="fab fa-linkedin"></i>
            </FooterLink>
          </Row>
        </div>
      </Container>
      <CompanyName />
    </Box>
  );
}

