import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function AboutMRCP({ }) {

    return (
        <div>
            <Helmet>
                <title>All about MRCP | Dr Planete</title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>What is MRCP?</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>The Smart Way</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", height: "700px", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div>
                            <p>MRCP stands for "Membership of the Royal Colleges of Physicians". It is a postgraduate medical examination for physicians in the United Kingdom and Ireland. The examination is divided into two parts, MRCP(UK) Part 1 and MRCP(UK) Part 2.</p>
                        </div>

                        <div><p>2. MRCP(UK) Part 1 is a computer-based examination that tests the candidate's knowledge of the basic sciences related to medicine and the ability to apply this knowledge to the practice of medicine. It is typically taken after the completion of the foundation years of training and before the start of specialty training.</p>
                        </div>

                        <div><p>MRCP(UK) Part 2 is a clinical examination that tests the candidate's ability to apply their knowledge and skills to the practice of internal medicine. It is typically taken during specialty training in internal medicine.
                            </p></div>

                            <div><p>Passing the MRCP examination is a requirement for becoming a member of the Royal Colleges of Physicians in the UK and Ireland and is also recognized internationally.
                            The MRCP(UK) examination is organized by the Royal College of Physicians of London, the Royal College of Physicians of Edinburgh and the Royal College of Physicians and Surgeons of Glasgow.</p></div>
                        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>


                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

            <div style={{ marginTop: "35px" }}><GetApp /></div>
            <div><FooterNext /></div>
        </div>
    )
}