import React, { useState, useEffect } from 'react';
import firebase from "firebase";
import { cheq, storage, db } from '../firebaseConfig';
import "../index.css";

import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';


export default function IndPrice({ todo }) {




    function buyGrowPlus(){


        //alert("Todo" - todo.name);

        if(todo.name === "Standard"){
            window.location.replace('https://rzp.io/l/drplanete-grop-standard');
        }if(todo.name === "Premium"){
            window.location.replace('https://rzp.io/l/drplanete-grop-premium');
        }if(todo.name === "Suite"){
            window.location.replace('https://rzp.io/l/drplanete-grop-suite');
        }
    }
    return (
        <div class="container">
            <Card>
                
                {/*<Card.Title style={{ marginTop: "15px", marginLeft: "15px", marginBottom: "4px" }}>Course Title: {todo.name}</Card.Title>
                <Card.Subtitle style={{ marginTop: "1px", marginLeft: "15px", marginBottom: "1px" }}>Course Duration: {todo.color}</Card.Subtitle>*/}
                <img src={todo.image}></img>
                
                <div>
                    <center>
                        <p>Plan Name - {todo.name}</p>
                    </center>
                </div>

               

                <div>
                    <center>
                        <p><b>Price - {`\u20B9`}{Math.floor(todo.charges/12)} </b> per month</p>
                        <p>{`\u20B9`}{todo.charges} per year</p>
                        <p>Billed Yearly</p>
                    </center>
                </div>

                <div>
                    <center>
                        <p> <b>Free</b> Upto <b>{todo.limits} users</b> per month</p>
                    </center>
                </div>

                <div>
                    <center>
                        <p>Post Free Tier Usage -  {`\u20B9`}{todo.cpu} per user per month</p>
                    </center>
                </div>

                <div>
                    <center>
                    <Button variant="primary" size="lg" style={{width:"85%", marginBottom:"15px"}} onClick={buyGrowPlus}>Buy Now</Button>
                    </center>
                    
                </div>
                {/*<Card.Body>

                    <p></p>
                    <p>{todo.features}</p>
                    <p className={todo.complete ? 'complete' : ''}>Tags - {todo.tag}</p>
                    <p className={todo.complete ? 'complete' : ''}>Charges - {todo.charges}</p>

                </Card.Body>
            </div>*/}
            </Card>


        </div>
    );
}