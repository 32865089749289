import React from "react";
import { firebase, cheq, db, database } from "../../firebaseConfig";
import { useState } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    Link,
} from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';
import { Button } from "react-bootstrap";

import { DayPicker } from 'react-day-picker';
import FooterNew from "../../Footers/Footer";
import GetApp from "../../Footers/getApp";


import { render } from "@testing-library/react";
import Login from "../../Login";
import MedicalStudent from "../../medicalstudent/MedicalStudent";
import { Column } from "../../Footers/FooterStyles";

import DashboardDoctor from "../../doctor/dashboardDoctor";
import ReactDOM from 'react-dom';
import HospitalRegistration from "../HospitalRegistration";
import Observership from "./observership";
 
// Message Component


function checkifHospitalIsRegistered(childName) {
    return new Promise((resolve, reject) => {
      const ref = database.ref(childName);
      ref.once("value", (snapshot) => {
        const exists = snapshot.exists();
        resolve(exists);
      }, (error) => {
        reject(error);
      });
    });
}

export default function CheckIfHospitalObs({  }) {

    const [user] = useAuthState(cheq);
    let [regis, setRegis] = useState("false");

    var phone = "hello";
    if (user != null) {
        phone = cheq.currentUser.email;
    }

    

    
      
      // Usage example:
      checkifHospitalIsRegistered(`Hospital/${phone}`)
        .then((exists) => {
          if (exists) {
            setRegis("true");
            console.log("Child exists in the database.");
          } else {
            console.log("Child does not exist in the database.");
          }
        })
        .catch((error) => {
          console.error(error);
        });

    return (

      user ? 
        <div>
        {
            (regis=="true") &&
            <Observership/>
        }{
            (regis=="false") &&
            <div><HospitalRegistration/></div>
        }
       </div> : <Login/>
        

    );
    //alert("value - " + isRegistered);


}

ReactDOM.render(
    <CheckIfHospitalObs />,
    document.getElementById('root')
);

