import React, { useState, useContext, useEffect } from "react";
import "./slider.css";
import { storage, db } from "../firebaseConfig";
import firebase from "firebase";
import { UserContext } from "../user";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Carousel from "react-multi-carousel";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "react-multi-carousel/lib/styles.css";
import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";
import { Slider } from "rsuite";
import { Alert } from "react-bootstrap";
import Memeboard from "./displayMemes/memeboard";
import Todo from "./base/Todo";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function ShowMemes({ }) {
  const [user, setUser] = useState("");
  const [todoList, setTodoList] = useState();
  
  useEffect(() => {
    const todoRef = firebase
      .database()
      .ref("page/memes/collection1");
    todoRef.on("value", (snapshot) => {
      const todos = snapshot.val();
      const todoList = [];
      for (let id in todos) {
        todoList.push({ id, ...todos[id] });
      }
      setTodoList(todoList);
    });

  }, []);

  const slideLeft = () => {
    var slider = document.getElementById("memes-slider");
    slider.scrollLeft = slider.scrollLeft - 500;
  };

  const slideRight = () => {
    var slider = document.getElementById("memes-slider");
    slider.scrollLeft = slider.scrollLeft + 500;
  };

  return (
    <>
      <div class="container">

        <div>
        <div class="container" style={{ marginTop: "35px", marginBottom: "25px" }}>
        <Alert variant="light">
          <center>
            <div><h4 class="font-mono text-blue-600"><b>Try Not to Laugh!!</b></h4></div>
        </center>


        </Alert>
      </div>
        </div>
        <div className="relative flex items-center">
          <MdChevronLeft
            className="opacity-50 cursor-pointer hover:opacity-100"
            onClick={slideLeft}
            size={40}
          />
          <div
            id="memes-slider"
            className="w-full h-full overflow-x-auto scroll whitespace-nowrap scroll-smooth scrollbar-hide"
          >
            {todoList
        ? todoList.map((todo, index) => <Memeboard class="inline" todo={todo} key={index} />)
        : ''}
          </div>
          <MdChevronRight
            className="opacity-50 cursor-pointer hover:opacity-100"
            onClick={slideRight}
            size={40}
          />
        </div>
      </div>
    </>
  );
}

