import React, { Component, useState } from 'react';
import ReactPlayer from 'react-player';

export default function FeatureVideo(videoID) {

    const [videoURL, setVideoURL] = useState("https://firebasestorage.googleapis.com/v0/b/video-resume-bf3c1.appspot.com/o/doctors%2Fvr-1.mp4?alt=media&token=cb6dca76-6556-430a-8c55-dcdefec090d2");
     return (
        <div style={{height:"auto"}}>
            <video controls width="85%" autoPlay={"autoplay"} preload='true' loop>
                <source src={videoURL} type="video/mp4" />
     </video>

        </div>
    );
};

