import React, { useState } from "react";
import "./Home.css"
import { db } from "./firebaseConfig";
import Product from "./Product";
import Footerhome from "./Footerhome.js";
import HorizontalScroll from "react-horizontal-scrolling";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import ShowImages_school_corporate from "./school_corporate/showimages_school_corporate";
import ShowProducts from "./components/showProducts";
import HeaderGen from "./HeaderGen.js";
import FooterNew from "./Footers/Footer.js";
import GetApp from "./Footers/getApp";
import ShowImagesHomepage from "./school_corporate/showImagesHomepage";
import ShowExperts from "./components/showExperts";
import FooterNext from "./Footers/FooterNext";
import DisplayFeature from "./feature/displayFeature";

function Home() {

  const [startDate, setStartDate] = useState(new Date().toDateString());
  
  const [postsX, setPostsX] = useState([]);
  db.collection("homepage").onSnapshot((snapshot) => {
    setPostsX(snapshot.docs.map((doc) => ({ id: doc.id, postsX: doc.data() })));
  });



  return (
    <div style={{display:"flex-column", marginTop:"auto"}}>
      <div class="place-content-center" style={{ height: "650px", width: "auto", display: "block" }}>
        <ShowProducts path="slideshow/home/get" />
      </div>
      <div>

        <div class="container" style={{ width: "auto", marginBottom: "35px" }}>
          {postsX.map(({ id, postsX }) => {
            return (
              <div class="border border-indigo-600" style={{ width: "auto", height: "auto", marginTop: "15px", marginBottom: "15px" }}>
                <ShowImagesHomepage
                  
                  key={id}
                  slideshow_image={postsX.slideshow_image}
                  onClickfunction={postsX.onClickfunction}
                />
              </div>
            );
          })}
        </div>

       
      </div>

      
      <div style={{ marginTop: "10px" }}>

      </div>
      <FooterNext />
    </div>
  );
}

export default Home;
