import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function RajasthanMedicalCouncilDetails() {
  return (
    <Container className="my-5">
      <Helmet>
        <title>Rajasthan Medical Council (RMC) Details</title>
        <meta name="description" content="Complete details about the Rajasthan Medical Council (RMC). Learn about its functions, responsibilities, and contact information." />
        <meta name="keywords" content="Rajasthan Medical Council, RMC, medical registration, medical education, doctor registration, contact details" />
      </Helmet>

      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title as="h1" className="text-center mb-4">Rajasthan Medical Council (RMC)</Card.Title>
              <Card.Text>The Rajasthan Medical Council (RMC) is a statutory body established under the Rajasthan Medical Council Act. It functions under the jurisdiction of the state government of Rajasthan, India. The primary purpose of the RMC is to regulate the medical profession and ensure the standards of medical education and practice within the state.</Card.Text>

              <Card.Subtitle as="h2" className="mt-4">Key Functions and Responsibilities:</Card.Subtitle>
              <ListGroup variant="flush" className="mb-4">
                <ListGroup.Item>1. Registration of Medical Practitioners: Maintains a registry of qualified medical practitioners in Rajasthan and issues registration certificates to eligible doctors.</ListGroup.Item>
                <ListGroup.Item>2. Regulation of Medical Practice: Monitors professional conduct and enforces ethical standards in medical practice, taking disciplinary action as necessary.</ListGroup.Item>
                <ListGroup.Item>3. Medical Education: Recognizes and approves medical colleges and institutions, ensuring they meet required standards.</ListGroup.Item>
                <ListGroup.Item>4. Continuing Medical Education (CME): Promotes ongoing education and training for medical practitioners through workshops, seminars, and programs.</ListGroup.Item>
                <ListGroup.Item>5. Renewal of Registration: Manages the periodic renewal process for registered practitioners, ensuring compliance with continuing education requirements.</ListGroup.Item>
                <ListGroup.Item>6. Issuance of Good Standing Certificates: Provides certificates of good standing for various purposes such as job applications and further studies.</ListGroup.Item>
                <ListGroup.Item>7. Public Awareness and Health Initiatives: Engages in public health campaigns and collaborates with other organizations on health initiatives.</ListGroup.Item>
                <ListGroup.Item>8. Maintenance of Medical Standards: Ensures adherence to prescribed medical standards, updating guidelines and protocols as needed.</ListGroup.Item>
                <ListGroup.Item>9. Handling Grievances and Complaints: Addresses patient complaints, investigates issues, and maintains trust in the medical profession.</ListGroup.Item>
                <ListGroup.Item>10. Policy Making and Advisory Role: Advises the state government on medical education and practice, participating in healthcare policy making.</ListGroup.Item>
              </ListGroup>

              <Card.Subtitle as="h2" className="mt-4">Contact and Additional Information:</Card.Subtitle>
              <Card.Text>
                <strong>Address:</strong> Sardar Patel Marg, C-Scheme, Jaipur-302001, Rajasthan, India (around 3 km from Jaipur Railway Station and Bus Stand)
              </Card.Text>
              <Card.Text>
                <strong>Telephone Numbers:</strong>
                <ul>
                  <li>0141-2225102</li>
                  <li>0141-2221734 (Fax)</li>
                  <li>+91 9079844641 (Mobile)</li>
                </ul>
              </Card.Text>
              <Card.Text>
                <strong>Working Days:</strong> Monday to Friday
              </Card.Text>
              <Card.Text>
                <strong>Office Hours:</strong> 9:30 AM to 6:00 PM
              </Card.Text>
              <Card.Text>
                <strong>Transaction Hours:</strong> 10:30 AM to 3:00 PM (for registrations etc.)
              </Card.Text>
              <Card.Text>
                <strong>Website:</strong> <Card.Link href="https://www.rmcjaipur.org/">Rajasthan Medical Council</Card.Link>
              </Card.Text>
              <Card.Footer className="text-muted">
                Disclaimer: The information provided here is for general informational purposes only and should not be considered as legal or professional advice. For accurate and updated information, please visit the official Rajasthan Medical Council website or contact them directly.
              </Card.Footer>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RajasthanMedicalCouncilDetails;
