import React, { useState, useContext } from "react";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Carousel from "react-multi-carousel";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "react-multi-carousel/lib/styles.css";
import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";
import { Slider } from "rsuite";
import IndPrice from "./indPrice";
import { Card } from "react-bootstrap";


export default function GrowPlusPricing({}) {
  const [user, setUser] = useState("");
  const [functionName, setFunctionName] = useState("");

  const [post, setPosts] = useState([]);
  db.collection("growplans").onSnapshot((snapshot) => {
    setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
  });

  return (
    <div>
        <div className="columns-3">
        <div>
          {post.map(({ id, post }) => {
            return (
              <div>
                <IndPrice  todo={post} key={id}/>
              </div>
            );
          })}
        </div>
        </div>
    </div>
  );
}

{/*<Card style={{  background: `${post.color}` }}>

<Card.Title style={{ marginTop: "15px", marginLeft: "15px", marginBottom: "4px" }}>Course Title: {post.name}</Card.Title>
<Card.Subtitle style={{ marginTop: "1px", marginLeft: "15px", marginBottom: "1px" }}>Course Duration: {post.color}</Card.Subtitle>
<Card.Body>

    <p>Tags - {post.tag}</p>
    <p>Charges - {post.charges}</p>

</Card.Body>
</Card>*/}
