import React from 'react';
import firebase from "firebase";
import { cheq , storage, db} from '../../firebaseConfig';
import "../../index.css";

export default function Todo({ todo }) {
    
  const deleteTodo = () => {
    const todoRef = firebase.database().ref('Todo').child(todo.id);
    todoRef.remove();
  };
  const completeTodo = () => {
    const todoRef = firebase.database().ref('Todo').child(todo.id);
    todoRef.update({
      complete: !todo.complete,
    });
  };
  return (
    <div>
      <p className={todo.complete ? 'complete' : ''}>{todo.slideshow_image}</p>
      {/*<h2>{todo}</h2>*/}
      <button onClick={deleteTodo}>Delete</button>
      <button onClick={completeTodo}>Complete</button>
    </div>
  );
}