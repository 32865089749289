import React, { Component } from "react";
import "./Header.css";
import {
  Navbar,
  NavDropdown,
  Dropdown,
  Form,
  FormControl,
  Button,
  Nav,
  NavLink
} from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";

import { useStateValue } from "./StateProvider";
import { cheq } from "./firebaseConfig";
import { MenuItems } from "./MenuItems";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";
import Doctor from "./doctor/Doctor.js";
import Home from "./Home";
import Login from "./Login";
import Hospital from "./hospital/Hospital.js";
import DoctorRegistration from "./doctor/doctorreg";
import MedicalStudent from "./medicalstudent/MedicalStudent";

import DashboardDoctor from "./doctor/dashboardDoctor";
import Safeplan from "./school_corporate/safeplan.js";
import DoctorRegistrationPortal from "./doctor/doctorRegistrationPortal";
import Termsandcondtions from "./termsandconditions/termsandconditions";
import PrivacyPolicy from "./termsandconditions/privacypolicy";

import Story from "./company/Story.js";
import Careers from "./company/Careers.js";
import Contact from "./company/Contact.js";
import Team from "./company/Team.js";
import Todo from "./components/base/Todo";
import MedicalStudentLogin from "./Login/medicalstudent_login";
import DoctorHospitalLogin from "./Login/doctorhospital_login";
import SchoolCorporateLogin from "./Login/schoolcorporate_login";
import Offers from "./medicalstudent/Offers";
import CampusAmbassadorProgram from "./medicalstudent/campusAmbassadorProgram";
import ThirtyDayQuestion from "./medicalstudent/thirtydayquestion";
import ExamAlerts from "./medicalstudent/examalerts";

import Vaccination from "./school_corporate/plans/Vaccination";
import EducationSession from "./school_corporate/plans/EducationSession";
import RequestVisitingDoctor from "./school_corporate/plans/RequestVisitingDoctor";
import DentalCheckup from "./school_corporate/plans/DentalCheckup";
import StudentHealthCheckup from "./school_corporate/plans/StudentHealthCheckup";
import EmployeeHealthCheckup from "./school_corporate/plans/EmployeeHealthCheckup";
import EyeCheckup from "./school_corporate/plans/EyeCheckup";
import AffiliateRegistration from "./medicalstudent/affiliateRegistration";
import TrainingCentre from "./hospital/registration/trainingCentre";
import { Train } from "@material-ui/icons";

import { AboutUs } from "./hospital/Sidebar/pages/AboutUs";
import { OurAim } from "./hospital/Sidebar/pages/AboutUs";
import { OurVision } from "./hospital/Sidebar/pages/AboutUs";
import { Services } from "./hospital/Sidebar/pages/Services";
import { ServicesOne } from "./hospital/Sidebar/pages/Services";
import { ServicesTwo } from "./hospital/Sidebar/pages/Services";
import { ServicesThree } from "./hospital/Sidebar/pages/Services";
import { Events } from "./hospital/Sidebar/pages/Events";
import { EventsOne } from "./hospital/Sidebar/pages/Events";
import { EventsTwo } from "./hospital/Sidebar/pages/Events";
import CourseDetails from "./hospital/registration/courseDetails";
import SidebarThis from "./hospital/Sidebar/components/Sidebar";
import Observership from "./hospital/registration/observership";
import ViewCourses from "./hospital/viewCourses";
import ViewObservershipPlans from "./hospital/viewObservershipPlans";
import IncreasePractice from "./doctor/content/increasePractice";
import AboutMRCP from "./doctor/content/aboutMRCP";
import AboutMRCS from "./doctor/content/aboutMRCS";
import AboutFMGE from "./doctor/content/aboutFMGE";
import AboutNEETPG from "./doctor/content/aboutNEETPG.js";
import AboutUSMLE from "./doctor/content/aboutUSMLE";
import AboutNEETSS from "./doctor/content/aboutNEETSS";
import GrowPlusPricing from "./products/pricing";
import GrowPlus from "./products/growplus";
import GroPlusTnc from "./termsandconditions/groplusterms";
import DoctorRegistrationBasicDetails from "./doctor/registration/basicDetails";
import DoctorRegistrationOtherDetails from "./doctor/registration/superSpecializationDetails";
import DoctorRegistrationSuperSpecialization from "./doctor/registration/superSpecializationDetails";
import DoctorRegistrationSpecialization from "./doctor/registration/specializationDetails";
import SupportForm from "./support/support.js";


class HeaderGen extends Component {
  state = {
    clicked: false,
  };
  handleClick = () => {
    this.setState({ clicked: !this.state.clicked });
  };

  render() {
    return (
      <Router>
        <div className="show_Nav" style={{background:"blue", width:"auto"}}>
          <Navbar bg="light" variant={"light"}>
            <img style={{height:"5vh"}} src="/logo.png" />
            <Navbar.Brand href="/">Dr Planete</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                
                style={{ height:"auto" , width:"auto" }}
                navbarScroll
              >
                <NavLink as={Link} to="/safeplan">
                  School/Corporate
                </NavLink>
                <Nav.Link as={Link} to="/medicalstudent">
                  Medical Student
                </Nav.Link>
                <Nav.Link as={Link} to="/doctor">
                  Doctor
                </Nav.Link>
                <Nav.Link as={Link} to="/hospital">
                  Hospital
                </Nav.Link>
               
               

               
                </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
        
        <div>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/doctor">
              <Doctor />
            </Route>
            <Route path="/medicalstudent">
              <MedicalStudent />
            </Route>
            <Route path="/hospital">
              <Hospital />
            </Route>
            <Route path="/school">
              <Safeplan />
            </Route>
            <Route path="/safeplan">
              <Safeplan />
            </Route>
            <Route path="/corporate">
              <Safeplan />
            </Route>
            <Route path="/doctorreg">
              <DoctorRegistrationBasicDetails />
            </Route>
            <Route path="/doctorreg-specialization">
              <DoctorRegistrationSpecialization />
            </Route>
            <Route path="/doctorreg-super-specialization">
              <DoctorRegistrationSuperSpecialization />
            </Route>
            <Route path="/dashboard">
              <DashboardDoctor />
            </Route>
            <Route path="/doctorregistrationportal">
              <DashboardDoctor />
            </Route>
            <Route path="/termsandconditions">
              <Termsandcondtions />
            </Route>
            <Route path="/gro-plus-termsandconditions">
              <GroPlusTnc />
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicy />
            </Route>
            <Route path="/story">
              <Story />
            </Route>
            <Route path="/team">
              <Team/>
            </Route>
            <Route path="/careers">
              <Careers/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
            <Route path="/todo">
              <Todo/>
            </Route>
            <Route path="/login-school-corporate">
              <SchoolCorporateLogin/>
            </Route>
            <Route path="/login-doctor-hospital">
            <DoctorHospitalLogin/>
            </Route>
            <Route path="/login-medicalstudent">
              <MedicalStudentLogin/>
            </Route>
            <Route path="/offers">
              <Offers/>
            </Route>
            <Route path="/affiliate-connect">
              < AffiliateRegistration/>
            </Route>
            <Route path="/campus-ambassador-program">
              <CampusAmbassadorProgram/>
            </Route>
            <Route path="/30days-30questions-30prizes">
              <ThirtyDayQuestion/>
            </Route>
            <Route path="/exam-alerts">
              < ExamAlerts/>
            </Route>
            <Route path="/vaccination-plans">
              < Vaccination/>
            </Route>
            <Route path="/awareness-session">
              < EducationSession/>
            </Route>
            <Route path="/request-visiting-doctor">
              < RequestVisitingDoctor/>
            </Route>
            <Route path="/dental-checkup-plans">
              < DentalCheckup/>
            </Route>
            <Route path="/student-health-checkups-plans">
              < StudentHealthCheckup/>
            </Route>
            <Route path="/employee-health-checkup-plans">
              < EmployeeHealthCheckup/>
            </Route>
            <Route path="/eye-checkup-plans">
              < EyeCheckup/>
            </Route>
            
            <Route path="/training-centre-enrollment">
              <TrainingCentre/>
            </Route>
            <Route path="/new-training-course">
              <CourseDetails/>
            </Route>
            <Route path="/new-observership-plan">
              <Observership/>
            </Route>
            <Route path="/my-courses">
              <ViewCourses/>
            </Route>
            <Route path="/my-observership-plans">
              <ViewObservershipPlans/>
            </Route>
            <Route path="/increase-practice-doctors">
              <IncreasePractice/>
            </Route>
            <Route path="/about-mrcp">
              <AboutMRCP/>
            </Route>
            <Route path="/about-mrcs">
              <AboutMRCS/>
            </Route>
            <Route path="/about-fmge">
              <AboutFMGE/>
            </Route>
            <Route path="/about-neetpg">
              <AboutNEETPG/>
            </Route>
            <Route path="/about-usmle">
              <AboutUSMLE/>
            </Route>
            <Route path="/about-neetss">
              <AboutNEETSS/>
            </Route>
            <Route path="/gro-plus-plans">
              <GrowPlus/>
            </Route>
            <Route path="/pricing">
              <GrowPlusPricing/>
            </Route>
          </Switch>
        </div>
      </Router>
    );
  }
}

export default HeaderGen;
