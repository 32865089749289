import React, { useState } from "react";
import { firebase, cheq } from "../firebaseConfig";
import { BrowserRouter as Link } from "react-router-dom";
import Header from "../HeaderGen";
import GetApp from "../Footers/getApp";
import FooterNew from "../Footers/Footer";


const SchoolCorporateLogin = () => {
  // Inputs

  return (
    <div className="show_school">
      <h1>School Corporate Login</h1>
      <div
        style={{ display: "flex", height: "80%", justifyContent: "flex-end" }}
      >
        <div className="show_canva" style={{ width: "60%", height: "100%" }}>
          <iframe
            loading="lazy"
            style={{
              position: "absolute",
              width: "100%",
              height: "80%",
              top: "0",
              left: "0",
              border: "none",
              padding: "0",
              margin: "0",
            }}
            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAE2ikAEOgA&#x2F;view?embed"
            allowfullscreen="allowfullscreen"
            allow="fullscreen"
          ></iframe>
        </div>

        <div
          style={{
            display: "flex",
            height: "80%",
            width: "70%",
            justifyContent: "flex-end",
          }}
        >

        </div>
      </div>

      <FooterNew/>
    </div>
  );
}
export default SchoolCorporateLogin;