import { React, Component } from "react";
import "./displayFeature.css"
import FooterNew from "../Footers/Footer.js";
import Footer from "../Footers/Footer.js";
import FeatureVideo from "./featureVideo.js";
import FeaturePics from "./featurePics.js";
import PlayVideo from "../components/videoplayer";


export default class DisplayFeature extends Component {

  render() {
    return (
      <div className="set-together">
          {/*<div><PlayVideo/></div>*/}
          <div><FeatureVideo videoID={`https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/videos%2Fwhen%20first%20year%20medico%20says%20mbbs%20ke%20bad%20paisa%20hi%20paisa%20hoga.mp4?alt=media&token=83e53211-707c-4ba7-a063-8576e849e981`}/></div>
          
          <div><FeaturePics/></div>
      </div>
    )
  }
}

