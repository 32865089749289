import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Card, ListGroup } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';
import FooterNext from '../../../../Footers/FooterNext';

function MadhyaPradeshMedicalCouncilRegistration() {
    return (
        <div>
            <Container className="my-5">
                <Helmet>
                    <title>How to Apply for Medical Council Registration in Madhya Pradesh</title>
                    <meta name="description" content="Step-by-step guide to applying for medical council registration in Madhya Pradesh. Check eligibility, documents required, and renewal process." />
                    <meta name="keywords" content="medical council registration, Madhya Pradesh, MPOnline portal, doctor registration, eligibility, documents, renewal" />
                </Helmet>

                <Row className="justify-content-center">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title as="h1" className="text-center mb-4">How to Apply for Medical Council Registration in Madhya Pradesh</Card.Title>
                                <Card.Text >Register yourself in Madhya Pradesh Medical Council through online.</Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">1. Create User Profile</Card.Subtitle>
                                <Card.Text>Candidates are required to have a valid personal e-mail ID and contact number. If not, create a new e-mail ID before applying online.</Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">2. User Profile Creation</Card.Subtitle>
                                <Card.Text>Applicants must first create a User Profile at <a href="https://mpmc.mponline.gov.in" target="_blank" rel="noopener noreferrer">User Profile Creation</a>. Enter the correct mobile number, which will serve as the user ID.</Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">3. Profile Verification</Card.Subtitle>
                                <Card.Text>The user profile will be created by verifying the applicant's Aadhaar number via OTP/Biometric authentication.</Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">4. Online Application Submission</Card.Subtitle>
                                <Card.Text>Submit the application online through your User Profile. Contact customer care for technical issues.</Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">Important Notes</Card.Subtitle>
                                <Card.Text>
                                    For resolving any technical problem in user profile/application submission/document uploading / online payment etc., contact the customer care number 0755 – 6720200 of the online portals or lock your grievance on MP online grievance section.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="justify-content-center mt-5">
                    <Col md={8}>
                        <Card>
                            <Card.Body>
                                <Card.Title as="h1" className="text-center mb-4">Additional Information</Card.Title>
                                <Card.Subtitle as="h4" className="mt-4">Application Process</Card.Subtitle>
                                <Card.Text>The application will be made online through MP Online portal or through Public Service Centre / Kiosk.</Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">Eligibility Criteria</Card.Subtitle>
                                <Card.Text>
                                    Candidate has to create their user profile/account on Madhya Pradesh Medical Council (mponline.gov.in) for the registration process and the candidate should submit the application through his user profile only.
                                </Card.Text>

                                <Card.Subtitle as="h4" className="mt-4">Required Documents</Card.Subtitle>
                                <Card.Text>
                                    - Internship Completion Certificate<br />
                                    - Signed Declaration, (Appendix ‘A’)<br />
                                    - Notarized Affidavit, (Appendix ‘B’) if applying after 1 year of internship completion<br />
                                    - Good Conduct Certificate from the head of the institution (if applicable)
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            {/* FooterNext component */}
            

            {/* Contact Information */}
            <Container className="mt-5">
                <Typography variant="h5" className="text-center">Contact Information</Typography>
                <Typography variant="body1" className="text-center">
                    Address: F-7, Sanchi Complex Opposite Board Office, Bhopal (M.P.) - 462016 [INDIA]<br />
                    Phone Number: 0755 - 2767786<br />
                    Fax: 0755 - 2551568<br />
                    Email: <a href="mailto:registrarmpmc@gmail.com">registrarmpmc@gmail.com</a> (or) <a href="mailto:registrar.mpmc@mp.gov.in">registrar.mpmc@mp.gov.in</a>
                </Typography>
            </Container>

            {/*<Typography>
            Disclaimer: The information provided here is for general informational purposes only and should not be considered as legal or professional advice. For accurate and updated information, please visit the official Rajasthan Medical Council website or contact them directly.
            
    </Typography>*/}
            <FooterNext/>
        </div>
    );
}

export default MadhyaPradeshMedicalCouncilRegistration;

