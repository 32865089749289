import { KeyboardReturnRounded, WhereToVote } from "@material-ui/icons";
import React from "react";
import { cheq, storage, db, database } from "../../firebaseConfig";
import firebase from "firebase";
import { useEffect, useState } from "react";
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";
import { Button } from "react-bootstrap";
import { MDCTextField } from "@material/textfield";

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Login from "../../Login";
import GetApp from "../../Footers/getApp";
import FooterNew from "../../Footers/Footer";

import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import FooterNext from "../../Footers/FooterNext";

export default function DoctorRegistrationSpecialization() {

    const [user] = useAuthState(cheq);

    const [reqDate, setReqDate] = useState(new Date());
    const [specializationDegree, setSpecializationDegree] = useState("");
    const [specializationDegreeUni, setSpecializationDegreeUni] = useState("");

    const [specializationDegreeCertificate, setSpecializationDegreeCertificate] =
        useState("");
    

    const [
        specializationRegistration,
        setSpecializationRegistration,
    ] = useState("");

    const uploadSpecializationDegreeCertificate = () => {
        if (specializationDegree != "" && specializationDegreeCertificate.name == null) {
            alert("Specialization Degree Certificate Not Selected");
            return;
        }

        var path = cheq.currentUser.phoneNumber;
        storage
            .ref(`doctorregistration/${path}/${specializationDegreeCertificate.name}`)
            .put(specializationDegreeCertificate)
            .on(
                "state_changed",
                alert("Specialization degree certificate Updated"),
                alert
            );
    };

    const uploadSpecializationRegistration = () => {
        if (specializationRegistration != "" && specializationRegistration.name == null) {
            alert("Specialization Registration Certificate Not Selected");
            return;
        }

        var path = cheq.currentUser.phoneNumber;
        storage
            .ref(`doctorregistration/${path}/${specializationDegreeCertificate.name}`)
            .put(specializationDegreeCertificate)
            .on(
                "state_changed",
                alert("Specialization Registration certificate Updated"),
                alert
            );
    };


    

    const submitDetails = (e) => {
        e.preventDefault();

        uploadSpecializationDegreeCertificate();
        uploadSpecializationRegistration();

        database.ref(`Doctor Registration/${cheq.currentUser.phoneNumber}/specialization`).set({

            specializationDegree: specializationDegree,
            specializationDegreeUni: specializationDegreeUni,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

            Phone: cheq.currentUser.phoneNumber,

            PaymentStatus: "Pending",
            todayDate: dayjs(reqDate).format('LL'),
            Time: firebase.firestore.Timestamp.now().toDate().toString(),
      
          }).catch(alert);
          

        window.location.replace('https://rzp.io/l/drplanete-doctors-registration');
    };

    return (

        //user ?
        //<>
        <div>
            <center>
                <h3>Welcome Doctor {/*{cheq.currentUser.phoneNumber}*/}</h3>
            </center>
            
            

            <div>
                
               
            <form style={{ marginTop: "35px", marginLeft: "9px" }}>
                <center>
                    <div class="container">
                        <div>

                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={1}
                                label="Specialization Degree (If Any)" variant="outlined"
                                onChange={(e) => setSpecializationDegree(e.target.value)} />

                        </div>

                        <div
                            style={{
                                marginTop: "35px",
                            }}
                        >
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={1}
                                label="Specialization Degree University" variant="outlined"
                                onChange={(e) => setSpecializationDegreeUni(e.target.value)} />

                        </div>

                        <div
                            style={{
                                marginTop: "35px",
                            }}
                        ></div>
                    </div>
                </center>
            </form>
            <center>
                <div style={{ marginTop: "35px" }}>
                    Upload Specialization Degree Certificate
                </div>

                <input
                    type="file"
                    onChange={(e) => {
                        setSpecializationDegreeCertificate(e.target.files[0]);
                    }}
                ></input>
                <button
                    onClick={uploadSpecializationDegreeCertificate}
                    className="btn btn-success"
                >
                    Upload
                </button>
            </center>

            <center>
                <div style={{ marginTop: "35px" }}>
                    Upload Specialization Degree Certificate
                </div>

                <input
                    type="file"
                    onChange={(e) => {
                        setSpecializationRegistration(e.target.files[0]);
                    }}
                ></input>
                <button
                    onClick={uploadSpecializationRegistration}
                    className="btn btn-success"
                >
                    Upload
                </button>
            </center>
            </div>

            <div>
                <center>
                    <button
                        onClick={submitDetails}
                        className="btn btn-primary btn-lg"
                        style={{ marginTop: "35px", marginBottom: "35px" }}
                    >
                        Submit
                    </button>

                </center>
            </div>

            <div><FooterNext /></div>
        </div>

        //</> : <Login />
    );
}

