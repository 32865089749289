import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [

	{
		title: "Home",
		path: "/hospital",
		icon: <FaIcons.FaPhone />,
	},
	{
		title: "Add New Training Course",
		path: "/new-training-course",
		icon: <FaIcons.FaPhone />,
	},

	{
		title: "Add New Observership Plan",
		path: "/new-observership-plan",
		icon: <FaIcons.FaPhone />,
	},

	{
		title: "View My Courses",
		path: "/my-courses",
		icon: <FaIcons.FaPhone />,
	},

	{
		title: "View My Observership Plans",
		path: "/my-observership-plans",
		icon: <FaIcons.FaPhone />,
	},

	{
		title: "View SafePlan Requests",
		path: "/view-safeplan-requests",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "Medico Legal Experts",
		path: "/medico-legal-experts",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "Request Visiting Doctor",
		path: "/request-visiting-doc",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "NABH Support",
		path: "/nabh-support",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "High Risk Mgmt Experts",
		path: "/high-risk-mgmt-expt",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "Account",
		path: "/account",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "FAQ",
		path: "/faq",
		icon: <IoIcons.IoMdHelpCircle />,
	},

	{
		title: "Logout",
		path: "/support",
		icon: <IoIcons.IoMdHelpCircle />,
	},
];

{/* 
{
	title: "About Us",
	path: "/about-us",
	icon: <AiIcons.AiFillHome />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	
},
{
	title: "Services",
	path: "/services",
	icon: <IoIcons.IoIosPaper />,
	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	subNav: [
	{
		title: "Service 1",
		path: "/services/services1",
		icon: <IoIcons.IoIosPaper />,
		cName: "sub-nav",
	},
	{
		title: "Service 2",
		path: "/services/services2",
		icon: <IoIcons.IoIosPaper />,
		cName: "sub-nav",
	},
	{
		title: "Service 3",
		path: "/services/services3",
		icon: <IoIcons.IoIosPaper />,
	},
	],
},

{
	title: "Events",
	path: "/events",
	icon: <FaIcons.FaEnvelopeOpenText />,

	iconClosed: <RiIcons.RiArrowDownSFill />,
	iconOpened: <RiIcons.RiArrowUpSFill />,

	subNav: [
	{
		title: "Event 1",
		path: "/events/events1",
		icon: <IoIcons.IoIosPaper />,
	},
	{
		title: "Event 2",
		path: "/events/events2",
		icon: <IoIcons.IoIosPaper />,
	},
	],
},
*/}

