import React, { useState, useContext } from "react";
import "./slider.css";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Carousel from "react-multi-carousel";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "react-multi-carousel/lib/styles.css";
import ShowImages_school_corporate from "../school_corporate/showimages_school_corporate";
import { Slider } from "rsuite";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function HorizontalScrollSlider({path}) {
  const [user, setUser] = useState("");
  const [functionName, setFunctionName] = useState("");

  const [post, setPosts] = useState([]);
  db.collection(`${path}`).onSnapshot((snapshot) => {
    setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
  });

  const slideLeft = () => {
    var slider = document.getElementById("horizontal-slider");
    slider.scrollLeft = slider.scrollLeft - 1500;
  };

  const slideRight = () => {
    var slider = document.getElementById("horizontal-slider");
    slider.scrollLeft = slider.scrollLeft + 1500;
  };

  return (
    <>
      <img
        className="w-full h-[440px] object-cover"
        style={{width:"100%", height:"auto"}}
        src="https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/medicalstudent%2Fwebpage%2FStart.png?alt=media&token=3a0c0194-f09d-4aab-9a2c-2dc47c0081a6"
        alt=""
      />
      <div className="relative flex items-center">
        <MdChevronLeft
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={slideLeft}
          size={40}
        />
        <div
          id="horizontal-slider"
          className="w-full h-full overflow-x-auto scroll whitespace-nowrap scroll-smooth scrollbar-hide"
        >
          {post.map(({ id, post }) => {
            return (
              <div class="inline">
                <Link to={post.onClickfunction}>
                  <img
                    className="w-[220px] inline-block p-2 cursor-pointer hover:scale-105 ease-in-out duration-300 rounded-full shadow-2xl"
                    src={post.slideshow_image}
                    alt="Image One"
                    style={{ height: "250px", width: "250px" }}
                  />
                </Link>
              </div>
            );
          })}
        </div>
        <MdChevronRight
          className="opacity-50 cursor-pointer hover:opacity-100"
          onClick={slideRight}
          size={40}
        />
      </div>
    </>
  );
}

