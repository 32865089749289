import React, { useState } from "react";
import { firebase, cheq, db, database } from "../../firebaseConfig";
import { BrowserRouter as Link } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';

import { Button } from "react-bootstrap";
import FooterNew from "../../Footers/Footer";
import PostRequest from "../components/postRequest";
import ClientDetails from "../components/clientDetails";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import TnC from "../components/tnc";


import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Login from "../../Login";
import FooterNext from "../../Footers/FooterNext";


export default function RequestVisitingDoctor({

}) {
  // Inputs



  const [user] = useAuthState(cheq);
  
  const [todayDate, setTodayDate] = useState(new Date());
  const [reqDate, setReqDate] = useState(new Date());

  const [cityName, setCityName] = useState("");
  const [headCount, setHeadCount] = useState("");
  const [specialization, setSpecialization] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgRepName, setOrgRepName] = useState("");
  const [orgRepDesignation, setRepDesignation] = useState("");
  const [orgRepOfficialEmail, setOrgRepOfficialEmail] = useState("");
  const [orgCompleteAddress, setOrgCompleteAddress] = useState("");

  const cityOptions = [
    { label: 'Bengaluru' },
    { label: 'Delhi' },
    { label: 'Hyderabad' },
    { label: 'Mumbai' },
    { label: 'Pune' },
  ];

  const headCountOptions = [
    { label: 'less than 50' },
    { label: '50-100' },
    { label: '100-500' },
    { label: '500-1000' },
    { label: '1000-5000' },
    { label: 'more than 5000' },
  ];

  const specOptions = [
    { label: 'General Physician' },
    { label: 'Gynecologist' },
    { label: 'Pediatrician' },
    { label: 'Ophthalmologist' },
    { label: 'Dentist' },
  ];


  function postRequest() {

    if(cityName === ""){
      alert("Please enter City");
      return;
    }

    if(headCount === ""){
      alert("Please enter Head Count (Approx.)");
      return;
    }

    if(specialization === ""){
      alert("Please enter Specialization");
      return;
    }

    if(orgName === "" || orgRepName === "" || orgRepDesignation === "" || orgRepOfficialEmail === "" ||
    orgCompleteAddress === ""){
      alert("Please enter Organization Details");
      return;
    }

    /*db.collection("requestvisit-safeplan").add({

      PosterPhone: cheq.currentUser.phoneNumber,
      city: cityName,
      headCount: headCount,
      specialization: specialization,

      orgName: orgName,
      orgRepName: orgRepName,
      orgRepDesignation: orgRepDesignation,
      orgRepOfficialEmail: orgRepOfficialEmail,
      orgCompleteAddress: orgCompleteAddress,

      todayDate:todayDate, 
      requestDate: dayjs(reqDate).format('LL'),
      Time: firebase.firestore.Timestamp.now().toDate().toString(),
      
    });*/

    database.ref("onlinerequest/safeplan/visit").push({

      PosterPhone: cheq.currentUser.phoneNumber,
      city: cityName,
      headCount: headCount,
      specialization: specialization,

      orgName: orgName,
      orgRepName: orgRepName,
      orgRepDesignation: orgRepDesignation,
      orgRepOfficialEmail: orgRepOfficialEmail,
      orgCompleteAddress: orgCompleteAddress,

      todayDate:todayDate, 
      requestDate: dayjs(reqDate).format('LL'),
      Time: firebase.firestore.Timestamp.now().toDate().toString(),

    }).catch(alert);




    window.location.replace('https://rzp.io/l/drplanete-req-visiting-doc');
   
    //alert("Request Placed Successfully!!")
    /*alert("city Name - " + cityName + "\n" + headCount + "\n" + specialization + "\n" +
    orgName + "\n" + orgRepName + "\n" + orgRepDesignation + "\n" + orgRepOfficialEmail + "\n" + 
    orgCompleteAddress + "\n" + dayjs(todayDate).format('LL') + "\n" + dayjs(reqDate).format('LL'));

    */
  }

  return (
    user ? <> <div>
      <div><img
        className="w-full h-[440px] object-cover"
        style={{ width: "1600px", height: "300px" }}
        src="https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/safeplan%2Fweb-heads%2Fweb%20-%20REQUEST%20VISITING%20DOCTOR.png?alt=media&token=66f71f4b-1c4e-4206-b511-a521f0ff5228" 
        alt=""
      /></div>
      <h1>Request Visiting Doctor</h1>
      <div  style={{ marginBottom: "45px" }}>
        <center><Autocomplete
          disablePortal
          id="combo-box-demo"
          options={cityOptions}
          sx={{ width: 500 }}
          onChange={(event, value) => setCityName(value.label)}
          renderInput={(params) => <TextField {...params} label="Select City" />}
        /></center>
      </div>
      <div >

        <div class="container body-content border-1 border-indigo-600" style={{ marginTop: "15px", marginBottom: "35px" }}>
          <div style={{ marginTop: "35px", marginBottom: "15px", width: "450px" }}>
            <TextField id="outlined-basic" fullWidth label="Organization Name" variant="outlined" onChange={(e) => setOrgName(e.target.value)} />
          </div>
          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic" fullWidth label="Organization Representative Name" variant="outlined" onChange={(e) => setOrgRepName(e.target.value)} />
          </div>
          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic" fullWidth label="Organization Representative Designation" variant="outlined" onChange={(e) => setRepDesignation(e.target.value)} />
          </div>

          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic" fullWidth label="Representative Official Email ID" variant="outlined" onChange={(e) => setOrgRepOfficialEmail(e.target.value)} />
          </div>
          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic"
              multiline fullWidth
              maxRows={4}
              label="Organization Address" variant="outlined" onChange={(e) => setOrgCompleteAddress(e.target.value)} />
          </div>

        </div>
      </div>

      <div class="container body-content border-1 border-indigo-600">
        <div class="container" style={{ marginBottom: "15px" }}>
          <p>Post Request for Visiting Doctor</p>
          <div
            class="row justify-content-start inline"
            style={{ marginTop: "15px", marginLeft: "35px" }}
          >
            <div class="col">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={headCountOptions}
                sx={{ width: 300 }}
                onChange={(event, value) => setHeadCount(value.label)}
                renderInput={(params) => <TextField {...params} label="Head Count" />}
              />
            </div>

            <div class="col">
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={specOptions}
                sx={{ width: 300 }}
                onChange={(event, value) => setSpecialization(value.label)}
                renderInput={(params) => <TextField {...params} label="Specialization" />}
              />
            </div>

            <div class="col" style={{ height: "45px", marginBottom: "15px" }}>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker

                  label="Date"
                  value={reqDate}
                  onChange={(newValue) => {
                    setReqDate(newValue);
                  }}
                  toolbarFormat="ddd DD MMMM"
                  showToolbar
                  renderInput={(params) => <TextField {...params}/>}
                />
              </LocalizationProvider>
              <p>You selected {dayjs(reqDate).format('LL')}</p>
            </div>

            <div class="col" style={{ height: "65px", marginBottom: "15px" }}>
              <Button variant="primary" size="lg" onClick={postRequest}>Post Request</Button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="container body-content border-1 border-indigo-600" style={{marginTop: "35px", marginBottom: "25px"}}>
          <div>
            <div><center><h3>Note</h3></center></div>
            <div><p class="text-sm ...">1. Dr Planete is merely providing an Interface Platform to the client wherein the client can post/request to post his lawful requirements as eligible on the platform and seek the response from the prospective Responding Entity on this platform.</p></div>
            <div><p class="text-sm ...">2. Dr Planete shall not have any privity with respect to any terms and conditions or any agreements etc. or with respect to any transactions, or with respect to any interactions, etc. which may be entered into by the client and the Responding Entity once they establish contact through the platform.</p></div>
            <div><p class="text-sm ...">3. Dr Planete does not give any guarantee to the client with regard to the satisfaction of his requirements. Merely, posting of the requirements by the client shall not be presumed or taken as a guarantee to meet and satisfy the requirements of the client. The client understands and agrees that this Service Interface Platform is merely an interface between the client and the Responding Entity.</p></div>
            <div class="text-sm ..."><p>4. All charges are inclusive of all taxes applicable and are non-refundable.
            </p>
            </div>
            <div><p class="text-sm ...">5. Dr Planete shall not be responsible for any future dispute with regard to your competence and authorization by your Organization.
            </p>
            </div>
            <div><p class="text-sm ...">6. You and your Organization shall be duly responsible for all the consequences that may arise out of the use of this Service Interface Platform. It’s the responsibility of the client to keep updated and comply with the terms and conditions of the interface platform.
            </p>
            </div>

          </div>

          <div>
            <center>
              <p>By continuing, I agree to all Terms & Conditions</p>
            </center>
          </div>

        </div>
      </div>
      <div><FooterNext /></div>
    </div></> : <Login/>
  );
}
