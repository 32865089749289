import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@material-ui/core/Button";
import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import firebase from "firebase";
import { db } from "../firebaseConfig";
import "../index.css";
import ShowColumn from "../components/showColumn";
import HorizontalScrollSlider from "../components/horizontalScroll";
import GetApp from "../Footers/getApp";
import FooterNew from "../Footers/Footer";
import ShowProducts from "../components/showProducts";
import Slideshow from "../medicalstudent/slideshow_medicalstudent";
import ShowBranches from "../components/showBranches";
import Pricing from "./pricing";
import GrowPlusPricing from "./pricing";

import { Alert } from "react-bootstrap";
import FooterNext from "../Footers/FooterNext";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

export default function GrowPlus() {
  const [startDate, setStartDate] = useState(new Date().toDateString());
  
  
    const [post, setPosts] = useState([]);
  db.collection("growplans").onSnapshot((snapshot) => {
    setPosts(snapshot.docs.map((doc) => ({ id: doc.id, post: doc.data() })));
  });
  return (
    <div style={{ width: "100%" }}>

      <div class="container" style={{ height: "400px" }}>
        <ShowProducts path="slideshow/growplus/get" />
      </div>

      <HorizontalScrollSlider path="slideshow/gphs/get" style={{ marginBottom: "45px" }} />

      <div style={{ marginTop: "35px", marginBottom: "35px" }}>

        {[
          {v:'primary', text:"Increase your revenue potential with Gro+ Plans."},
          {v:'secondary', text:" Provide wide array of services to your patients in your custom brand"},
          {v:'success', text:"Share Informative Content on your own Custom Brand Platform"},
          {v:'danger', text:"Provide Subscription based services to your patients"},
          {v:'warning', text:"Seemless Appointment Booking for Online/Offline Consultations"},
          {v:'info', text:"Get Yours Now!!"},
        ].map((variant) => (
          <div className="container">
          <Alert key={variant.v} variant={variant.v}>
            <center><b>{variant.text}</b></center>
          </Alert>
          </div>
        ))}

        </div>

      <div class="container" style={{ marginTop: "45px", marginBottom: "35px" }}>
        <GrowPlusPricing />
        </div>

      <div class="container">
      <Alert variant="info">
            <center>By Subscribing to Dr Planete's Gro+ Plans to you agree our the terms & conditions</center>
          </Alert>
      </div>
      <FooterNext />
    </div>
  );
}

