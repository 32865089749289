import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function AboutNEETPG({ }) {

    return (
        <div>
            <Helmet>
                <title>All about NEET-PG</title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>All about NEET-PG!!</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>The Smart Way</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div>
                            <p>The National Eligibility cum Entrance Test for Postgraduate (NEET-PG) is a national level entrance exam in India for admission to postgraduate medical courses such as MD (Doctor of Medicine) and MS (Master of Surgery) programs. The exam is conducted by the National Board of Examinations (NBE).
                            </p>
                        </div>

                        <div>

                            <h5><b>Exam Pattern</b></h5>
                            <p>
                                The NEET-PG exam is a computer-based test and consists of 300 multiple choice questions. The questions are based on the syllabus and the students are required to have a good understanding of the subject matter and the ability to apply their knowledge to solve problems. The test is conducted in a single session and the duration of the test is 3 hours and 30 minutes.The NEET-PG syllabus is based on the undergraduate medical curriculum and includes subjects such as anatomy, physiology, pharmacology, obstetrics and gynecology, and community medicine. The syllabus is divided into two sections: pre- clinical and para-clinical.

                            </p>
                        </div>


                        <div>

                            
                            <div>
                                <h5><b>Pre-clinical subjects</b></h5>

                                <div class="columns-3">

                                    <p>Anatomy</p>
                                    <p>Physiology</p>
                                    <p>Biochemistry</p>

                                </div>


                            </div>

                            <div>

                                <h5><b>Para-clinical subjects</b></h5>

                                <div class="columns-4">
                                    <p>Pharmacology</p>
                                    <p>Microbiology</p>
                                    <p>Forensic Medicine</p>
                                    <p>Pathology</p>
                                </div>

                            </div>

                            <div>

                                <h5><b>Clinical subjects</b></h5>

                                <div class="columns-1">
                                    <p style={{ marginTop: "-5px", marginBottom: "1px" }}>Medicine</p>
                                    <p style={{ marginTop: "-5px", marginBottom: "1px" }}>Pediatrics</p>
                                    <p style={{ marginTop: "-5px", marginBottom: "1px" }}>Surgery</p>
                                    <p style={{ marginTop: "-5px", marginBottom: "1px" }}>Obstetrics and Gynecology</p>
                                    <p style={{ marginTop: "-5px" }}>Community Medicine</p>
                                </div>

                            </div>
                        </div>

                        

                        <div>

                            <h5><b>Preparation & Strategy</b></h5>
                            <p>The best way to prepare for the NEET-PG is by studying from a combination of standard textbooks and question banks. Here are some books that are considered to be the best for NEET-PG preparation:</p>

                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Park's Textbook of Preventive and Social Medicine</b> by Dr. K. Park</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Arora's Textbook of Pediatrics</b> by Dr. K.D. Arora</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Tripathy's Medical Pharmacology</b> by Dr. Dipak Tripathy</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>A Short Textbook of Surgery</b> by Dr. S. Das</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Essentials of Obstetrics and Gynecology</b> by Dr. D. C. Dutta</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Textbook of Pathology</b> by Harsh Mohan</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Textbook of Microbiology</b> by Ananthanarayan and Paniker</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Textbook of Forensic Medicine and Toxicology</b> by K.S. Narayan Reddy</p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}>Textbook of Medicine by <b>Dr. R. K. Sharma</b> </p>
                            <p style={{ marginTop: "-2px", marginBottom: "1px" }}><b>Textbook of Orthopedics</b> by Dr. A. K. Danda</p>


                        </div>


                        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>


                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

            <div><FooterNext /></div>
        </div>
    )
}