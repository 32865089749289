import React, { useState } from "react";
import "./dashboardDoctor.css";
import { cheq, db } from '../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from "../Login";
import Footerhome from "../Footerhome.js";
import HeaderDH from "../HeaderDH";
import FooterDH from "../FooterDH";
import Image from "react-bootstrap/Image";
import { Redirect, Link } from "react-router-dom";
import DoctorRegistration from "./doctorreg";
import FooterNext from "../Footers/FooterNext";
import ShowExperts from "../components/showExperts";
import ShowColumn from "../components/showColumn";
import DisplayColumn from "../components/dashboard/displayColumns";
import ViewObservershipPlans from "../hospital/viewObservershipPlans";
import ViewCourses from "../hospital/viewCourses";
import ViewEdCourses from "./educators/viewEducationalCourses";

function DashboardDoctor() {
  const [user] = useAuthState(cheq);


  const [postsX1, setPostsX1] = useState([]);
  db.collection("second/dashboard/option1").onSnapshot((snapshot) => {
    setPostsX1(snapshot.docs.map((doc) => ({ id: doc.id, postsX1: doc.data() })));
  });

  const [postsX2, setPostsX2] = useState([]);
  db.collection("second/dashboard/option2").onSnapshot((snapshot) => {
    setPostsX2(snapshot.docs.map((doc) => ({ id: doc.id, postsX2: doc.data() })));
  });

  return (

    <div>
      <div style={{ marginLeft: "5%", marginRight: "5%" }}>
            <p><b>To View Request for On-Call Doctor by Hospitals, Schools & Industries</b></p>
            <p>
              Download Dr Planete Mobile App to view the requests placed by Hospitals, Schools, & Industries. Use your registered Mobile Number(Same as mentioned while purchasing Dr Planete membership) as login credentials.
            </p>

            <b>
              Scroll Down To View your listed Training, Observership & Educational Courses. After listing your course on Dr Planete is takes 1-2 days to reflect on this dashboard
            </b>
          </div>
          
          
      <div style={{ marginTop: "35px" }}>
        <div class="container body-content">
          <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            {postsX1.map(({ id, postsX1 }) => {
              return (
                <div class="column">
                  <ShowColumn
                    key={id}
                    slideshow_image={postsX1.slideshow_image}
                    onClickfunction={postsX1.onClickfunction}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "5%", marginRight: "5%" }}>

        <div>

          <div>
            <p><b>Add Training Course (For Practicing Doctors) </b></p>
            <p>
              Training Courses for Practicing Doctors are to be provided by Registered Training Centres. Register your Training Course Today to make it available on Dr Planete Platform if you are a Registered Training Institution as per Medical Council of India.
            </p>
          </div>
        </div>

        <div>
          <p>
            <b>
              Add Educational Course (For Medical Students)
            </b>
          </p>

          <p>
            If teaching is your passion then Add your educational course on Dr Planete today. Be visible to Medical Students across India and countries where Dr Planete platform is available.
          </p>
        </div>
      </div>

      <div style={{ marginTop: "35px" }}>
        <div class="container body-content">
          <div style={{ marginTop: "5px", marginBottom: "5px" }}>
            {postsX2.map(({ id, postsX2 }) => {
              return (
                <div class="column">
                  <ShowColumn
                    key={id}
                    slideshow_image={postsX2.slideshow_image}
                    onClickfunction={postsX2.onClickfunction}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "5%", marginRight: "5%" }}>

        <div>

          <div>
            <p><b>Add Observership Plans - Informal & Formal (For Medical Students, Recent Graduates & Practicing Doctors) </b></p>
            <p>
            Would you like to leverage the potential of your Clinic/Hospital.Do you think you can provide experience to recent graduates at your clinic/hospital. Then Observership Plans on Dr Planete are there for you. List your Observership Plans, both Informal & Formal 
            to provide learning experience to medical students, recent graduates & practicing doctors.   
            </p>
          </div>
        </div>

        <div>
          <p>
            <b>
              Join Dr Planete as Case-Selection & High-Risk Management Expert
            </b>
          </p>

          <p>
          As a private practitioner you have to manage patients independantly, be at your clinic or hospital. High-Risk Cases do come up frequently and it's very important to take note of precautions and steps to be taken at the right time.On Dr Planete , doctors & hospitals can book a 1 on 1 session with our Case Selection & High-Risk Management Experts on a paid basis.
          Inviting Senior Doctors & Professors having more than 20 years of experience to guide private practitioners on Case Selection, Medico Legal & High Risk Management.For more details call or whatsapp us at +918619460759
          </p>
        </div>
      </div>
      
      <div>
        <ShowExperts />
      </div>

      <div>
            <ViewObservershipPlans/>
      </div>

      <div>
        <ViewCourses/>
          </div>

          <div>
            <ViewEdCourses/>
          </div>
      
      <div style={{ marginTop: "70px" }}>
        <FooterNext />
      </div>
    </div>
  );
}

export default DashboardDoctor;
