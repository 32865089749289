import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function AboutFMGE({ }) {

    return (
        <div>
            <Helmet>
                <title>All about FMGE | Dr Planete </title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>What is FMGE?</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>The Smart Way</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", height: "700px", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div>
                            <p>FMGE stands for "Foreign Medical Graduate Examination", also known as "Screening Test". It is an examination conducted by the National Board of Examinations (NBE) in India for foreign medical graduates who wish to practice medicine in India. The examination is intended to assess the medical knowledge and clinical skills of foreign medical graduates and ensure that they meet the standards set by the Medical Council of India (MCI) for medical practitioners in India.
</p>
                        </div>

                        <div><p>To be eligible to take the FMGE, an individual must have a primary medical qualification from a medical institution outside of India that is recognized by the MCI. The FMGE is a computer-based test and is typically conducted twice a year. It is divided into two parts, the first part is multiple choice questions and the second part is the practical examination.</p>
                        </div>

                        <div><p>Passing the FMGE is a requirement for obtaining a medical license in India for foreign medical graduates. It is also a requirement for Indian citizens who have completed their medical education outside of India.

                            </p></div>

                            <div><p>It's worth noting that, the FMGE has been replaced by National Exit Test (NEXT) from 2022, which would be the licentiate exam for all medical graduates including Indian and Foreign medical graduates.</p></div>
                        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>


                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

           <div><FooterNext /></div>
        </div>
    )
}