import React, { useState } from "react";
import { firebase, cheq, db, database } from "../../firebaseConfig";
import { useAuthState } from 'react-firebase-hooks/auth';
import {
    BrowserRouter as Router,
    Route,
    useHistory,
    Switch,
    Redirect,
    Link,
} from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from "@mui/material";
import { Button } from "react-bootstrap";
import GetApp from "../../Footers/getApp";
import FooterNew from "../../Footers/Footer";
import Login from "../../Login";
import FooterNext from "../../Footers/FooterNext";



export default function HospitalBasicDetails({ }) {

    const [user] = useAuthState(cheq);

    //const history = useHistory();

    const [todayDate, setTodayDate] = useState(new Date());
    const [hospitalName, setHospitalName] = useState("");
    const [hospitalRepName, setHospitalRepName] = useState("");
    const [hospitalRepDesignation, setHospitalRepDesignation] = useState("");
    const [emailID, setEmailID] = useState("");

    const [hospitalAddress, setHospitalAddress] = useState("");
    const [hospitalCity, setHospitalCity] = useState("");
    const [hospitalState, setHospitalState] = useState("");
    const [hospitalCountry, setHospitalCountry] = useState("");
    const [hospitalPincode, setHospitalPincode] = useState("");

    const x1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x2 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x3 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x4 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x5 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x6 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x7 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x8 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x9 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x10 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x11 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x12 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x13 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x14 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x15 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x16 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x17 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x18 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x19 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x20 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x21 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x22 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x23 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x24 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x25 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x26 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x27 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x28 = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const [boolX1, setX1] = useState("0");
    const checkX1 = () => {
        setX1(1 - boolX1);
    }

    const [boolX2, setX2] = useState("0");
    const checkX2 = () => {
        setX2(1 - boolX2);
    }

    const [boolX3, setX3] = useState("0");
    const checkX3 = () => {
        setX3(1 - boolX3);
    }

    const [boolX4, setX4] = useState("0");
    const checkX4 = () => {
        setX4(1 - boolX4);
    }

    const [boolX5, setX5] = useState("0");
    const checkX5 = () => {
        setX5(1 - boolX5);
    }

    const [boolX6, setX6] = useState("0");
    const checkX6 = () => {
        setX6(1 - boolX6);
    }

    const [boolX7, setX7] = useState("0");
    const checkX7 = () => {
        setX7(1 - boolX7);
    }

    const [boolX8, setX8] = useState("0");
    const checkX8 = () => {
        setX8(1 - boolX8);
    }

    const [boolX9, setX9] = useState("0");
    const checkX9 = () => {
        setX9(1 - boolX9);
    }

    const [boolX10, setX10] = useState("0");
    const checkX10 = () => {
        setX10(1 - boolX10);
    }

    const [boolX11, setX11] = useState("0");
    const checkX11 = () => {
        setX11(1 - boolX11);
    }

    const [boolX12, setX12] = useState("0");
    const checkX12 = () => {
        setX12(1 - boolX12);
    }

    const [boolX13, setX13] = useState("0");
    const checkX13 = () => {
        setX13(1 - boolX13);
    }

    const [boolX14, setX14] = useState("0");
    const checkX14 = () => {
        setX14(1 - boolX14);
    }

    const [boolX15, setX15] = useState("0");
    const checkX15 = () => {
        setX15(1 - boolX15);
    }

    const [boolX16, setX16] = useState("0");
    const checkX16 = () => {
        setX16(1 - boolX16);
    }

    const [boolX17, setX17] = useState("0");
    const checkX17 = () => {
        setX17(1 - boolX17);
    }

    const [boolX18, setX18] = useState("0");
    const checkX18 = () => {
        setX18(1 - boolX18);
    }

    const [boolX19, setX19] = useState("0");
    const checkX19 = () => {
        setX19(1 - boolX19);
    }

    const [boolX20, setX20] = useState("0");
    const checkX20 = () => {
        setX20(1 - boolX20);
    }

    const [boolX21, setX21] = useState("0");
    const checkX21 = () => {
        setX21(1 - boolX21);
    }

    const [boolX22, setX22] = useState("0");
    const checkX22 = () => {
        setX22(1 - boolX22);
    }

    const [boolX23, setX23] = useState("0");
    const checkX23 = () => {
        setX23(1 - boolX23);
    }

    const [boolX24, setX24] = useState("0");
    const checkX24 = () => {
        setX24(1 - boolX24);
    }

    const [boolX25, setX25] = useState("0");
    const checkX25 = () => {
        setX25(1 - boolX25);
    }

    const [boolX26, setX26] = useState("0");
    const checkX26 = () => {
        setX26(1 - boolX26);
    }

    const [boolX27, setX27] = useState("0");
    const checkX27 = () => {
        setX27(1 - boolX27);
    }

    const [boolX28, setX28] = useState("0");
    const checkX28 = () => {
        setX28(1 - boolX28);
    }

    var x = new Array();        
                        
    if (boolX1 == true) {
        x.push("Anesthetsia");
    } if (boolX2 == true) {
        x.push("Cardiology");
    } if (boolX3 == true) {
        x.push("Cardiothoracic Surgery");
    } if (boolX4 == true) {
        x.push("Cosmetic & Plastic Surgery");
    } if (boolX5 == true) {
        x.push("Dermatology");
    } if (boolX6 == true) {
        x.push("Dentistry");
    } if (boolX7 == true) {
        x.push("Endocrinology");
    } if (boolX8 == true) {
        x.push("ENT Surgery");
    } if (boolX9 == true) {
        x.push("Gastroenterology");
    } if (boolX10 == true) {
        x.push("General Medicine");
    } if (boolX11 == true) {
        x.push("General Surgery");
    } if (boolX12 == true) {
        x.push("Gynecology");
    } if (boolX14 == true) {
        x.push("Intensive Medicine");
    } if (boolX15 == true) {
        x.push("Nephrology");
    } if (boolX16 == true) {
        x.push("Neurology");
    } if (boolX17 == true) {
        x.push("Neuro Surgery");
    } if (boolX18 == true) {
        x.push("Oncology");
    } if (boolX19 == true) {
        x.push("Ophthalmology");
    } if (boolX20 == true) {
        x.push("Orthopedics");
    } if (boolX21 == true) {
        x.push("Pathology");
    } if (boolX22 == true) {
        x.push("Pediatric Medicine");
    } if (boolX23 == true) {
        x.push("Pediatric Surgery");
    } if (boolX24 == true) {
        x.push("Radiology");
    } if (boolX25 == true) {
        x.push("Pulmonology");
    } if (boolX26 == true) {
        x.push("Rheumatology");
    } if (boolX27 == true) {
        x.push("Urology");
    } if (boolX28 == true) {
        x.push("Others");
    }


    function RegisterHospital(phone) {

        if (hospitalName === "" || hospitalRepName === "" || hospitalRepDesignation === "" || emailID === "") {
            alert("Please enter Hospital Details");
            return;
        }

        if (hospitalAddress === "" || hospitalCity === "" || hospitalState === "" || hospitalCountry === "" || hospitalPincode === "") {
            alert("Please enter City");
            return;
        }

        db.collection(`Hospital-Registration`).doc(cheq.currentUser.phoneNumber).set({

            Phone: cheq.currentUser.phoneNumber,
            Name: hospitalName,
            country: hospitalCountry,
            City: hospitalCity,
            state: hospitalState,
            address: hospitalAddress,
            pincode: hospitalPincode,

            representative: hospitalRepName,
            designation: hospitalRepDesignation,
            emailID: emailID,

            Departments: x,
            
            todayDate: todayDate,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        });

       
        database.ref(`Hospital/${cheq.currentUser.phoneNumber}/info`).push({

            Phone: cheq.currentUser.phoneNumber,
            Name: hospitalName,
            country: hospitalCountry,
            City: hospitalCity,
            state: hospitalState,
            address: hospitalAddress,
            pincode: hospitalPincode,

            representative: hospitalRepName,
            designation: hospitalRepDesignation,
            emailID: emailID,

            Departments:x,
            todayDate: todayDate,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);

        alert("Welcome to Dr Planete!!")
        window.location.replace('/drplanete-dashboard');

        //history.push(`/drplanete-dashboard`);
       
    }

    return (

        
        <div >
            <div><center>Let's get Started</center></div>
            <div class="container body-content border-1 border-indigo-600" style={{ marginTop: "15px", marginBottom: "35px" }}>


                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic"
                        multiline fullWidth
                        maxRows={4}
                        label="Hospital Name" variant="outlined" onChange={(e) => setHospitalName(e.target.value)}/>
                </div>
                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic" fullWidth label="Hospital Representative Name" variant="outlined" onChange={(e) => setHospitalRepName(e.target.value)} />
                </div>
                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic" fullWidth label="Hospital Representative Designation" variant="outlined" onChange={(e) => setHospitalRepDesignation(e.target.value)}/>
                </div>

                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic" fullWidth label="Email ID" variant="outlined" onChange={(e) => setEmailID(e.target.value)}/>
                </div>
                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic"
                        multiline fullWidth
                        maxRows={4}
                        label="Hospital Address" variant="outlined" onChange={(e) => setHospitalAddress(e.target.value)}/>
                </div>
                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic"
                        multiline fullWidth
                        maxRows={4}
                        label="City" variant="outlined" onChange={(e) => setHospitalCity(e.target.value)}/>
                </div>

                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic"
                        multiline fullWidth
                        maxRows={4}
                        label="State" variant="outlined" onChange={(e) => setHospitalState(e.target.value)}/>
                </div>

                <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                    <TextField id="outlined-basic"
                        multiline fullWidth
                        maxRows={1}
                        label="Country" variant="outlined" onChange={(e) => setHospitalCountry(e.target.value)}/> 
                </div>
                <div style={{ marginTop: "35px", marginBottom: "15px", width: "450px" }}>
                    <TextField id="outlined-basic" fullWidth label="Pincode" variant="outlined" onChange={(e) => setHospitalPincode(e.target.value)}/>
                </div>


            </div>

            <div class="container body-content border-1 border-indigo-600">
                <div><center><h3 style={{ marginTop: "15px" }}>Departments</h3></center></div>
                <div className="columns-4" style={{ marginTop: "15px" }}>

                    <div><Checkbox {...x1} onChange={checkX1} />Anesthesia</div>
                        <div><Checkbox {...x2} onChange={checkX2} />Cardiology</div>
                        <div><Checkbox {...x3} onChange={checkX3} />Cardiothoracic Surgery</div>
                        <div><Checkbox {...x4} onChange={checkX4} />Cosmetic & Plastic Surgery</div>
                        <div><Checkbox {...x5} onChange={checkX5} />Dermatology</div>
                        <div><Checkbox {...x6} onChange={checkX6} />Dentistry</div>
                        <div><Checkbox {...x7} onChange={checkX7} />Endocrinology</div>
                        <div><Checkbox {...x8} onChange={checkX8} />ENT Surgery</div>
                        <div><Checkbox {...x9} onChange={checkX9} />Gastroenterology</div>
                        <div><Checkbox {...x10} onChange={checkX10} />General Medicine</div>
                        <div><Checkbox {...x11} onChange={checkX11} />General Surgery</div>
                        <div><Checkbox {...x12} onChange={checkX12} />Gynecology</div>
                        <div><Checkbox {...x14} onChange={checkX14} />Intensive Medicine</div>
                        <div><Checkbox {...x15} onChange={checkX15} />Nephrology</div>
                        <div><Checkbox {...x16} onChange={checkX16} />Neurology</div>
                        <div><Checkbox {...x17} onChange={checkX17} />Neuro Surgery</div>
                        <div><Checkbox {...x18} onChange={checkX18} />Oncology</div>
                        <div><Checkbox {...x19} onChange={checkX19} />Ophthalmology</div>
                        <div><Checkbox {...x20} onChange={checkX20} />Orthopedics</div>
                        <div><Checkbox {...x21} onChange={checkX21} />Pathology</div>
                        <div><Checkbox {...x22} onChange={checkX22} />Pediatric Medicine</div>
                        <div><Checkbox {...x23} onChange={checkX23} />Pediatric Surgery</div>
                        <div><Checkbox {...x24} onChange={checkX24} />Radiology</div>
                        <div><Checkbox {...x25} onChange={checkX25} />Pulmonology</div>
                        <div><Checkbox {...x26} onChange={checkX26} />Rheumatology</div>
                        <div><Checkbox {...x27} onChange={checkX27} />Urology</div>
                        <div><Checkbox {...x28} onChange={checkX28} />Others</div>
   

                </div>
            </div>
            <div style={{ marginTop: "25px" }}>
                <center>
                    <Button variant="primary" size="lg" style={{ width: "150px", marginBottom: "5px" }} onClick={RegisterHospital}>Save</Button>
                </center>
            </div>

            <div style={{ marginTop: "15px" }}><FooterNext /></div>
        </div>
    );
}