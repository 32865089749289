import React, { useState } from "react";
import "./addEducationalCourse.css";
import { cheq, db, database } from "../../firebaseConfig";
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from "firebase";
import {
    BrowserRouter as Router,
    Route,
    useHistory,
    Switch,
    Redirect,
    Link,
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import FooterNext from "../../Footers/FooterNext";

import { BiCheckboxSquare } from "react-icons/bi";
import { PricingTable, PricingSlot, PricingDetail } from 'react-pricing-table';
import Login from "../../Login";


export default function EducationalCourseDetails({ }) {

    const [user] = useAuthState(cheq);

    //const navigate = useHistory();

    const [value, setValue] = useState(new Date());

    const [courseTitle, setCourseTitle] = useState("");
    const [courseDuration, setCourseDuration] = useState("");
    const [courseStartingDate, setCourseStartingDate] = useState(new Date());
    const [courseCertificate, setCourseCertificate] = useState("");

    const [courseFees, setCourseFee] = useState("");
    const [termsnconditions, setTermsNConditions] = useState("");
    const [refundPolicy, setRefundPolicy] = useState("");

    const online = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const hybrid = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const at_centre = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const x00 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x01 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x02 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x03 = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const x1 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x2 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x3 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x4 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x5 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x6 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x7 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x8 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x9 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x10 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x11 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x12 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x14 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x15 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x16 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x17 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x18 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x19 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x20 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x21 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x22 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x23 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x24 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x25 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x26 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x27 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x28 = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const [boolX00, setX00] = useState("0");
    const checkX00 = () => {
        setX00(1 - boolX00);
    }

    const [boolX01, setX01] = useState("0");
    const checkX01 = () => {
        setX01(1 - boolX01);
    }

    const [boolX02, setX02] = useState("0");
    const checkX02 = () => {
        setX02(1 - boolX02);
    }

    const [boolX03, setX03] = useState("0");
    const checkX03 = () => {
        setX03(1 - boolX03);
    }

    const [boolX1, setX1] = useState("0");
    const checkX1 = () => {
        setX1(1 - boolX1);
    }

    const [boolX2, setX2] = useState("0");
    const checkX2 = () => {
        setX2(1 - boolX2);
    }

    const [boolX3, setX3] = useState("0");
    const checkX3 = () => {
        setX3(1 - boolX3);
    }

    const [boolX4, setX4] = useState("0");
    const checkX4 = () => {
        setX4(1 - boolX4);
    }

    const [boolX5, setX5] = useState("0");
    const checkX5 = () => {
        setX5(1 - boolX5);
    }

    const [boolX6, setX6] = useState("0");
    const checkX6 = () => {
        setX6(1 - boolX6);
    }

    const [boolX7, setX7] = useState("0");
    const checkX7 = () => {
        setX7(1 - boolX7);
    }

    const [boolX8, setX8] = useState("0");
    const checkX8 = () => {
        setX8(1 - boolX8);
    }

    const [boolX9, setX9] = useState("0");
    const checkX9 = () => {
        setX9(1 - boolX9);
    }

    const [boolX10, setX10] = useState("0");
    const checkX10 = () => {
        setX10(1 - boolX10);
    }

    const [boolX11, setX11] = useState("0");
    const checkX11 = () => {
        setX11(1 - boolX11);
    }

    const [boolX12, setX12] = useState("0");
    const checkX12 = () => {
        setX12(1 - boolX12);
    }

    const [boolX13, setX13] = useState("0");
    const checkX13 = () => {
        setX13(1 - boolX13);
    }

    const [boolX14, setX14] = useState("0");
    const checkX14 = () => {
        setX14(1 - boolX14);
    }

    const [boolX15, setX15] = useState("0");
    const checkX15 = () => {
        setX15(1 - boolX15);
    }

    const [boolX16, setX16] = useState("0");
    const checkX16 = () => {
        setX16(1 - boolX16);
    }

    const [boolX17, setX17] = useState("0");
    const checkX17 = () => {
        setX17(1 - boolX17);
    }

    const [boolX18, setX18] = useState("0");
    const checkX18 = () => {
        setX18(1 - boolX18);
    }

    const [boolX19, setX19] = useState("0");
    const checkX19 = () => {
        setX19(1 - boolX19);
    }

    const [boolX20, setX20] = useState("0");
    const checkX20 = () => {
        setX20(1 - boolX20);
    }

    const [boolX21, setX21] = useState("0");
    const checkX21 = () => {
        setX21(1 - boolX21);
    }

    const [boolX22, setX22] = useState("0");
    const checkX22 = () => {
        setX22(1 - boolX22);
    }

    const [boolX23, setX23] = useState("0");
    const checkX23 = () => {
        setX23(1 - boolX23);
    }

    const [boolX24, setX24] = useState("0");
    const checkX24 = () => {
        setX24(1 - boolX24);
    }

    const [boolX25, setX25] = useState("0");
    const checkX25 = () => {
        setX25(1 - boolX25);
    }

    const [boolX26, setX26] = useState("0");
    const checkX26 = () => {
        setX26(1 - boolX26);
    }

    const [boolX27, setX27] = useState("0");
    const checkX27 = () => {
        setX27(1 - boolX27);
    }

    const [boolX28, setX28] = useState("0");
    const checkX28 = () => {
        setX28(1 - boolX28);
    }


    const x100 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x101 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x102 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x103 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x104 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x105 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x106 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x107 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x108 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x109 = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const x110 = { inputProps: { 'aria-label': 'Checkbox demo' } };


    const [boolIsCourseOnline, setBoolIsCourseOnline] = useState("0");
    const checkIsCourseOnline = () => {
        setBoolIsCourseOnline(1 - boolIsCourseOnline);
    }

    const [boolIsCourseAtCentre, setBoolIsCourseAtCentre] = useState("0");
    const checkIsCourseAtCentre = () => {
        setBoolIsCourseAtCentre(1 - boolIsCourseAtCentre);
    }

    const [boolIsCourseHybrid, setBoolIsCourseHybrid] = useState("0");
    const checkIsCourseHybrid = () => {
        setBoolIsCourseHybrid(1 - boolIsCourseHybrid);
    }


    var teach = new Array();

    if (boolIsCourseAtCentre == true) {
        teach.push("At Centre");
    } if (boolIsCourseOnline == true) {
        teach.push("Online");
    } if (boolIsCourseHybrid == true) {
        teach.push("Hybrid");
    }

    var eligible = new Array();


    if (boolX00 == true) {
        eligible.push("MBBS Student / NEET-PG Aspirant ; ");
    } if (boolX01 == true) {
        eligible.push("BDS Student / NEET-PG(MDS) Aspirant ; ");
    } if (boolX02 == true) {
        eligible.push("Alternative Medicine Student(BHMS/BAMS) ; ");
    } if (boolX03 == true) {
        eligible.push("Specialists & above / NEET-SS / MRCS / MRCP etc ; ");
    }

    const [boolX100, setX100] = useState("0");
    const checkX100 = () => {
        setX100(1 - boolX100);
    }

    const [boolX101, setX101] = useState("0");
    const checkX101 = () => {
        setX101(1 - boolX101);
    }

    const [boolX102, setX102] = useState("0");
    const checkX102 = () => {
        setX102(1 - boolX102);
    }

    const [boolX103, setX103] = useState("0");
    const checkX103 = () => {
        setX103(1 - boolX103);
    }

    const [boolX104, setX104] = useState("0");
    const checkX104 = () => {
        setX104(1 - boolX104);
    }

    const [boolX105, setX105] = useState("0");
    const checkX105 = () => {
        setX105(1 - boolX105);
    }

    const [boolX106, setX106] = useState("0");
    const checkX106 = () => {
        setX106(1 - boolX106);
    }

    const [boolX107, setX107] = useState("0");
    const checkX107 = () => {
        setX107(1 - boolX107);
    }

    const [boolX108, setX108] = useState("0");
    const checkX108 = () => {
        setX108(1 - boolX108);
    }

    const [boolX109, setX109] = useState("0");
    const checkX109 = () => {
        setX109(1 - boolX109);
    }

    const [boolX110, setX110] = useState("0");
    const checkX110 = () => {
        setX110(1 - boolX110);
    }

    var x = new Array();












    if (boolX100 == true) {
        x.push("Anatomy (MBBS) ; ");
    } if (boolX101 == true) {
        x.push("Physiology (MBBS) ; ");
    } if (boolX102 == true) {
        x.push("Biochemistry (MBBS) ; ");
    } if (boolX103 == true) {
        x.push("Pathology (MBBS) ; ");
    } if (boolX104 == true) {
        x.push("Microbiology (MBBS) ; ");
    } if (boolX105 == true) {
        x.push("Medicine (MBBS) ; ");
    } if (boolX106 == true) {
        x.push("Pediatrics (MBBS) ; ");
    } if (boolX107 == true) {
        x.push("Surgery (MBBS) ; ");
    } if (boolX108 == true) {
        x.push("Obstetrics & Gynecology (MBBS) ; ");
    } if (boolX109 == true) {
        x.push("Forensic Medicine (MBBS) ; ");
    } if (boolX110 == true) {
        x.push("Preventive Social Medicine (MBBS) ; ");
    } if (boolX110 == true) {
        x.push("Pharmacology ; ");
    }

    if (boolX1 == true) {
        x.push("Anesthetsia ; ");
    } if (boolX2 == true) {
        x.push("Cardiology ; ");
    } if (boolX3 == true) {
        x.push("Cardiothoracic Surgery ; ");
    } if (boolX4 == true) {
        x.push("Cosmetic & Plastic Surgery ; ");
    } if (boolX5 == true) {
        x.push("Dermatology ; ");
    } if (boolX6 == true) {
        x.push("Dentistry ; ");
    } if (boolX7 == true) {
        x.push("Endocrinology ; ");
    } if (boolX8 == true) {
        x.push("ENT Surgery ; ");
    } if (boolX9 == true) {
        x.push("Gastroenterology ; ");
    } if (boolX10 == true) {
        x.push("General Medicine ; ");
    } if (boolX11 == true) {
        x.push("General Surgery ; ");
    } if (boolX12 == true) {
        x.push("Gynecology ; ");
    } if (boolX14 == true) {
        x.push("Intensive Medicine ; ");
    } if (boolX15 == true) {
        x.push("Nephrology ; ");
    } if (boolX16 == true) {
        x.push("Neurology ; ");
    } if (boolX17 == true) {
        x.push("Neuro Surgery ; ");
    } if (boolX18 == true) {
        x.push("Oncology ; ");
    } if (boolX19 == true) {
        x.push("Ophthalmology ; ");
    } if (boolX20 == true) {
        x.push("Orthopedics ; ");
    } if (boolX21 == true) {
        x.push("Pathology ; ");
    } if (boolX22 == true) {
        x.push("Pediatric Medicine ; ");
    } if (boolX23 == true) {
        x.push("Pediatric Surgery ; ");
    } if (boolX24 == true) {
        x.push("Radiology ; ");
    } if (boolX25 == true) {
        x.push("Pulmonology ; ");
    } if (boolX26 == true) {
        x.push("Rheumatology ; ");
    } if (boolX27 == true) {
        x.push("Urology ; ");
    } if (boolX28 == true) {
        x.push("Others ; ");
    }

    


    function PlusPremiumEducationalCourse() {


        if (courseTitle === "") {
            alert("Please enter Course Title");
            return;
        }
        if (courseDuration === "") {
            alert("Please enter Course Duration");
            return;
        }
        if (courseFees === "") {
            alert("Please enter Course Fee");
            return;
        }
        if (courseStartingDate === "") {
            alert("Please enter Course Starting Date");
            return;
        }

        if (courseCertificate === "") {
            alert("Please enter Course Certificate");
            return;
        }

        if (termsnconditions === "" || refundPolicy === "") {
            alert("Please enter Terms & Conditions and Refund Policy");
            return;
        }



        db.collection(`educational_course`).doc(dayjs(value).format('LL')).collection(cheq.currentUser.phoneNumber).add({

            Plan: "Premium",
            PaymentStatus: "Pending",
            courseTitle: courseTitle,
            courseDuration: courseDuration,
            courseFees: courseFees,
            courseStartingDate: dayjs(courseStartingDate).format('LL'),
            courseCertificate: courseCertificate,

            courseTnC: termsnconditions,
            courseRefundPolicy: refundPolicy,
            Eligible: eligible,
            Subject: x,
            mode: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        });


        database.ref(`educator/${cheq.currentUser.phoneNumber}/educationalcourses`).push({

            Plan: "Premium",
            PaymentStatus: "Pending",
            courseTitle: courseTitle,
            courseDuration: courseDuration,
            courseFees: courseFees,
            courseStartingDate: dayjs(courseStartingDate).format('LL'),
            courseCertificate: courseCertificate,

            courseTnC: termsnconditions,
            courseRefundPolicy: refundPolicy,

            Subject: x,
            Eligible: eligible,
            mode: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);

        alert("Proceeding to Payment!!")
        window.location.replace('https://pages.razorpay.com/drplanete-edcourse');


    }

    function PlusBasicEducationalCourse() {


        if (courseTitle === "") {
            alert("Please enter Course Title");
            return;
        }
        if (courseDuration === "") {
            alert("Please enter Course Duration");
            return;
        }
        if (courseFees === "") {
            alert("Please enter Course Fee");
            return;
        }
        if (courseStartingDate === "") {
            alert("Please enter Course Starting Date");
            return;
        }

        if (courseCertificate === "") {
            alert("Please enter Course Certificate");
            return;
        }

        if (termsnconditions === "" || refundPolicy === "") {
            alert("Please enter Terms & Conditions and Refund Policy");
            return;
        }



        db.collection(`educational_course`).doc(dayjs(value).format('LL')).collection(cheq.currentUser.phoneNumber).add({

            Plan: "Basic",
            courseTitle: courseTitle,
            courseDuration: courseDuration,
            courseFees: courseFees,
            courseStartingDate: dayjs(courseStartingDate).format('LL'),
            courseCertificate: courseCertificate,

            courseTnC: termsnconditions,
            courseRefundPolicy: refundPolicy,
            Eligible: eligible,
            Subject: x,
            mode: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);


        database.ref(`educator/${cheq.currentUser.phoneNumber}/educationalcourses`).push({

            Plan: "Basic",
            courseTitle: courseTitle,
            courseDuration: courseDuration,
            courseFees: courseFees,
            courseStartingDate: dayjs(courseStartingDate).format('LL'),
            courseCertificate: courseCertificate,

            courseTnC: termsnconditions,
            courseRefundPolicy: refundPolicy,

            Subject: x,
            Eligible: eligible,
            mode: teach,

            Time: firebase.firestore.Timestamp.now().toDate().toString(),

        }).catch(alert);

        alert("Course Added Successfully!!")
        //Redirect
        //history.push(`/drplanete-dashboard`);

    }

    return (
        user ? <div >
            <div class="container" style={{ width: "100%", height: "auto" }}>
                
                    <div style={{ marginTop: "1px", marginBottom: "35px" }}>

                        <div>
                            <center>
                                <h3>Hi, Please enter details about your Educational Course</h3>
                            </center>
                        </div>
                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Course Title" variant="outlined"
                                onChange={(e) => setCourseTitle(e.target.value)}
                            />
                        </div>

                        <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic" fullWidth
                                label="Course Duration"
                                variant="outlined"
                                onChange={(e) => setCourseDuration(e.target.value)} />
                        </div>

                        <div style={{ marginTop: "15px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="Course Starting Date"
                                    fullWidth
                                    style={{ width: "1000px" }}
                                    value={value}
                                    onChange={(newValue) => {
                                        setCourseStartingDate(newValue);
                                    }}
                                />
                            </LocalizationProvider>
                        </div>


                        <div class="container body-content border-1 border-indigo-600" style={{ marginTop: "15px", marginBottom: "15px" }}>
                            <center>
                                <h3>Mode of Teaching</h3>
                                <div className="columns-3" style={{ marginTop: "15px" }}>

                                    <div><Checkbox {...at_centre} onChange={checkIsCourseAtCentre} />At Centre</div>
                                    <div><Checkbox {...online} onChange={checkIsCourseOnline} />Online</div>
                                    <div><Checkbox {...hybrid} onChange={checkIsCourseHybrid} />Hybrid</div>
                                </div>
                            </center>
                        </div>



                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                fullWidth
                                label="Course Certificate"
                                variant="outlined"
                                onChange={(e) => setCourseCertificate(e.target.value)} />

                            <p>Write <b>Informal</b> if the course is for Competitive Exam Preparation</p>
                        </div>
                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Course Fee"
                                variant="outlined"
                                onChange={(e) => setCourseFee(e.target.value)} />
                        </div>
                        
                        <div><div class="container body-content border-1 border-indigo-600">
                            <div><center><h3 style={{ marginTop: "15px" }}>Eligiblity Criteria</h3>
                                <div><Checkbox {...x00} onChange={checkX00} />MBBS Student / NEET-PG Aspirant</div>
                                <div><Checkbox {...x01} onChange={checkX01} />BDS Student / NEET-PG(MDS) Aspirant</div>
                                <div><Checkbox {...x02} onChange={checkX02} />Alternative Medicine Student(BHMS/BAMS)</div>
                                <div><Checkbox {...x03} onChange={checkX03} />Specialists & above / NEET-SS / MRCS / MRCP etc</div>

                                <div style={{ marginTop: "15px" }}>
                                    <p><b>Courses will be visible to Eligible Students & Doctors</b></p>
                                    <p style={{ color: "blue" }}><b>In case of Specialist & Above the courses will be shown to both relevant Doctors & Medical students</b></p>
                                </div>
                            </center></div>
                        </div>
                        </div>

                        <div>
                            <div><center><h3 style={{ marginTop: "15px" }}>Course Sector</h3></center></div>

                            <div>
                                <center>
                                    <p><b>Core MBBS Subjects</b></p>
                                </center>
                            </div>

                            <div style={{ marginTop: "15px" }}>
                                <div className="column"><Checkbox {...x100} onChange={checkX100} />Anatomy</div>
                                <div className="column"><Checkbox {...x101} onChange={checkX101} />Physiology</div>
                                <div className="column"><Checkbox {...x102} onChange={checkX102} />Biochemistry</div>
                                <div className="column"><Checkbox {...x103} onChange={checkX103} />Pathology</div>
                                <div className="column"><Checkbox {...x104} onChange={checkX104} />Microbiology</div>
                                <div className="column"><Checkbox {...x105} onChange={checkX105} />Medicine</div>
                                <div className="column"><Checkbox {...x106} onChange={checkX106} />Pediatrics</div>
                                <div className="column"><Checkbox {...x107} onChange={checkX107} />Surgery</div>
                                <div className="column"><Checkbox {...x108} onChange={checkX108} />Obstetrics & Gynecology</div>
                                <div className="column"><Checkbox {...x109} onChange={checkX109} />Forensic Medicine</div>
                                <div className="column"><Checkbox {...x110} onChange={checkX110} />Preventive Social Medicine</div>
                                <div className="column"><Checkbox {...x110} onChange={checkX110} />Pharmacology</div>
                            </div>

                        </div>  


                        <div style={{marginTop:"200px"}}>  
                            <div style={{ marginTop: "45px" }}>
                                <center>
                                <h5 style={{color:"blue"}}><b>Specialization Subjects</b></h5>
                                </center>
                        </div>

                            <div style={{ marginTop: "15px" }}>

                                <div className="column"><Checkbox {...x1} onChange={checkX1} />Anesthesia</div>
                                <div className="column"><Checkbox {...x2} onChange={checkX2} />Cardiology</div>
                                <div className="column"><Checkbox {...x3} onChange={checkX3} />Cardiothoracic Surgery</div>
                                <div className="column"><Checkbox {...x4} onChange={checkX4} />Cosmetic & Plastic Surgery</div>
                                <div className="column"><Checkbox {...x5} onChange={checkX5} />Dermatology</div>
                                <div className="column"><Checkbox {...x6} onChange={checkX6} />Dentistry</div>
                                <div className="column"><Checkbox {...x7} onChange={checkX7} />Endocrinology</div>
                                <div className="column"><Checkbox {...x8} onChange={checkX8} />ENT Surgery</div>
                                <div className="column"><Checkbox {...x9} onChange={checkX9} />Gastroenterology</div>
                                <div className="column"><Checkbox {...x10} onChange={checkX10} />General Medicine</div>
                                <div className="column"><Checkbox {...x11} onChange={checkX11} />General Surgery</div>
                                <div className="column"><Checkbox {...x12} onChange={checkX12} />Gynecology</div>
                                <div className="column"><Checkbox {...x14} onChange={checkX14} />Intensive Medicine</div>
                                <div className="column"><Checkbox {...x15} onChange={checkX15} />Nephrology</div>
                                <div className="column"><Checkbox {...x16} onChange={checkX16} />Neurology</div>
                                <div className="column"><Checkbox {...x17} onChange={checkX17} />Neuro Surgery</div>
                                <div className="column"><Checkbox {...x18} onChange={checkX18} />Oncology</div>
                                <div className="column"><Checkbox {...x19} onChange={checkX19} />Ophthalmology</div>
                                <div className="column"><Checkbox {...x20} onChange={checkX20} />Orthopedics</div>
                                <div className="column"><Checkbox {...x21} onChange={checkX21} />Pathology</div>
                                <div className="column"><Checkbox {...x22} onChange={checkX22} />Pediatric Medicine</div>
                                <div className="column"><Checkbox {...x23} onChange={checkX23} />Pediatric Surgery</div>
                                <div className="column"><Checkbox {...x24} onChange={checkX24} />Radiology</div>
                                <div className="column"><Checkbox {...x25} onChange={checkX25} />Pulmonology</div>
                                <div className="column"><Checkbox {...x26} onChange={checkX26} />Rheumatology</div>
                                <div className="column"><Checkbox {...x27} onChange={checkX27} />Urology</div>
                                <div className="column"><Checkbox {...x28} onChange={checkX28} />Others</div>

                                </div>
                        </div>

                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Terms & Conditions"
                                variant="outlined"
                                onChange={(e) => setTermsNConditions(e.target.value)} />
                        </div>

                        <div style={{ marginTop: "35px", marginBottom: "15px" }}>
                            <TextField id="outlined-basic"
                                multiline fullWidth
                                maxRows={4}
                                label="Cancellation & Refund Policy"
                                variant="outlined"
                                onChange={(e) => setRefundPolicy(e.target.value)} />
                        </div>
                    </div>

                    <div>
                        <p>By listing your Educational Course, you agree to Dr Planete's Terms & Conditions and Privacy Policy</p>
                    </div>
                <div>
                    <PricingTable highlightColor='#1976D2'>
                        <PricingSlot title='FREE'>
                            <PricingDetail> <b style={{ fontSize: "35px" }}>10%</b> <p style={{ fontSize: "15px" }}>Course Fee/Per Enrollment</p></PricingDetail>
                            <PricingDetail> <p>Promotions Charges as per Prevailing Rates</p> </PricingDetail>
                            <PricingDetail> <p>Monthly Payouts</p> </PricingDetail>
                            
                            <center>
                                <Button size="lg" variant="secondary" onClick={PlusBasicEducationalCourse}>Try for Free</Button>
                                </center>
                        </PricingSlot>
                        
                    
                        <PricingSlot highlighted buttonText='' title='PREMIUM' priceText="@ just ₹ 5999/per year (incl. all taxes)" >
                            <PricingDetail> <b style={{ fontSize: "40px", color: "blue" ,fontFamily:"Quicksand"}}>4%</b>  <p style={{ fontSize: "15px" }}>Course Fee/Per Enrollment</p></PricingDetail>
                            <PricingDetail> <b style={{ fontSize: "35px", color: "red", fontFamily: "cursive" }}>Save 6%</b>  <p style={{ fontSize: "15px" }}><b>On every Enrollment</b></p><p style={{ marginTop: "-5px" }}>(Compared to Free Plan)</p> </PricingDetail>
                            <PricingDetail> <b>1 - Year Free Promotions on Dr Planete</b> </PricingDetail>
                            <PricingDetail> <b>Weekly Payouts</b></PricingDetail>

                            <center>
                                <Button size="lg" variant="success" onClick={PlusPremiumEducationalCourse}>Get Premium</Button>
                                </center>

                        </PricingSlot>
                        


                    </PricingTable>
                </div>
            </div>
            <div style={{ marginTop: "45px" }}><FooterNext /></div>
        </div> :<Login/>
    );
}