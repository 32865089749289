import React, { useState } from "react";
import { firebase, cheq } from "../firebaseConfig";
import { BrowserRouter as Link } from "react-router-dom";
import Header from "../HeaderGen";
import GetApp from "../Footers/getApp";
import FooterNew from "../Footers/Footer";

export default function MedicalStudentLogin({
    from,
}) {
    // Inputs
    const [mynumber, setnumber] = useState("");
    const [otp, setotp] = useState("");
    const [show, setshow] = useState(false);
    const [final, setfinal] = useState("");

    // Sent OTP

    const signin = () => {
        if (mynumber === "" || mynumber.length < 10) return;

        let verify = new firebase.auth.RecaptchaVerifier("recaptcha-container");
        cheq
            .signInWithPhoneNumber("+91" + mynumber, verify)
            .then((result) => {
                setfinal(result);
                <div class="alert alert-success" role="alert">
                    A simple success alert—check it out!
                </div>;
                //alert("code sent");
                setshow(true);
            })
            .catch((err) => {
                alert(err);
                window.location.reload();
            });
    };

    // Validate OTP
    const ValidateOtp = () => {
        if (otp === null || final === null) return;
        final
            .confirm(otp)
            .then((result) => {
                // success
                <Link to={from}/>
            })
            .catch((err) => {
                alert("Wrong code");
            });
    };

    return (
        <div className="show_school">
            <h1>Medical Student Login</h1>
            <div
                style={{ display: "flex", height: "80%", justifyContent: "flex-end" }}
            >
                <div className="show_canva" style={{ width: "60%", height: "100%" }}>
                    <iframe
                        loading="lazy"
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "80%",
                            top: "0",
                            left: "0",
                            border: "none",
                            padding: "0",
                            margin: "0",
                        }}
                        src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAE2ikAEOgA&#x2F;view?embed"
                        allowfullscreen="allowfullscreen"
                        allow="fullscreen"
                    ></iframe>
                </div>

                <div
                    style={{
                        display: "flex",
                        height: "80%",
                        width: "70%",
                        justifyContent: "flex-end",
                    }}
                >
                    <div class="container" style={{ marginLeft: "7px" }}>
                        <div>
                            <img src="/logo.png" alt="" />
                            <div style={{ display: !show ? "block" : "none" }}>
                                <input
                                    value={mynumber}
                                    class="form-control"
                                    style={{ marginRight: "1px", marginLeft: "1px" }}
                                    onChange={(e) => {
                                        setnumber(e.target.value);
                                    }}
                                    placeholder="phone number"
                                />
                                <br />
                                <br />
                                <div id="recaptcha-container"></div>
                                <button
                                    class="btn btn-success"
                                    style={{ marginTop: "5px" }}
                                    onClick={signin}
                                >
                                    Send OTP
                                </button>
                            </div>
                            <div
                                class="form-group form-control-lg col-lg-3"
                                style={{ display: show ? "block" : "none" }}
                            >
                                <input
                                    type="text"
                                    class="form-control"
                                    placeholder={"Enter your OTP"}
                                    onChange={(e) => {
                                        setotp(e.target.value);
                                    }}
                                ></input>
                                <br />
                                <br />
                                <button class="btn btn-success" onClick={ValidateOtp}>
                                    Verify
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <GetApp/>
            <FooterNew/>
        </div>
    );
}
