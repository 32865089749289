import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function IncreasePractice({ }) {

    return (
        <div>
            <Helmet>
                <title>Top 8 ways to increase your medical practice | Dr Planete </title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>Increase Practice for Doctors</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>The Smart Way</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div><p>1. <b>Be confident </b>& supportive towards your patients. Remember, word of mouth is the most
                            effective towards building a great practice in long term
                        </p></div>

                        <div><p>2. <b>Do proper counselling </b> to your patients, Do not forget - <b>Medicine is art as well as science</b>.
                            Along with treatment it is the moral support that heals</p></div>

                        <div><p>3. Conduct awarness sessions at Schools & Corporate Institutions near you. This way you'll build trust & long term engagement with people in your clientage area. You can also view Schools/Corporations who have posted request for visiting doctor on Dr Planete. </p></div>

                        <div style={{marginLeft: "1%", marginRight:"1%"}}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>

                        <div>
                            <p>4. Give your patients a wide array of services. Tie-up with your colleagues from different specialization to provide multi-speciality services at your clinic. You can also request On-Call Doctors on Dr Planete Platform at your clinic/hospital to provide multi-speciality services at no extra cost*</p>
                        </div>
                        <div><p>5. Set a right marketing budget. As a doctor, marketing means creating awareness about your
                            services among the population in your area. Your marketing content should <b>strictly </b> be
                            informative and devoid of any offers (discounts, free consultations, diagnostic tests etc).
                        </p></div>

                        <div><p>6. Your Marketing channels should be a combination of both outdoor & online marketing. For
                            superspecialists/hospitals more than 50 beds, print media is also an option to create awareness
                            among the masses.For budget optimized marketing plans customized for your clinic/hospital connect with our Marketing Experts only on Dr Planete Mobile App</p></div>


                        <div>
                            <p>7. Create Informative content to educate people and covert potential patient. You can do this by starting your blog, a channel on social media or even create your own platform to educate, counsell & <b>grow your practice with Dr Planete's Grow+ Plans</b></p>
                        </div>

                        <div style={{marginLeft: "1%", marginRight:"1%"}}>
                            <Alert variant="success">
                                <b>Start growing your Practice today with Dr Planete's Gro+ Plans.</b>
                            </Alert>
                        </div>

                        {/*<div>
                            <p>8. Provide better experience to your patients. </p>
                        </div>

                        <div>
                            <p>9. Some point here</p>
    </div>*/}

                        <div>
                            <p>8. Hire the right staff for your clinic. Your staff must behave properly with the patients & attendants.</p>
                        </div>
                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

            
            <div><FooterNext /></div>
        </div>
    )
}