import React from "react";
import ShowExperts from "../components/showExperts";
import ShowMemes from "../components/showMemes";
import FooterNext from "../Footers/FooterNext";

export default function CCA_Confirmation() {

    return (
        <div>
            <div>
                <img
                    className="w-full h-[440px] object-cover"
                    style={{ width: "100%", height: "auto" }}
                    src="submit_application.png"
                    alt="" />
            </div>

            <div style={{ marginTop: "35px" }}>
                <center>
                    <h5><b>Now What's Next!!</b></h5>

                    <p>1. Our Team will be looking up to the details you have provided in the application</p>

                    <p>2. Please give us 2-3 business days to process your application</p>

                    <p>3. We'll be shortlisting Creators based on their creativity & originality </p>

                    <p>4. The selected Creators will become a part of Dr Planete's Med-Creations program where they will be getting access to Dr Planete platform to share and monetize their content. Also, they will be receiving mentorship and guidance on various aspects.</p>

                    <p>5. The selected Creators will also be able to share their content on Dr Planete Social, a spot only for VIP Medicos </p>


                </center>
            </div>

            <div style={{ marginTop: "25px" }}>
                <center>
                <h5><b>Till then, Do take a look at our Experts & other content</b></h5>
                </center>
            </div>
            <div style={{ marginTop: "-5px" }}>
                <ShowExperts />
            </div>

            <div>
                <ShowMemes />
            </div>

            <div style={{marginTop:"35px"}}>
                <FooterNext/>
            </div>
        </div>
    )
}