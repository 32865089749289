import react from "react"
import { Helmet } from "react-helmet"
import { Alert } from "react-bootstrap"
import Rightside from "../../components/rightsider/rightside"
import FooterNew from "../../Footers/Footer"
import GetApp from "../../Footers/getApp"
import FooterNext from "../../Footers/FooterNext"



export default function AboutNEETSS({ }) {

    return (
        <div>
            <Helmet>
                <title>All about NEET-SS | Dr Planete </title>
            </Helmet>
            <div style={{ marginLeft: "35px" }}>
                <div>
                    <h1>All about NEET-SS?</h1>
                </div>
                <div style={{ marginTop: "-15px" }}>
                    <p>The Smart Way</p>
                </div>

            </div>

            <div class="flex gap-3 columns-2">
                <div className="container">

                    <div style={{ width: "100%", height: "100%", background: "white", marginLeft: "15px", marginRight: "35px" }}>

                        <div>
                            <p>
                                NEET-SS (Super Speciality) is a national level entrance examination in India for admission to various super speciality medical courses such as DM (Doctor of Medicine) and M.Ch (Master of Chirurgiae) programs. The exam is conducted by the National Board of Examinations (NBE).
                            </p>
                        </div>

                        <div>
                            <p>
                                The NEET-SS exam is for candidates who hold a postgraduate degree in medicine (MD/MS) and wish to pursue super-specialization in a particular field. The candidates must have completed or be completing their postgraduate degree (MD/MS) in the same academic year.
                            </p>
                        </div>

                        <div>
                            <p>
                                The exam is conducted in a computer-based test format and consists of 200 multiple-choice questions. The questions are based on the syllabus of the respective super speciality course and the students are required to have a good understanding of the subject matter and the ability to apply their knowledge to solve problems. The test is conducted in a single session and the duration of the test is 3 hours and 30 minutes.
                            </p>
                        </div>

                        <div>

                            <h3>Eligibility Criteria</h3>
                            <p>
                                The National Board of Examinations (NBE) has prescribed the eligibility criteria for NEET SS. Candidates who wish to apply for the entrance test are advised to go through the NEET SS 2023 eligibility criteria before filling the application.
                                Candidate must possess a postgraduate degree (MD/MS/DNB/ Equivalent) from a recognised institution
                                Candidates who complete their postgraduate degree on or before September 14, 2023, will be eligible for NEET SS 2023
                                He/ she must be registered with the Medical Council of India or State Medical Council

                            </p>

                            <div>
                                <div>
                                    <center>
                                        <h5>
                                            <b>
                                                MD/DNB General Medicine
                                            </b>
                                        </h5>
                                    </center>


                                    <div class="columns-3" style={{ marginTop: "15px", marginBottom: "15px" }}>
                                        <p>Cardiology</p>
                                        <p>Neurology</p>
                                        <p>Nephrology</p>
                                        <p>Medical Oncology</p>
                                        <p>Medical Gastroenterology</p>
                                        <p>Endocrinology</p>
                                        <p>Clinical Immunology & Rheumatology</p>
                                        <p>Critical Care Medicine</p>
                                        <p>Hepatology</p>
                                        <p>Clinical Haematology</p>
                                        <p>Infectious Diseases</p>
                                        <p>Medical Genetics</p>
                                    </div>


                                </div>

                                <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                                    <Alert variant="primary">
                                        <b>Never give free consultations to your patients</b>
                                    </Alert>
                                </div>

                                <div>

                                    <h5><b>MS/DNB General Surgery</b></h5>

                                    <div class="columns-3">
                                        <p style={{ marginTop: "-5px" }}>Cardiovascular & Thoracic Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Neurosurgery</p>
                                        <p style={{ marginTop: "-5px" }}>Plastic & Reconstructive Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Paediatric Cardiothoracic Vascular Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Paediatric Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Surgical Gastroenterology</p>
                                        <p style={{ marginTop: "-5px" }}>Vascular Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Urology</p>
                                        <p style={{ marginTop: "-5px" }}>Surgical Oncology</p>
                                        <p style={{ marginTop: "-5px" }}>Endocrine Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Thoracic Surgery</p>
                                        <p style={{ marginTop: "-5px" }}>Hepato Pancreato Biliary Surgery</p>
                                    </div>

                                </div>


                                <div>

                                    <h5><b>MD/DNB Pediatrics</b></h5>

                                    <div class="columns-3">
                                        <p>Neonatology</p>
                                        <p>Paediatric Hepatology</p>
                                        <p>Paediatric Nephrology</p>
                                        <p>Paediatric Oncology</p>
                                        <p>Paediatric Neurology</p>
                                        <p>Paediatric Cardiology</p>
                                        <p>Paediatric Gastroenterology</p>
                                        <p>Paediatric Critical Care </p>
                                    </div>

                                </div>

                                <div>
                                    <h5><b>MD/MS/DNB Obstetrics & Gynaecology</b></h5>

                                    <div class="columns-2">
                                        <p>Gynaecological Oncology</p>
                                        <p>Reproductive Medicine & Surgery </p>
                                    </div>

                                </div>

                                <div>
                                    <h5><b>MS/DNB Orthopaedics </b></h5>

                                    <div class="columns-2">
                                        <p> Hand Surgery </p>
                                        <p>Paediatric Orthopaedics</p>
                                    </div>

                                </div>


                                <div>
                                    <h5><b>MD/DNB Anesthesiology</b></h5>

                                    <div class="columns-2">
                                        <p>Cardiac Anaesthesia</p>
                                        <p>Neuroanesthesia</p>
                                        <p>Organ Transplant Anaesthesia & Critical Care</p>
                                        <p>Paediatric & Neonatal Anaesthesia</p>
                                    </div>

                                </div>


                                <div>

                                    <h5><b>MD/DNB Radiology</b></h5>

                                    <div class="columns-2">
                                        <p>Neuro Radiology</p>
                                        <p>Interventional Radiology</p>
                                    </div>


                                </div>

                                <div>
                                    <h5><b>MD/DNB Respiratory Medicine</b></h5>

                                    <p>Pulmonary Medicine</p>
                                </div>

                                <div>
                                    <h5><b>MD/DNB Microbiology</b></h5>

                                    <p>Virology</p>
                                </div>

                                <div>

                                    <h5><b>MD/DNB Pathology</b></h5>

                                    <p>Onco-Pathology</p>
                                </div>

                                <div>

                                    <h5><b>MD/DNB Psychiatry</b></h5>

                                    <div class="columns-2">
                                        <p>Geriatric Mental Health</p>
                                        <p>Child and Adolescent Psychiatry</p>
                                    </div>

                                </div>

                                <div>

                                    <h5><b>MD/DNB Pharmacology</b></h5>

                                    <p>Clinical Pharmacology</p>

                                </div>

                                <div>

                                    <h5><b>MS/DNB ENT</b></h5>

                                    <p>Head & Neck Surgery</p>
                                </div>
                            </div>

                        </div>



                        <div style={{ marginLeft: "1%", marginRight: "1%" }}>
                            <Alert variant="primary">
                                <b>Never give free consultations to your patients</b>
                            </Alert>
                        </div>


                    </div>
                </div>

                <div class="flex-initial " style={{ marginRight: "35px" }}>
                    <div style={{ width: "100%", height: "700px" }}><Rightside /></div>
                </div>
            </div>

            
            <div><FooterNext /></div>
        </div>
    )
}