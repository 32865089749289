import React, { useState, useContext } from "react";
import "./slideshow_school_corporate.css";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  Link,
} from "react-router-dom";

import { Button } from "react-bootstrap";
export default function ShowImagesHomepage({
  slideshow_image,
  onClickfunction,
}) {
  
  return (
    <div>
        <img
          style={{ height: "auto", width: "auto" }}
          src={slideshow_image}
        />
    </div>
  );
}
