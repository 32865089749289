import React, { useState } from "react";
import { firebase, cheq, db, database } from "../../firebaseConfig";
import { BrowserRouter as Link } from "react-router-dom";
import { useAuthState } from 'react-firebase-hooks/auth';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { Button } from "react-bootstrap";
import Login from "../../Login";
import FooterNew from "../../Footers/Footer";
import ClientDetails from "../components/clientDetails";
import Student from "../components/student";
import TnC from "../components/tnc";

import dayjs from "dayjs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Checkbox from '@mui/material/Checkbox';
import FooterNext from "../../Footers/FooterNext";

export default function StudentHealthCheckup() {
  // Inputs

  const [user] = useAuthState(cheq);

  const [todayDate, setTodayDate] = useState(new Date());
  const [reqDate, setReqDate] = useState(new Date());


  const booleanEyeCheckup = { inputProps: { 'aria-label': 'eye checkup' } };
  const booleanDentalCheckup = { inputProps: { 'aria-label': 'dental checkup' } };

  const [ec, setEC] = useState("0");
  const [dc, setDC] = useState("0");

  const checkEyeCheckup = () => {
    setEC(1-ec);
  }

  const checkDentalCheckup = () => {
    setDC(1-dc);
  }

  const [cityName, setCityName] = useState("");
  const [headCountChildren, setHeadCountChildren] = useState("");
  const [headCountBoys, setHeadCountBoys] = useState("");
  const [headCountGirls, setHeadCountGirls] = useState("");
  const [budgetPP, setBudgetPP] = useState("");

  const [otherInfo, setOtherInfo] = useState("");

  const [orgName, setOrgName] = useState("");
  const [orgRepName, setOrgRepName] = useState("");
  const [orgRepDesignation, setRepDesignation] = useState("");
  const [orgRepOfficialEmail, setOrgRepOfficialEmail] = useState("");
  const [orgCompleteAddress, setOrgCompleteAddress] = useState("");

  const cityOptions = [
    { label: 'Bengaluru' },
    { label: 'Delhi' },
    { label: 'Hyderabad' },
    { label: 'Mumbai' },
    { label: 'Pune' },
  ];

  const headCountOptions = [
    { label: 'less than 50' },
    { label: '50-100' },
    { label: '100-500' },
    { label: '500-1000' },
    { label: '1000-5000' },
    { label: 'more than 5000' },
  ];

  const budgetOptions = [
    { label: 'INR 200 - INR 500 per person' },
    { label: 'INR 500 - INR 1000 per person' },
    { label: 'INR 1000 - INR 1500 per person' },
    { label: 'INR 1500 - INR 2000 per person' },
    { label: 'INR 2000 - INR 2500 per person' },
    { label: 'above INR 2500 per person' },
  ];


  function postRequest() {

    if(cityName === ""){
      alert("Please enter City");
      return;
    }

    if( headCountChildren === "" || headCountBoys === "" || headCountGirls === ""){
      alert("Please enter Head Count (Approx.)");
      return;
    }

    if(budgetPP === ""){
      alert("Please enter budget");
      return;
    }

    if(orgName === "" || orgRepName === "" || orgRepDesignation === "" || orgRepOfficialEmail === "" ||
    orgCompleteAddress === ""){
      alert("Please enter Organization Details");
      return;
    }

    db.collection("school-safeplan").add({

      posterPhone: cheq.currentUser.phoneNumber,
      city: cityName,
      
      headCountChildren: headCountChildren,
      headCountBoys: headCountBoys,
      headCountGirls: headCountGirls,

      budgetPP: budgetPP,

      booleanEyeCheckup: ec,
      booleanDentalCheckup: dc,


      otherInfo: otherInfo,
      orgName: orgName,
      orgRepName: orgRepName,
      orgRepDesignation: orgRepDesignation,
      orgRepOfficialEmail: orgRepOfficialEmail,
      orgCompleteAddress: orgCompleteAddress,

      todayDate:todayDate, 
      requestDate: dayjs(reqDate).format('LL'),
      Time: firebase.firestore.Timestamp.now().toDate().toString(),
      
    });

    database.ref("onlinerequest/safeplan/school").push({

      PosterPhone: cheq.currentUser.phoneNumber,
      city: cityName,

      headCountChildren: headCountChildren,
      headCountBoys: headCountBoys,
      headCountGirls: headCountGirls,

      budgetPP: budgetPP,

      booleanEyeCheckup: ec,
      booleanDentalCheckup: dc,

      otherInfo: otherInfo,
      orgName: orgName,
      orgRepName: orgRepName,
      orgRepDesignation: orgRepDesignation,
      orgRepOfficialEmail: orgRepOfficialEmail,
      orgCompleteAddress: orgCompleteAddress,

      todayDate:todayDate, 
      requestDate: dayjs(reqDate).format('LL'),
      Time: firebase.firestore.Timestamp.now().toDate().toString(),

    }).catch(alert);

    /*alert("dt - " + dt + "\n" +
    "ec - " + ec + "\n" + 
    "dc - " + dc );*/

    window.location.replace('https://rzp.io/l/drplanete-student-hlth-chkp');
   
    //alert("Request Placed Successfully!!")
    /*alert("city Name - " + cityName + "\n" + headCount + "\n" + budgetPP + "\n" +
    orgName + "\n" + orgRepName + "\n" + orgRepDesignation + "\n" + orgRepOfficialEmail + "\n" + 
    orgCompleteAddress + "\n" + dayjs(todayDate).format('L') + "\n" + dayjs(reqDate).format('LL'));

    */
  }
  return (
    user ? <><div>
      <div><img
        className="w-full h-[440px] object-cover"
        style={{ width: "1600px", height: "300px" }}
        src="https://firebasestorage.googleapis.com/v0/b/admin-5d27b.appspot.com/o/safeplan%2Fweb-heads%2Fweb%20-%20shcp%20%20(1).png?alt=media&token=66f2250b-bfc1-4dde-bd02-3d043c793323"
        alt=""
      /></div>
      
      <div>
            <div class="container body-content " style={{ marginTop: "35px", marginBottom: "35px" }}>


                <div class="container body-content " style={{ marginBottom: "45px" }}>
                    <center><Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={cityOptions}
                        sx={{ width: 500 }}
                        onChange={(event, value) => setCityName(value.label)}
                        renderInput={(params) => <TextField {...params} label="Select City" />}
                    /></center>
                </div>

                <div class="border-1 border-indigo-600" style={{ marginBottom: "35px" }}>
                    <center>
                        <p>For Students below 11 years</p>
                        <div>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={headCountOptions}
                                sx={{ width: 400 }}
                                onChange={(event, value) => setHeadCountChildren(value.label)}
                                renderInput={(params) => <TextField {...params} label="Head Count Children" />}
                            />
                        </div>
                    </center>
                </div>

                <div class="border-1 border-indigo-600" style={{ marginTop: "19px", marginBottom: "15px" }}>
                    <div >
                        <div><center><p>For Students above 11 years</p></center></div>
                        <div
                            style={{ marginTop: "15px", marginLeft: "35px" }}
                        >
                            <div class="columns-2">
                                <center>
                                    <div style={{ marginTop: "15px" }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={headCountOptions}
                                            sx={{ width: 400 }}
                                            onChange={(event, value) => setHeadCountBoys(value.label)}
                                            renderInput={(params) => <TextField {...params} label="Head Count (Boys)" />}
                                        />
                                    </div>

                                    <div style={{ marginTop: "15px" }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={headCountOptions}
                                            sx={{ width: 400 }}
                                            onChange={(event, value) => setHeadCountGirls(value.label)}
                                            renderInput={(params) => <TextField {...params} label="Head Count (Girls)" />}
                                        />
                                    </div>
                                </center>
                            </div>

                            <div style={{ marginTop: "15px" }}>
                                <center>
                                    <div><Checkbox {...booleanEyeCheckup} onClick={checkEyeCheckup}/>Include Eye checkup</div>
                                    <div><Checkbox {...booleanDentalCheckup} onClick={checkDentalCheckup}/>Include Dental checkup</div>
                                </center>
                            </div>


                            <div class="columns-1" style={{ marginTop: "15px" }}>
                                <center>
                                    <div style={{ marginBottom: "15px" }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={budgetOptions}
                                            sx={{ width: 700 }}
                                            onChange={(event, value) => setBudgetPP(value.label)}
                                            renderInput={(params) => <TextField {...params} label="Approx Budget per Student" />}
                                        />
                                    </div>

                                    <div style={{ marginTop: "15px" }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <DatePicker
                                                renderInput={(props) => <TextField {...props} />}
                                                label="Date"
                                                style={{ width: "1000px" }}
                                                value={reqDate}
                                                onChange={(newValue) => {
                                                    setReqDate(newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <p>You selected {dayjs(reqDate).format('LL')}</p>
                                </center>
                            </div>

                            <div class="container" style={{ marginTop: "15px", marginBottom: "15px", marginRight:"145px" }}>
                    <TextField id="outlined-basic"
                        multiline fullWidth
                        maxRows={4}
                        label="Any other info" variant="outlined" onChange={(e) => setOtherInfo(e.target.value)}/>
                </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
      <div >

        <div class="container body-content border-1 border-indigo-600" style={{ marginTop: "15px", marginBottom: "35px" }}>
          <div style={{ marginTop: "35px", marginBottom: "15px", width: "450px" }}>
            <TextField id="outlined-basic" fullWidth label="Organization Name" variant="outlined"  onChange={(e) => setOrgName(e.target.value)}/>
          </div>
          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic" fullWidth label="Organization Representative Name" variant="outlined" onChange={(e) => setOrgRepName(e.target.value)} />
          </div>
          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic" fullWidth label="Organization Representative Designation" variant="outlined" onChange={(e) => setRepDesignation(e.target.value)}/>
          </div>

          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic" fullWidth label="Representative Official Email ID" variant="outlined" onChange={(e) => setOrgRepOfficialEmail(e.target.value)}/>
          </div>
          <div style={{ marginTop: "35px", marginBottom: "15px" }}>
            <TextField id="outlined-basic"
              multiline fullWidth
              maxRows={4}
              label="Organization Address" variant="outlined" onChange={(e) => setOrgCompleteAddress(e.target.value)}/>
          </div>

        </div>
      </div>
      <div>
        <div class="container body-content border-1 border-indigo-600">
          <div>
            <div><center><h3>Note</h3></center></div>
            <div><p class="text-sm ...">1. Dr Planete is merely providing an Interface Platform to the client wherein the client can post/request to post his lawful requirements as eligible on the platform and seek the response from the prospective Responding Entity on this platform.</p></div>
            <div><p class="text-sm ...">2. Dr Planete shall not have any privity with respect to any terms and conditions or any agreements etc. or with respect to any transactions, or with respect to any interactions, etc. which may be entered into by the client and the Responding Entity once they establish contact through the platform.</p></div>
            <div><p class="text-sm ...">3. Dr Planete does not give any guarantee to the client with regard to the satisfaction of his requirements. Merely, posting of the requirements by the client shall not be presumed or taken as a guarantee to meet and satisfy the requirements of the client. The client understands and agrees that this Service Interface Platform is merely an interface between the client and the Responding Entity.</p></div>
            <div class="text-sm ..."><p>4. All charges are inclusive of all taxes applicable and are non-refundable.
            </p>
            </div>
            <div><p class="text-sm ...">5. Dr Planete shall not be responsible for any future dispute with regard to your competence and authorization by your Organization.
            </p>
            </div>
            <div><p class="text-sm ...">6. You and your Organization shall be duly responsible for all the consequences that may arise out of the use of this Service Interface Platform. It’s the responsibility of the client to keep updated and comply with the terms and conditions of the interface platform.
            </p>
            </div>

          </div>

          <div>
            <center>
              <p>By continuing, I agree to all Terms & Conditions</p>
            </center>
          </div>

          <div style={{ marginTop: "25px", marginBottom: "25px" }}>
            <center>
              <Button variant="primary" size="lg" onClick={postRequest}>Post Request</Button>
            </center>
          </div>
        </div>
      </div>
      {/*<div><ClientDetails /></div>
      <div><TnC/></div>*/}
      {/*<div style={{ height: "auto", marginTop: "5px", marginBottom: "15px" }}>
        <center>
          <Button variant="primary" size="lg">Post Request</Button>
        </center>
  </div>*/}
      <div><FooterNext /></div>
    </div></> : <Login/>
  );
}
