import React from "react";
import FooterNext from "../Footers/FooterNext";


export default function GroPlusTnc({ }) {

    return (
        <div>
            <div><h1>Grow Plus Terms & Conditions</h1></div>

            <div>

                <h4>Services & Support</h4>
                <p>1.1 This is an agreement between Sungowin Technologies Private Limited, a company incorporated under the laws of India and owner &
                    operator of the Software as a Service (SaaS) product Gro Plus ("Company" or "We") and you, any natural or legal person or organization
                    ("Customer" or "You"), who has agreed to be a user of the service.

                    1.2 Subject to the terms of this Agreement, Company will use commercially reasonable efforts to provide Customer the Services
                    including the Features as mentioned at the time of subsciption to the service

                    1.3 Subject to the terms hereof, Company will provide Customer with reasonable technical support services in
                    accordance with Company's standard IncreasePractice
                </p>
            </div>

            <div>
                <h4>Confidentially & Proprietary Rights</h4>
                <p>2.1 Each party (the "Receiving Party") understands the the other party (the "Disclosing Party") has disclosed or may disclose business, technical or financial information
                    relating to the Disclosing Party's business(hereinafter referred to as "Proprietary Information" of the Disclosing Party).

                    2.2 Proprietary Information of Customer includes non-public data provided by the Customer to Company to enable the provision of Services.

                    2.3 Proprietary Information of Company includes non-public information regarding features, functionality and performance of the Service.


                    2.4 Company shall own and retain all rights, title and interest in and to (a) the services and Software, all improvements,
                    enhancements or modifications thereto, (b) any software, applications, inventions or other technology developed in connection with Services
                    or support, and (c) all intellectual property rights related to any of the foregoing.

                    2.5 The Receiving Party agrees: (a) to take reasonable precautions to protect such Proprietary Information, and
                    (b) not to use or divulge to any third person such such Proprietary Information

                    2.6 The Disclosing Party agrees that foregoing shall not apply with respect to any information that Receiving party can document (a) is or
                    become generally available to the public, or (b) was in its possession or known by it prior to receipt from the Disclosing Party, or
                    (c) was rightfully disclosed to it without restriction by a third party, or (d) was independentaly developed without the use of any Proprietary
                    Information of the Disclosing Party or (e) is required to be disclosed by law.

                    2.7 Notwithstanding anything to the contrary, Company shall have the right to collect and analyze data and other information relating to provision, use
                    and performance of various aspects of the Services and related systems and technologies(including information concerning Customer Data and data derived therefrom),
                    and Company will be free (during and after the term hereof) to (i) use such information and data to improve and enhance the Services and for other development, diagnostic
                    and corrective purposes in connection with the Services and other Company Offerings, and (ii) disclose such data solely in aggregate or other de-identified form in connection
                    with its business. No rights or licenses are granted except as expressly set forth herein.

                </p>
            </div>

            <div>

                <h4>Restrictions and Responsiblities</h4>
                <p> 3.1 Customer will not, directly or indirectly: reverse engineer, decompile, disassemble, or otherwise attempt
                    to discover the source code, object code or underlying structure, ideas, know-how or algorithms relevant to the Services
                    or any software, documentation or data related to the Services ("Software"); modify, translate, or create derivative works based on the Services or
                    any Software; use the Services or any Software for timesharing or service bureau purposes or otherwise for the benefit of a third; or remove any
                    proprietary notices or labels. With respect to any Software that is distributed or provided to Customer, Company hereby grants Customer a non-exclusive,
                    non-transferable, non-sublicenseable license to use such Software during the Term only in connection with the Services.

                    3.2 Customer represents, convenants, and warrants that Customer will use the Services only in compliance with Company's standard published policies in effect.
                    Customer hereby agrees to indemnify and hold harmless Company against any damages, losses, liabilities, settlements and expenses (including without
                    limitation costs and attorneys's fees) in connection with any claim or action that arises from an alleged violation of the foregoing or otherwise from
                    Customer's use of Services. Although Company has no obligation to monitor Customer's use of the Services, Company may do so and may prohibit any use of the services it
                    believes may be (or alleged to be) in violation of the foregoing.

                    3.3 Customer shall be responsible for obtaining and maintaining any equipment and ancillary services needed to connect to, access or otherwise use the Services, including, without limitation, modems, hardware, servers, software, operating systems, networking, web servers and the like (collectively, “Equipment”). Customer shall also be responsible for maintaining the security of the Equipment, Customer account, passwords (including but not limited to administrative and user passwords) and files, and for all uses of Customer account or the Equipment with or without Customer’s knowledge or consent.
                </p>
            </div>

            <div>
                <h4>Payment of Fees</h4>

                <p>
                    4.1 Customer will pay Company the then applicable fees described in the Order Form for the Services in accordance with the terms therein (the “Fees”) as per the schedule provided in exhibit C. In case of failure to payments per the schedule, Company reserves the right to terminate Services at will and deploy additional recourses for recovery of payments.
                    4.2 All Payments have to be made through the Company's Official Website (www.drplanete.com) or Mobile App available on Google Play Store
                    4.3 If Customer’s use of the Services exceeds the Service Capacity set forth on the Order Form or otherwise requires the payment of additional fees (per the terms of this Agreement), Customer shall be billed for such usage and Customer agrees to pay the additional fees in the manner provided herein.
                    4.4 All charges are inclusive of all taxes and are non-refundable.
                </p>
            </div>

            <div>

                <h4>Term & Termination</h4>

                <p>5.1 Subject to earlier termination as provided below, this Agreement is for the Initial Service Term as specified in the Order Form, unless either party requests termination at least thirty (30) days prior to the end of the then-current term.

                    5.2 In addition to any other remedies it may have, either party may also terminate this Agreement upon thirty (30) days’ notice (or without notice in the case of nonpayment by Customer), if the other party materially breaches any of the terms or conditions of this Agreement. Upon termination notice in any circumstances, Customer must pay within thirty (30) days, in full for the Services up to and including the last day on which the Services are provided.

                    5.3 Upon any termination, subject to Customer completing payment in full for the Services up to and including the last day on which the Services are provided, Company will make all Customer Data available to Customer for electronic retrieval for a period of thirty (30) days, but thereafter Company may, but is not obligated to, delete stored Customer Data.] All sections of this Agreement which by their nature should survive termination will survive termination, including, without limitation, accrued rights to payment, confidentiality obligations, warranty disclaimers, and limitations of liability.
                </p>
            </div>

            <div>
                <h4>Warranty and Disclaimer</h4>

                <p>6.1  Services may be temporarily unavailable for scheduled maintenance or for unscheduled emergency maintenance, either by Company or by third-party providers, or because of other causes beyond Company’s reasonable control, company does not warrant that the services will be uninterrupted or error free; nor does it make any warranty as to the results that may be obtained from use of the services. Except as expressly set forth in this section, the services are provided “as is” and company disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose and non-infringement.

                </p>
            </div>


            <div>

                <h4>Indemnity</h4>

                <p>Customer shall defend, indemnify & hold harmless Company, its officers, directors and employees against claims, actions, proceedings, losses, damages, expenses and costs (
                    including without limitation court costs & legal fees) arising out of or in connection with Customer's use of Services, and shall indemnify Company for any amounts awarded against Company in judgement or settlement of such outcomes.
                    
                    In no event shall the Company, its employees, agents and sub-contractors be liable to Customer or any other party, to any extend</p>
            </div>

            <div>
                <h4> Limitation of Liability</h4>

                <p>Not Withstanding anything contrary, company, its officers, affiliates, representatives, contractors and employees shall not be responsible or liable with respect to any matter of this agreement or terms and conditions related thereto under any contract, negligence, strict liability or other theory 
                    (a) fo error or interruption of use or for loss or inaccuracy or corruption of data or cost of procurement of subsitute goods, services or technology or loss of business;
                    (b) for any indirect, examplary, incidental, special or consequential damages;
                    (c) for any matter whatsoever  
                </p>

                this agreement is subject to the jurisdiction of the Courts situated at Jaipur only. If any dispute with regard to the condition of this agreement is raised, the parties shall endeavor to settle it amicably amongst themselves mutually and litigation shall be the last resort.
            </div>

            <div style={{marginTop:"35px"}}>
                <FooterNext/>
            </div>
        </div>
    );
}