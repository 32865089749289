import React, { useState, useContext } from "react";
import { storage, db } from "../firebaseConfig";
import { UserContext } from "../user";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
    Link,
} from "react-router-dom";



import ShowColumn from "../components/showColumn";
import { Button } from "react-bootstrap";
import FooterNew from "../Footers/Footer";
export default function Offers({

}) {


    const [postsX, setPostsX] = useState([]);
    db.collection("slideshowschool").onSnapshot((snapshot) => {
        setPostsX(snapshot.docs.map((doc) => ({ id: doc.id, postsX: doc.data() })));
    });

    return (
        <div>
            <h1 class="center">Offers</h1>
            <div>
                <div class="container body-content">
                    <div className="columns-2">
                        {postsX.map(({ id, postsX }) => {
                            return (
                                <ShowColumn
                                    key={id}
                                    slideshow_image={postsX.slideshow_image}
                                    onClickfunction={postsX.onClickfunction}
                                />
                            );
                        })}
                    </div>

                </div>

            </div>
            <FooterNew />
        </div>
    );
}
