import React, {useState} from "react";
import "./NavNext.css";

import {BrowserRouter as Router, Link, Route, Switch,  } from 'react-router-dom';
import Home from "../../Home";
import Login from "../../Login";
import Doctor from "../../doctor/Doctor";
import MedicalStudent from "../../medicalstudent/MedicalStudent";
import Hospital from "../../hospital/Hospital";
import Safeplan from "../../school_corporate/safeplan";
import DoctorRegistrationBasicDetails from "../../doctor/registration/basicDetails";
import DoctorRegistrationSpecialization from "../../doctor/registration/specializationDetails";
import DoctorRegistrationSuperSpecialization from "../../doctor/registration/superSpecializationDetails";
import TermsAndConditions from "../../termsandconditions/termsandconditions";
import GroPlusTnc from "../../termsandconditions/groplusterms";
import PrivacyPolicy from "../../termsandconditions/privacypolicy";
import Contact from "../../company/Contact";
import SchoolCorporateLogin from "../../Login/schoolcorporate_login";
import DoctorHospitalLogin from "../../Login/doctorhospital_login";
import MedicalStudentLogin from "../../Login/medicalstudent_login";
import Offers from "../../medicalstudent/Offers";
import AffiliateRegistration from "../../medicalstudent/affiliateRegistration";
import CampusAmbassadorProgram from "../../medicalstudent/campusAmbassadorProgram";
import ThirtyDayQuestion from "../../medicalstudent/thirtydayquestion";
import ExamAlerts from "../../medicalstudent/examalerts";
import Vaccination from "../../school_corporate/plans/Vaccination";
import EducationSession from "../../school_corporate/plans/EducationSession";
import RequestVisitingDoctor from "../../school_corporate/plans/RequestVisitingDoctor";
import DentalCheckup from "../../school_corporate/plans/DentalCheckup";
import StudentHealthCheckup from "../../school_corporate/plans/StudentHealthCheckup";
import EmployeeHealthCheckup from "../../school_corporate/plans/EmployeeHealthCheckup";
import EyeCheckup from "../../school_corporate/plans/EyeCheckup";
import TrainingCentre from "../../hospital/registration/trainingCentre";
import CourseDetails from "../../hospital/registration/courseDetails";
import Observership from "../../hospital/registration/observership";
import ViewCourses from "../../hospital/viewCourses";
import ViewObservershipPlans from "../../hospital/viewObservershipPlans";
import IncreasePractice from "../../doctor/content/increasePractice";
import AboutMRCP from "../../doctor/content/aboutMRCP";
import AboutMRCS from "../../doctor/content/aboutMRCS";
import AboutFMGE from "../../doctor/content/aboutFMGE";
import AboutNEETPG from "../../doctor/content/aboutNEETPG";
import AboutNEETSS from "../../doctor/content/aboutNEETSS";
import AboutUSMLE from "../../doctor/content/aboutUSMLE";
import GrowPlus from "../../products/growplus";
import GrowPlusPricing from "../../products/pricing";
import WomensDay2023 from "../../content/womensday2023";
import CreatorRegistration from "../../medicalstudent/createrRegistration";
import CCA_Confirmation from "../../medicalstudent/cca_confirmation";
import EducatorRegistration from "../../doctor/educators/educatorRegistration";
import EducatorDetails from "../../doctor/educators/educatorDetails";
import DashboardDoctor from "../../doctor/dashboardDoctor";
import EducationalCourseDetails from "../../doctor/educators/addEducationalCourse";
import HospitalRegistration from "../../hospital/HospitalRegistration";
import CheckIfHospitalMember from "../../hospital/registration/checkIfHospitalMember";
import CheckIfHospitalObs from "../../hospital/registration/checkIfHospObs";
import CheckIfHospitalTraining from "../../hospital/registration/checkIfHospTraining";
import Careers from "../../company/Careers";
import ApplyAdditionalQualification from "../../blog/informative articles/applicationForRegistration/apply_additional_qualification";
import RajasthanMedicalCouncilRegistration from "../../blog/informative articles/applicationForRegistration/rajasthan/RajasthanMedicalCouncilRegistration";
import RajasthanMedicalCouncilDetails from "../../blog/informative articles/applicationForRegistration/rajasthan/MedicalCouncilRajasthan";
import MadhyaPradeshMedicalCouncilRegistration from "../../blog/informative articles/applicationForRegistration/madhya pradesh/MadhyaPradeshMedicalCouncilRegistration";
import ClubContent from "../../club/clubcontent";


export default function NavNext() {

    const [isMobile, setIsMobile] = useState(false);
    return (
        <Router>
        <nav className="navbar">
        <img style={{height:"75px"}} src="/logo.png" />
        {/*<h2 className="logo">Dr Planete</h2>*/}
            <ul className={isMobile ? "nav-links-mobile" : "nav-links"}
            onClick={() => setIsMobile(false)} style={{backgroundColor:"#FFFFFF"}}>
                <Link to="/safeplan" className="school_corporate">
                    <li style={{fontFamily: "serif", fontWeight:"bold", color:"black"}}>School/Corporate</li>
                </Link>
                <Link to="/medicalstudent" className="medical_student">
                    <li style={{fontFamily: "serif", fontWeight:"bold", color:"black"}}>Medical Student</li>
                </Link>
                <Link to="/doctor" className="doctor">
                    <li style={{fontFamily: "serif", fontWeight:"bold", color:"black"}}>Doctor</li>
                </Link>
                <Link to="/hospital" className="hospital" style={{marginRight:"150px"}}>
                    <li style={{fontFamily: "serif", fontWeight:"bold", color:"black"}}>Hospital</li>
                </Link>
                

            </ul>



            <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)} >
                {isMobile ? <i style={{color:"blue"}} className="fas fa-times"></i> : <i style={{color:"blue"}} className="fas fa-bars"></i>}
            </button>
    </nav>







        <div>
          <Switch>
            <Route exact path="/">
              <ClubContent/>
            </Route>
            <Route path="/login">
              <Login/>
            </Route>
            <Route path="/doctor">
              <Doctor/>
            </Route>
            <Route path="/medicalstudent">
              <MedicalStudent/>
            </Route>
            <Route path="/careers">
              <Careers/>
            </Route>
            <Route path="/social">
              <WomensDay2023/>
            </Route>
            <Route path="/hospital">
              <Hospital/>
            </Route>
            <Route path="/hospital-membership">
              <CheckIfHospitalMember/>
            </Route>
            <Route path="/safeplan">
              <Safeplan />
            </Route>
            <Route path="/school">
              <Safeplan/>
            </Route>
            <Route path="/corporate">
              <Safeplan/>
            </Route>
            <Route path="/doctor-membership">
              <DoctorRegistrationBasicDetails/>
            </Route>
            <Route path="/drplanete-dashboard">
              <DashboardDoctor/>
            </Route>

            <Route path="/doctorreg-specialization">
              <DoctorRegistrationSpecialization/>
            </Route>
            <Route path="/doctorreg-super-specialization">
              <DoctorRegistrationSuperSpecialization/>
            </Route>
            <Route path="/termsandconditions">
              <TermsAndConditions />
            </Route>
            <Route path="/gro-plus-termsandconditions">
              <GroPlusTnc/>
            </Route>
            <Route path="/privacypolicy">
              <PrivacyPolicy/>
            </Route>
            <Route path="/contact">
              <Contact/>
            </Route>
            <Route path="/login-school-corporate">
              <Login/>
            </Route>
            <Route path="/login-doctor-hospital">
            <Login/>
            </Route>
            <Route path="/login-medicalstudent">
              <Login/>
            </Route>
            <Route path="/offers">
              <Offers/>
            </Route>
            <Route path="/affiliate-connect">
              < AffiliateRegistration/>
            </Route>
            <Route path="/campus-ambassador-program">
              <CampusAmbassadorProgram/>
            </Route>
            <Route path="/creator-connect">
              <CreatorRegistration/>
            </Route>
            <Route path="/application-confirmation">
              <CCA_Confirmation/>
            </Route>
            <Route path="/30days-30questions-30prizes">
              <ThirtyDayQuestion/>
            </Route>
            <Route path="/exam-alerts">
              < ExamAlerts/>
            </Route>
            <Route path="/vaccination-plans">
              < Vaccination/>
            </Route>
            <Route path="/awareness-session">
              < EducationSession/>
            </Route>
            <Route path="/request-visiting-doctor">
              < RequestVisitingDoctor/>
            </Route>
            <Route path="/dental-checkup-plans">
              < DentalCheckup/>
            </Route>
            <Route path="/student-health-checkups-plans">
              < StudentHealthCheckup/>
            </Route>
            <Route path="/employee-health-checkup-plans">
              < EmployeeHealthCheckup/>
            </Route>
            <Route path="/eye-checkup-plans">
              < EyeCheckup/>
            </Route>
            
            <Route path="/training-centre-enrollment">
              <TrainingCentre/>
            </Route>
            <Route path="/educator-registration">
              <EducatorRegistration/>
            </Route>
            <Route path="/educator-details">
              <EducatorDetails/>
            </Route>
            <Route path="/add-educational-course">
              <EducationalCourseDetails/>
            </Route>
            <Route path="/list-educational-course">
              <EducationalCourseDetails/>
            </Route>
            <Route path="/list-training-course">
              <CheckIfHospitalTraining/>
            </Route>
            <Route path="/list-observership-plan">
              <CheckIfHospitalObs/>
            </Route>
            <Route path="/my-courses">
              <ViewCourses/>
            </Route>
            <Route path="/my-observership-plans">
              <ViewObservershipPlans/>
            </Route>
            <Route path="/increase-practice-doctors">
              <IncreasePractice/>
            </Route>
            <Route path="/about-mrcp">
              <AboutMRCP/>
            </Route>
            <Route path="/about-mrcs">
              <AboutMRCS/>
            </Route>
            <Route path="/about-fmge">
              <AboutFMGE/>
            </Route>
            <Route path="/about-neetpg">
              <AboutNEETPG/>
            </Route>
            <Route path="/about-usmle">
              <AboutUSMLE/>
            </Route>
            <Route path="/about-neetss">
              <AboutNEETSS/>
            </Route>
            <Route path="/gro-plus-plans">
              <GrowPlus/>
            </Route>
            <Route path="/pricing">
              <GrowPlusPricing/>
            </Route>
            <Route path="/how-to-apply-for-additional_qualification">
              <ApplyAdditionalQualification/>
            </Route>
            <Route path="/how-to-apply-for-permanent-registration-rajasthan">
              <RajasthanMedicalCouncilRegistration/>
            </Route>
            <Route path="/about-Rajasthan-Medical-Council">
              <RajasthanMedicalCouncilDetails/>
            </Route>
            <Route path="/how-to-apply-for-permanent-registration-madhya-pradesh">
              <MadhyaPradeshMedicalCouncilRegistration/>
            </Route>
          </Switch>
        </div>
        </Router>
    );
}