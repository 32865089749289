import React, { useState } from "react";
import firebase from "firebase";
import { cheq, storage, db, database } from "../firebaseConfig";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  CompanyHighlight,
} from "./FooterStyles";
import { TextField } from "@mui/material";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import InputAdornment from '@mui/material/InputAdornment';

export default function GetApp({
  page,
  date,
}) {
  const [name, setName] = useState("");
  const [pageRef, setPageRef] = useState("")

  const newsletter = () =>{
    
    database.ref("newsletter").child(`/${page}`).child(`/${date}`).push({
      name : name,
      Time: firebase.firestore.Timestamp.now().toDate().toString(),
    }).catch(alert);
    alert("done!!");
  };


  const playStoreCliq = () => {
    database.ref("app").child("android").child(`/${page}`).child(`/${date}`).push({
      name : name,
      Time: firebase.firestore.Timestamp.now().toDate().toString(),
    }).catch(alert);
  }

  const appStoreCliq = () => {
    database.ref("app").child("ios").child(`/${page}`).child(`/${date}`).push({
      name : name,
      Time: firebase.firestore.Timestamp.now().toDate().toString(),
    }).catch(alert);
  }

  return (
    <div>
      <Box
        style={{
          position: "relative",
          height: "auto",
          width:"auto",
          backgroundColor: "aqua",
        }}
      >
        <Container>
          <Row style={{ backgroundColor: "pink" }}>
            <Column style={{ width: "400px", backgroundColor: "pink" }}>
              <p style={{ textEmphasisColor: "pink", marginTop:"45px", fontFamily:"Cursive", marginLeft:"45px" }}>
                Subscribe to our newsletter
              </p>
              < ></>
              
            </Column>
            <Column
              style={{
                marginLeft: "125px",
                width: "400px",
                backgroundColor: "pink",
              }}
            >
              <Row style={{ backgroundColor: "pink" }}>
                <TextField
                  style={{
                    marginTop: "40px",
                    width: "200px",
                    height: "15px",
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+91</InputAdornment>,
                  }}
                  error={name.length === 0}
                  value={name}
                  size="small"
                  color="secondary"
                  label="Phone Number"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  focused
                />
                <Button
                  variant="success"
                  style={{ width: "75px", height: "40px", marginTop: "40px", marginLeft:"5px" }}
                  onClick={newsletter}
                >
                SEND
                </Button>
              </Row>

              {/*<p>Your Enter Value is: {name} </p>*/}
              {/*helperText={!name.length ? "name is required" : ""}*/}
            </Column>
            <Row style={{marginLeft: "525px",width:"280px", backgroundColor:"pink" }}>
              
              <p style={{fontFamily:"Cursive"}}>Experience Dr Planete Mobile App</p>
              
              </Row>
            <Column style={{marginTop:"25px", marginLeft: "300px", width: "150px", backgroundColor:"pink" }}>
              <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  class="google_play"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  onClick={playStoreCliq}
                />
              </a>
            </Column>
            {/*<Column style={{ marginTop:"25px", marginLeft: "300px", width: "150px",backgroundColor:"pink" }}>
              <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                <img
                  class="google_play"
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                />
              </a>
                </Column>*/}
            </Row>
        </Container>
      </Box>
    </div>
  );
}
