import { color } from "@mui/system";
import React from "react";
import FooterNext from "../Footers/FooterNext";
import "./womensday2023.css";


export default function WomensDay2023({ }) {


    return (
        <div>
            <div className="display-image">
                <center>
                    <img

                        src="https://firebasestorage.googleapis.com/v0/b/website-c857f.appspot.com/o/magzine%2Fcover%2Fins-iw.png?alt=media&token=d77ac863-9c58-48ae-a00c-8bef914d3cdd"
                    />
                </center>
            </div>

            <div style={{ marginTop: "25px" }}>
                <center>
                    <p>Here we go!!</p>
                </center>
            </div>

            <div className="set-together" style={{ marginTop: "25px" }}>

                <div className="show-feature-img">
                    <img

                        src="https://firebasestorage.googleapis.com/v0/b/social-display-af2ab.appspot.com/o/womens-day%2F1.png?alt=media&token=c51c5b58-4988-4f6b-b78c-041573c0c578"
                    />
                </div>

                <div className="show-feature-text">
                    <div><p><b>Featuring - Dr Pallavi A. Joshi Consultant Neuropsychiatrist</b></p></div>
                    <p>
                        Keep sharing, keep expressing !!</p>
                    <p>
                        Somethings happen in your life which are beyond your dreams !!

                        I still remember the day, when I opened my spam box and saw an email from the commissioning editor of Rupa Publications asking me to write a book on Urban Depression & my husband even joked this is a spam definitely🙂Why a prestigious publication like Rupa would reach out to me for writing the book? But a call from their commissioning editor confirmed this. They reached out to me after reading my article in Economic Times on depression in urban life.
                    </p>

                    <p style={{ fontStyle: "italic" }}>
                        <b>To read the complete story download Dr Planete Mobile App from Google PlayStore now!!</b>
                    </p>

                </div>
            </div>

            <div>
                <center>
                    <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            class="google_play"
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"

                        />
                    </a>
                </center>
            </div>

            <div className="set-together" style={{ marginTop: "25px" }}>

                <div className="show-feature-text">




                    <div><p><b>Featuring the inspiring story of Dr Pooja Gupta - Plastic and Reconstructive Surgeon</b></p></div>

                    <p>
                        Being the only single girl child of my parents, I had my childhood spent in a very protective environment, wherein I wasn't allowed to even go downstairs alone! My parents have always remained the main fuel of all my achievements so far especially my mother!
                    </p>

                    <p>
                        I wanted to become a doctor since a very small age, the exact reason I don't remember though, it was somehow in instilled in my mind, the noblest profession and being able to do the best for another human in highest form of practice!
                        I studied hard for UG entrance,and landed up in MBBS college far away from home. Never I had been away from home and now its been more 12 years staying away from home. The journey till now has not been an easy one.
                        After completing MBBS, the real struggle of stepping into a male dominated field started. 'Women are not meant for Surgery', this notion was still strong those times when I had to begin my post graduation.
                        I remember one of my senior advising me that I might be happier getting married and having kids instead of pursuing Surgery.

                    </p>

                    <p>
                        Stepping again into a male dominated field to become a Plastic Surgeon was again a big task to prove your worth! At every step, you had to prove, you as woman can do better or equal to your male counterparts, wherein you were always judged.
                        But actions always speak louder than words wherein you silently cease the tauntings and break the stereotyping by showing your skills.
                    </p>

                    <p style={{ fontStyle: "italic" }}>
                        <b>To read the complete story download Dr Planete Mobile App from Google PlayStore now!!</b>
                    </p>

                </div>

                <div className="show-feature-img">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/social-display-af2ab.appspot.com/o/womens-day%2F3.png?alt=media&token=7f84539d-4d2b-4b81-8531-f1cf89e0b5ce"
                    />
                </div>


            </div>


            <div>
                <center>
                    <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            class="google_play"
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"

                        />
                    </a>
                </center>
            </div>

            <div className="set-together" style={{ marginTop: "25px" }}>

                <div className="show-feature-img">
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/social-display-af2ab.appspot.com/o/womens-day%2F15.png?alt=media&token=7ead4b30-7a67-4501-90e2-90592e3aba3e"
                    />
                </div>

                <div className="show-feature-text">

                    <div><p><b>Featuring - Dr Saumya Srivastava - Critical Care Anesthesiologist</b></p></div>

                    <p>Growing up, i was an introverted child. I never really fit anywhere and with anyone, and i was on my own a lot, when you are alone you are instantly drawn towards the idea of pain and suffering and how to end it all, that is how I ended up being attracted towards the idea of being a doctor. Let me tell you it was not easy, i couldn't get into a government college because I was short by just a few marks, lucky for me I had an amazing set of parents, my father put all his savings to support my dream, that day I promised myself that I will make myself worthy of this title. The fees was high, i had to take an education loan, but i toiled every single day so that my parents can see that i recognised their contribution and sacrifice. It all became worth it when I started my PG at MOTI LAL NEHRU MEDICAL COLLEGE. It was so surreal, but nothing prepared me for what was in store for me. Residency is the toughest thing that I ever had to....</p>

                    <p style={{ fontStyle: "italic" }}>
                        <b>To read the complete story download Dr Planete Mobile App from Google PlayStore now!!</b>
                    </p>

                </div>
            </div>


            <div style={{ marginTop: "25px" }}>

                


                <center>

                <div style={{ height: "80%", width: "80%" }}>
                    
                    <img
                        src="https://firebasestorage.googleapis.com/v0/b/social-display-af2ab.appspot.com/o/womens-day%2F21.png?alt=media&token=d683be9b-b447-4791-80cf-0e4a70faedcc"
                    />
                
            </div>

                    <div>
                        <p><b>Featuring Dr Priyanka Mohanty </b></p>
                        <p>To the women who have engraved her name in field of humanity forever , wishing our pad girl of Odisha , young change maker , health icon a very happy international women’s day 2023</p>
                    </div>

                    <p style={{ fontStyle: "italic" }}>
                        <b> Read more on Dr Planete Mobile App</b>
                    </p>
                </center>

            </div>



            <center>
                    <a href="https://play.google.com/store/apps/details?id=lite.bits0895.thedoctorsapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
                        <img
                            class="google_play"
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"

                        />
                    </a>
                </center>
                
            <div>
                <center>
                    <h5 style={{ color: "blue" }}><b>To read the complete Dr Planete Social - Women's Day Special,  download Dr Planete from Google PlayStore now!!</b></h5>
                </center>
            </div>

            <div><FooterNext /></div>
        </div>
    );
}